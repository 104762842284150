const RESOURCE_NAME = 'transactions';

const RESOURCE_FIELDS = [
  'id',
  'amount',
  'creatorRevenue',
  'currencyCode',
  'description',
  'type',
  'createdAt',
  'status',
  'paymentRefNumber',
  'lineItem',
  {
    paymentTo: [
      'id',
      'name',
      {
        user: ['username', 'fullName', 'countryCode'],
      },
    ],
  },
  {
    paymentBy: ['id', 'mobileNumber', 'email'],
  },
] as const;

export { RESOURCE_NAME, RESOURCE_FIELDS };
