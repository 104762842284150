import { Col, Row, Typography } from '@pankod/refine-antd';
import { useOne, useTranslate } from '@pankod/refine-core';
import { RESOURCE_FIELDS, RESOURCE_NAME } from 'pages/videos/constant';

const { Paragraph } = Typography;

export const PlaylistVideoDetail = ({ id }) => {
  const t = useTranslate();
  const queryResult = useOne({
    id,
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
  });

  const video = queryResult?.data?.data;

  return (
    <Row>
      {video && (
        <>
          <Col span={12}>
            <Paragraph>
              {t('videos.fields.title')}: {video.title}
            </Paragraph>
            <Paragraph>
              {t('videos.fields.description')}: {video.description}
            </Paragraph>
            <Paragraph>
              {t('videos.fields.playbackUrl')}: {video.playbackUrl}
            </Paragraph>
            <Paragraph>
              {t('videos.fields.duration')}: {video.duration}
            </Paragraph>
            <Paragraph>
              {t('videos.fields.language')}: {video.language}
            </Paragraph>
            <Paragraph>
              {t('videos.fields.genre')}: {video.genre}
            </Paragraph>
            <Paragraph>
              {t('videos.fields.rating')}: {video.rating}
            </Paragraph>
          </Col>

          <Col span={12}>
            <Paragraph>
              {t('videos.fields.thumbnailUrl')}: {video.thumbnailUrl}
            </Paragraph>
            <Paragraph>
              {t('videos.fields.subtitleUrl')}: {video.subtitleUrl}
            </Paragraph>
            <Paragraph>
              {t('videos.fields.status')}: {video.status}
            </Paragraph>
            <Paragraph>
              {t('videos.fields.scheduleStart')}: {video.scheduleStart}
            </Paragraph>
            <Paragraph>
              {t('videos.fields.scheduleEnd')}: {video.scheduleEnd}
            </Paragraph>
            <Paragraph>
              {t('videos.fields.isPayPerView')}: {video.isPayPerView ? 'yes' : 'no'}
            </Paragraph>
          </Col>
        </>
      )}
    </Row>
  );
};
