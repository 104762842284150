import { useState } from 'react';
import { useDataProvider, useNotification } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { ITransaction } from 'interfaces';
import { formatMoney } from 'libs/number';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';

interface IRevenueReport {
  title: string;
  transactionDates: any[];
}

export const useExportRevenueReport = (params: IRevenueReport) => {
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const { getList } = dataProvider();
  const pageSize = 1000;
  const { open } = useNotification();

  const filename = `revenue-report-${new Date().toLocaleString()}`;

  const requestFanTransactions = async () => {
    let rawData: ITransaction[] = [];
    let current = 1;
    let preparingData = true;

    const title = params?.title || '';
    const dateFrom = params?.transactionDates[0] || null;
    const dateTo = params?.transactionDates[1] || null;

    while (preparingData) {
      try {
        const { data, total } = await getList({
          resource: 'transactions',
          pagination: {
            current,
            pageSize,
          },
          metaData: {
            operation: 'listMyFanTransactions',
            items: [
              'id',
              'amount',
              'creatorRevenue',
              'currencyCode',
              'description',
              'type',
              'createdAt',
              'status',
              'paymentRefNumber',
              'lineItem',
              {
                paymentBy: ['id', 'username', 'mobileNumber', 'email'],
              },
            ],
            metadata: [...PAGINATION_FIELDS],
          },
          filters: [
            {
              field: 'status',
              operator: 'eq',
              value: 'AUTHORISED',
            },
            {
              field: 'createdAt',
              operator: 'between',
              value: [
                dateFrom ? moment(dateFrom).startOf('day').toDate() : '',
                dateTo ? moment(dateTo).endOf('day').toDate() : '',
              ].filter(Boolean),
            },
            title && {
              field: 'lineItem',
              operator: 'eq',
              value: title,
            },
          ],
        });

        current++;
        rawData.push(...(data as ITransaction[]));
        if (total === rawData.length) {
          preparingData = false;
        }
      } catch (error) {
        preparingData = false;
        return;
      }
    }
    return rawData;
  };

  const triggerExport = async () => {
    setIsLoading(true);

    const transactions = (await requestFanTransactions()) || [];

    if (transactions.length === 0) {
      setIsLoading(false);

      open({
        key: `error` + Date.now(),
        type: 'error',
        description: `Error on exporting!`,
        message: 'No record available to export',
      });
      return;
    }

    const workbook = utils.book_new();

    const rowData = transactions.map((transaction) => {
      return {
        'Transaction Date & Time': moment(transaction.createdAt).format('DD-MMM-YYYY'),
        'Transaction ID': transaction.id,
        'Monetization Tools': transaction.type,
        Description: transaction?.lineItem?.name || '',
        'Video Title': transaction?.lineItem?.title || '',
        'Total Revenue Amount': formatMoney((transaction.amount * 0.8 || 0) / 100, {
          currency: transaction.currencyCode,
        }),
        'Fans Username': transaction.paymentBy?.username,
        'Fans Email': transaction.paymentBy?.email,
      };
    });

    // init
    const worksheet = utils.json_to_sheet([]);

    // header
    utils.sheet_add_aoa(worksheet, [['Revenue Report']], { origin: 'A1' });
    utils.sheet_add_aoa(worksheet, [[`Export Date: ${moment().format('DD-MMM-YYYY')}`]], { origin: 'A2' });
    utils.sheet_add_aoa(
      worksheet,
      [
        [
          `Selected Period: ${
            params?.transactionDates?.map((date) => date.format('DD-MMM-YYYY')).join(' - ') || 'ALL'
          }`,
        ],
      ],
      { origin: 'A3' },
    );
    utils.sheet_add_aoa(worksheet, [[`Search Video Title: ${params?.title || 'ALL'}`]], {
      origin: 'A4',
    });
    utils.sheet_add_aoa(worksheet, [[]], { origin: 'A5' });
    utils.sheet_add_json(worksheet, rowData, { skipHeader: false, origin: 'A6' });
    utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

    writeFile(workbook, `${filename}.xlsx`, { compression: true });

    setIsLoading(false);
  };

  return {
    isLoading,
    triggerExport,
  };
};
