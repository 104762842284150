import React from 'react';
import { Button, Col, DateField, Modal, Row, Table, Typography } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { useListMyFanTransactions } from 'hooks/list-my-fan-transactions';
import { IFanSubscriptionSummary, ITransaction } from 'interfaces';
import { formatMoney } from 'libs/number';

const { Paragraph } = Typography;

export const FanTransactionDialog: React.FC<{
  record: IFanSubscriptionSummary;
  close: () => void;
}> = ({ record, close }) => {
  const t = useTranslate();

  const { tableProps, tableQueryResult } = useListMyFanTransactions({
    permanentFilter: [
      {
        field: 'userId',
        operator: 'eq',
        value: record?.userId,
      },
    ],
  });

  return (
    <Modal
      title={t('fansReport.fanReportDetails')}
      width={800}
      visible={tableQueryResult?.isSuccess}
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          OK
        </Button>,
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col>
          <Paragraph>
            {t('fansReport.fields.username')}: {record.username}
          </Paragraph>
          <Paragraph>
            {t('fansReport.fields.email')}: {record.email}
          </Paragraph>
          <Paragraph>
            {t('fansReport.fields.joinedDate')}:{' '}
            {record?.joinedDate ? <DateField value={record.joinedDate} format="ll" /> : 'N/A'}
          </Paragraph>
          <Paragraph>
            {t('fansReport.fields.amount', { currencyCode: 'MYR' })}:{' '}
            {formatMoney(record?.totalAmount / 100 || 0, { currency: 'MYR' })}
          </Paragraph>
        </Col>

        <Col xl={24} xs={24}>
          <Table {...tableProps} title={() => t('fansReport.transactionDetail')} scroll={{ x: 1200 }} rowKey="id">
            <Table.Column<ITransaction>
              dataIndex="createdAt"
              title={t('fansReport.tables.createdAt')}
              render={(_, record) => <DateField value={record.createdAt} format="lll" />}
              fixed="left"
              width="10%"
            />
            <Table.Column dataIndex="id" title={t('fansReport.tables.id')} />
            <Table.Column<ITransaction>
              dataIndex="amount"
              title={t('fansReport.tables.amount', { currencyCode: 'MYR' })}
              render={(_, record) => formatMoney(record?.amount / 100 || 0, { currency: record?.currencyCode })}
            />
            <Table.Column<ITransaction>
              title={t('fansReport.tables.monetizationTools')}
              render={(_, record) => record?.type || '-'}
            />
            <Table.Column<ITransaction>
              title={t('fansReport.tables.description')}
              render={(_, record) => {
                return <>{record?.description}</>;
              }}
            />
            {/* Request changed by Frances */}
            {/* <Table.Column<ITransaction>
              title={'Video Title'}
              render={(_, record) => {
                if (record.type === ProductType.PayPerView) {
                  return <>{record?.lineItem?.title}</>;
                }
                return 'N/A';
              }}
            />
            <Table.Column<ITransaction>
              title={'Virtual Gift Title'}
              render={(_, record) => {
                if (record.type === ProductType.Gift) {
                  return <>{record?.lineItem?.name}</>;
                }
                return 'N/A';
              }}
            />
            <Table.Column<ITransaction>
              title={'Subscription Title'}
              render={(_, record) => {
                if (record.type === ProductType.Subscription) {
                  return <>{record?.lineItem?.name}</>;
                }
                return 'N/A';
              }}
            />
            <Table.Column<ITransaction> title={'Fans Email'} render={(_, record) => record?.paymentBy?.email || '-'} /> */}
          </Table>
        </Col>
      </Row>
    </Modal>
  );
};
