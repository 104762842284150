import React from 'react';
import { useTranslate } from '@pankod/refine-core';
import { Button, Form, Input, Modal } from 'antd';

interface MediaLink {
  key: string;
  title: string;
  url: string;
}

export const MediaForm: React.FC<{
  modalVisible: boolean;
  values?: MediaLink;
  onClose: () => void;
  onSubmit: (data: Omit<MediaLink, 'key'>) => void;
}> = ({ values, modalVisible, onClose, onSubmit }) => {
  const t = useTranslate();
  const [key, setKey] = React.useState('');
  const [form] = Form.useForm<Omit<MediaLink, 'key'>>();
  const isEdit = !!values;

  // reset form on close
  React.useEffect(() => {
    if (modalVisible && isEdit) {
      form.setFieldsValue({ ...values });
      setKey(Date.now().toString());
    } else {
      form.resetFields();
    }
  }, [form, isEdit, modalVisible, values]);

  return (
    <Modal
      title={isEdit ? t('myChannel.mediaTable.editMediaLink') : t('myChannel.mediaTable.addMediaLink')}
      visible={modalVisible}
      onCancel={onClose}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          {t('buttons.submit')}
        </Button>,
        <Button key="back" onClick={onClose}>
          {t('buttons.close')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          onSubmit({
            title: values.title,
            url: values.url,
          });
          onClose();
        }}
      >
        <Form.Item
          label={t('myChannel.mediaTable.title')}
          name="title"
          rules={[{ required: true, message: t('errors.required', { field: t('myChannel.mediaTable.title') }) }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('myChannel.mediaTable.link')}
          name="url"
          rules={[
            {
              required: true,
              message: t('errors.required', { field: t('myChannel.mediaTable.link') }),
            },
            {
              type: 'url',
              message: t('errors.url', { field: t('myChannel.mediaTable.link') }),
            },
          ]}
        >
          <Input placeholder="https://example.com" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
