import React from 'react';
import { useTable, useTableReturnType } from '@pankod/refine-antd';
import { CrudFilters, CrudSort, HttpError, MetaDataQuery } from '@pankod/refine-core';
import { RESOURCE_FIELDS, RESOURCE_NAME } from 'pages/nft-collection/constant';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { INftCollection, INftCollectionFilterVariables } from 'interfaces';

export const useListNFTCollections = ({
  metadata,
}: {
  metadata?: MetaDataQuery;
}): useTableReturnType<INftCollection, INftCollectionFilterVariables> => {
  const initialSorter: CrudSort[] = [{ field: 'createdAt', order: 'desc' }];
  const initialPageSize = 10;
  const initialFilter = [];

  return useTable<INftCollection, HttpError, INftCollectionFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      ...metadata,
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter,
    initialPageSize,
    initialFilter: initialFilter as CrudFilters,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { title, createdDates } = params;

      filters.push({
        field: 'title',
        operator: 'contains',
        value: title,
      });

      filters.push({
        field: 'createdAt',
        operator: 'between',
        value:
          createdDates?.length > 0
            ? [createdDates[0].startOf('day').toDate(), createdDates[1].endOf('day').toDate()]
            : [],
      });

      return filters;
    },
  });
};
