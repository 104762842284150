import { useState } from 'react';
import { CrudFilters, useDataProvider, useNotification } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { RESOURCE_FIELDS, RESOURCE_NAME } from '../constant';
import { ITransaction } from 'interfaces';
import { formatMoney } from 'libs/number';
import { utils, writeFile } from 'xlsx';

export const useExportTransactions = ({ filters, headers }: { filters: CrudFilters; headers: string[] }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const { getList } = dataProvider();
  const { open } = useNotification();
  const pageSize = 1000;
  const filename = `transaction-report-${new Date().toLocaleString()}`;

  const requestTransactions = async () => {
    let rawData: ITransaction[] = [];
    let current = 1;
    let preparingData = true;

    while (preparingData) {
      try {
        const { data, total } = await getList({
          resource: RESOURCE_NAME,
          pagination: {
            current,
            pageSize,
          },
          metaData: {
            items: [...RESOURCE_FIELDS],
            metadata: [...PAGINATION_FIELDS],
          },
          filters: [...filters],
        });

        current++;
        rawData.push(...(data as ITransaction[]));
        if (total === rawData.length) {
          preparingData = false;
        }
      } catch (error) {
        preparingData = false;
        return;
      }
    }
    return rawData;
  };

  const triggerExport = async () => {
    setIsLoading(true);

    const transactions = (await requestTransactions()) || [];

    if (transactions.length === 0) {
      setIsLoading(false);

      open({
        key: `error` + Date.now(),
        type: 'error',
        description: `Error on exporting!`,
        message: 'No record available to export',
      });
      return;
    }

    const workbook = utils.book_new();

    const rowData = transactions.map((item) => {
      const values = {
        'Transaction Date & Time': item.createdAt,
        'Transaction ID': item.id,
        'PRESTO TRX REF': item.paymentRefNumber,
        'Creator Channel ID': item?.paymentTo?.id,
        'Creator Channel Name': item?.paymentTo?.name,
        'Creator Username': item?.paymentTo?.user?.username,
        'Creator Country': item?.paymentTo?.user?.countryCode,
        Type: item.type,
        'Video ID (If Pay-Per-View)': item.type === 'PAY_PER_VIEW' ? item.lineItem?.id : 'N/A',
        'Video Title (If Pay-Per-View)': item.type === 'PAY_PER_VIEW' ? item.lineItem?.title : 'N/A',
        'Package (If Subscription)': item.type === 'SUBSCRIPTION' ? item.lineItem?.name : 'N/A',
        'Gift Name': item.type === 'GIFT' ? item.lineItem?.name : 'N/A',
        'Currency Code': item.currencyCode,
        'Total Transaction': formatMoney((item.amount || 0) / 100, { currency: item.currencyCode }),
        'Total Creator Revenue Amount': formatMoney((item.amount * 0.8 || 0) / 100, { currency: item.currencyCode }),
        'Fans ID': item.paymentBy?.id,
        'Fans Phone Number': item.paymentBy?.mobileNumber,
        'Fans Email': item.paymentBy?.email,
      };

      Object.keys(values).forEach((key) => {
        values[key] = values[key] || 'N/A';
      });

      return values;
    });

    const worksheet = utils.json_to_sheet([]);

    let rowNumber = 1;
    headers.map((header) => {
      utils.sheet_add_aoa(worksheet, [[header]], { origin: `A${rowNumber++}` });
    });
    utils.sheet_add_aoa(worksheet, [[]], { origin: `A${rowNumber++}` });
    utils.sheet_add_json(worksheet, rowData, { skipHeader: false, origin: `A${rowNumber++}` });
    utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

    writeFile(workbook, `${filename}.xlsx`, { compression: true });

    setIsLoading(false);
  };

  return {
    isLoading,
    triggerExport,
  };
};
