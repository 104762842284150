import { BiMoviePlay } from 'react-icons/bi';
import { FaCoins } from 'react-icons/fa';
import { IoSettingsSharp } from 'react-icons/io5';
import { TbFileInvoice } from 'react-icons/tb';
import { TbListCheck } from 'react-icons/tb';
import { Icons } from '@pankod/refine-antd';
import { ResourceProps } from '@pankod/refine-core';
import { AccountSettingEditPayout } from 'pages/account-setting/edit-payout';
import { AccountSettingEditProfile } from 'pages/account-setting/edit-profile';
import { HelpCenter } from 'pages/help-center/help-center';
import { MyChannelEdit } from 'pages/my-channel';
import { MyFansReport } from 'pages/my-fans-reports';
import { MyRevenueReport } from 'pages/my-revenue-reports';
import { Playlist } from 'pages/playlist';
import { PlaylistVideoList } from 'pages/playlist/playlist-videos/list';
import { SubscriptionEdit, SubscriptionList } from 'pages/subscription';
import { VideoCreate, VideoEdit, VideoList } from 'pages/videos';
import { VirtualGiftingList } from 'pages/virtual-gifting';
import { withRouteGuard } from 'components/route-guard';

const role = 'user';

export const UserRoutes: ResourceProps[] = [
  {
    key: 'my-channel',
    icon: <TbListCheck />,
    name: 'my-channel',
    options: {
      label: 'myChannel',
      role,
    },
    list: withRouteGuard(MyChannelEdit, 'user', true),
  },

  //
  // Content
  //
  {
    key: 'contents',
    icon: <BiMoviePlay />,
    name: 'contents',
    options: {
      label: 'contentManager',
      role,
    },
  },
  {
    name: 'videos',
    parentName: 'contents',
    options: {
      label: 'contentLibrary',
    },
    list: withRouteGuard(VideoList, 'user', true),
    create: withRouteGuard(VideoCreate, 'user', true),
    edit: withRouteGuard(VideoEdit, 'user', true),
  },
  {
    name: 'playlists',
    parentName: 'contents',
    options: {
      label: 'playlists',
    },
    list: withRouteGuard(Playlist, 'user', true),
    edit: withRouteGuard(PlaylistVideoList, 'user', true),
  },

  //
  // Monetization
  //
  {
    key: 'monetization',
    icon: <FaCoins />,
    name: 'monetization',
    options: {
      label: 'monetization',
      role,
    },
  },
  {
    name: 'subscription',
    parentName: 'monetization',
    options: {
      label: 'subscriptionPlans',
    },
    list: withRouteGuard(SubscriptionList, 'user', true),
    edit: withRouteGuard(SubscriptionEdit, 'user', true),
  },
  {
    name: 'virtual-gifting',
    parentName: 'monetization',
    options: {
      label: 'virtualGifting',
    },
    list: withRouteGuard(VirtualGiftingList, 'user', true),
  },

  //
  // Reports
  //
  {
    key: 'my-channel-report',
    icon: <TbFileInvoice />,
    name: 'my-channel-report',
    options: {
      label: 'myChannelReport',
      role,
    },
  },
  {
    name: 'fans-report',
    parentName: 'my-channel-report',
    options: { label: 'fansReport' },
    list: withRouteGuard(MyFansReport, 'user', true),
  },
  {
    name: 'revenue-report',
    parentName: 'my-channel-report',
    options: { label: 'revenueReport' },
    list: withRouteGuard(MyRevenueReport, 'user', true),
  },

  //
  // Help Center
  //
  {
    key: 'help-center',
    name: 'help-center',
    options: {
      label: 'helpCenter',
      role,
    },
    icon: <Icons.ToolOutlined />,
    list: withRouteGuard(HelpCenter, 'user'),
  },
  //
  // Account Settings
  //
  {
    key: 'account-settings',
    icon: <IoSettingsSharp />,
    name: 'account-settings',
    options: {
      label: 'accountSettings',
      role,
    },
  },
  {
    name: 'profile',
    parentName: 'account-settings',
    options: { label: 'profile' },
    list: withRouteGuard(AccountSettingEditProfile, 'user'),
  },
  {
    name: 'payout',
    parentName: 'account-settings',
    options: { label: 'payout' },
    list: withRouteGuard(AccountSettingEditPayout, 'user', true),
  },
];
