import { useCustom } from '@pankod/refine-core';
import { Channel } from 'api';

type MyChannel = Pick<
  Channel,
  'id' | 'name' | 'aboutMe' | 'appLogo' | 'promotionalBanners' | 'aboutLinks' | 'currencyCode'
>;

export const useFindMyChannel = () => {
  const RESOURCE_FIELDS = ['id', 'name', 'aboutMe', 'appLogo', 'promotionalBanners', 'aboutLinks', 'currencyCode'];

  return useCustom<MyChannel>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'getMyChannel',
      fields: [...RESOURCE_FIELDS],
    },
  });
};
