import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Card,
  Col,
  Edit,
  Form,
  Input,
  ListButton,
  RefreshButton,
  Row,
  Select,
  Switch,
  Tag,
  Typography,
  useForm,
} from '@pankod/refine-antd';
import { HttpError, IResourceComponentsProps, useTranslate } from '@pankod/refine-core';
import { AliyunOSSUpload } from 'components/upload';
import { useLeaveFormConfirmation } from 'hooks/use-leave-form-confirmation';
import { mapValuesToSetter } from '../../libs';
import { RESOURCE_FIELDS, RESOURCE_NAME, genreOptions, ratingOptions, voLanguageOptions } from './constant';
import { VideoPlayer } from './video-player';
import { DatePicker, InputNumber } from 'antd';
import { VideoStatus } from 'api';
import { IChannel, IVideoForm } from 'interfaces';
import moment from 'moment';

const { Link } = Typography;

const responsive = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
};

const uploadItemResponsive = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xl: 12,
};

export const VideoEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { id } = useParams();
  const { onFieldsChange, onMutateSuccess } = useLeaveFormConfirmation();

  const { formProps, queryResult } = useForm<IVideoForm & { channel: IChannel }, HttpError, IVideoForm>({
    id,
    action: 'edit',
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS, { channel: ['id', 'currencyCode'] }],
    },
    onMutationSuccess: () => {
      onMutateSuccess();
    },
  });
  const result = queryResult?.data?.data;
  const isRelease = (result?.status as unknown as VideoStatus) === VideoStatus.Released;

  const isPayPerView = Form.useWatch('isPayPerView', formProps.form);

  return (
    <Edit
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        disabled: queryResult?.isFetching,
        onClick: () => {
          formProps.form.submit();
        },
      }}
      pageHeaderProps={{
        title: t('videos.editContent'),
        breadcrumb: <Breadcrumb hideIcons />,
        extra: (
          <>
            <ListButton>{t('videos.contentLibrary')}</ListButton>
            <RefreshButton onClick={() => queryResult?.refetch({})} />
          </>
        ),
      }}
    >
      <Form
        {...formProps}
        initialValues={{
          ...result,
          scheduleStart: result?.scheduleStart ? moment(result.scheduleStart) : undefined,
          payPerViewPrice: result?.payPerViewPrice ? result.payPerViewPrice / 100 : 1, // divide by 100 to convert to cents
        }}
        layout="vertical"
        onFieldsChange={onFieldsChange}
        onFinish={(values) => {
          const payload = {
            ...mapValuesToSetter({
              ...values,
              // multiply by 100 to convert to cents
              payPerViewPrice: Math.round(Math.max(values.payPerViewPrice * 100, 0)),
            }),
          };
          // @ts-ignore
          formProps?.onFinish?.(payload);
        }}
      >
        <Row gutter={[24, 24]} justify="start" align="top">
          <Col {...responsive}>
            <Card title={t('videos.generalSettings')}>
              <Form.Item
                label={t('videos.fields.title')}
                name="title"
                rules={[
                  {
                    required: true,
                    message: t('errors.required', { field: t('videos.fields.title') }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label={t('videos.fields.description')} name="description" rules={[{ required: false }]}>
                <Input.TextArea />
              </Form.Item>

              <Form.Item
                label={t('videos.fields.language')}
                name="language"
                rules={[
                  {
                    required: true,
                    message: t('errors.required', { field: t('videos.fields.language') }),
                  },
                ]}
              >
                <Select options={voLanguageOptions} />
              </Form.Item>

              <Form.Item
                label={t('videos.fields.genre')}
                name="genre"
                rules={[
                  {
                    required: true,
                    message: t('errors.required', { field: t('videos.fields.genre') }),
                  },
                ]}
              >
                <Select options={genreOptions} />
              </Form.Item>

              <Form.Item
                label={t('videos.fields.rating')}
                name="rating"
                rules={[
                  {
                    required: true,
                    message: t('errors.required', { field: t('videos.fields.rating') }),
                  },
                ]}
              >
                <Select options={ratingOptions} />
              </Form.Item>

              <Form.Item
                label={t('videos.fields.publishDate')}
                name="scheduleStart"
                rules={[
                  {
                    required: true,
                    message: t('errors.required', { field: t('videos.fields.publishDate') }),
                  },
                ]}
                getValueProps={(value) => ({ value: moment(value) })}
              >
                <DatePicker
                  placeholder={t('videos.fields.publishDate')}
                  style={{ width: '100%' }}
                  allowClear={false}
                  disabledDate={(current) => {
                    return current && current < moment().subtract(1, 'day').endOf('day');
                  }}
                />
              </Form.Item>
            </Card>
          </Col>

          <Col {...responsive}>
            <Card title={t('videos.monetizeVideo')}>
              <Form.Item label={t('videos.fields.isPayPerView')} name="isPayPerView" valuePropName="checked">
                <Switch />
              </Form.Item>

              {isPayPerView && (
                <Form.Item
                  label={t('videos.fields.pricePayPerView') + ` (${queryResult?.data?.data.channel?.currencyCode})`}
                  name="payPerViewPrice"
                  rules={[
                    {
                      required: true,
                      message: t('errors.required', { field: t('videos.fields.pricePayPerView') }),
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} min={1} />
                </Form.Item>
              )}
            </Card>
          </Col>

          <Col {...uploadItemResponsive}>
            <Card>
              <Form.Item
                label={
                  <>
                    {t('videos.fields.videoUpload')} &nbsp; <Tag>{result?.status}</Tag>
                  </>
                }
              >
                {isRelease && <VideoPlayer playbackUrl={result?.playbackUrl} />}
              </Form.Item>
              <br />
              <Form.Item label={t('videos.fields.subtitlesUpload.label')} name="subtitleUrl">
                <AliyunOSSUpload
                  purpose="VideoSubtitle"
                  accept=".vtt,.srt"
                  description={t('upload.extension', { extension: '.srt/.vtt' })}
                  onChange={(response) => {
                    formProps.form?.setFieldsValue({
                      subtitleUrl: response.url,
                    });
                  }}
                />
              </Form.Item>
              URL:
              <Link
                onClick={() => {
                  window.open(result?.subtitleUrl);
                }}
              >
                {result?.subtitleUrl}
              </Link>
            </Card>
          </Col>

          <Col {...uploadItemResponsive}>
            <Card>
              <Form.Item
                label={t('videos.fields.thumbnailUpload.label')}
                name="thumbnailUrl"
                rules={[{ required: false }]}
              >
                <AliyunOSSUpload
                  purpose="VideoThumbnail"
                  accept="image/*"
                  description={
                    t('upload.extension', { extension: '.jpg/.jpeg/.png' }) +
                    '<br/>' +
                    t('videos.fields.thumbnailUpload.description')
                  }
                  onChange={(response) => {
                    formProps.form?.setFieldsValue({
                      thumbnailUrl: response.url,
                    });
                  }}
                />
              </Form.Item>
              URL:
              <Link
                onClick={() => {
                  window.open(result?.thumbnailUrl);
                }}
              >
                {result?.thumbnailUrl}
              </Link>
            </Card>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
