import { useTable, useTableReturnType } from '@pankod/refine-antd';
import { CrudFilters, CrudSort, HttpError } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { RESOURCE_FIELDS, RESOURCE_NAME } from '../constant';
import { IWatchHistoryFilterVariables, IWatchHistorySummary } from 'interfaces';

export const useListWatchHistories = ({
  frequency,
}): useTableReturnType<IWatchHistorySummary, IWatchHistoryFilterVariables> => {
  const initialFilter: CrudFilters = [
    {
      field: 'frequency',
      operator: 'null',
      value: frequency,
    },
  ];
  const initialSorter: CrudSort[] = [];
  const initialPageSize = 10;

  const result = useTable<IWatchHistorySummary, HttpError, IWatchHistoryFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'getWatchHistorySummary',
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter,
    initialPageSize,
    initialFilter,
    onSearch: (params) => {
      const filters: CrudFilters = [];

      const { channel, searchDates } = params;

      filters.push({
        field: 'channelId',
        operator: 'null',
        value: channel?.id || null,
      });

      filters.push({
        field: 'dateFrom',
        operator: 'null',
        value: searchDates?.length > 0 ? searchDates[0].startOf('day').toDate() : null,
      });

      filters.push({
        field: 'dateTo',
        operator: 'null',
        value: searchDates?.length > 0 ? searchDates[1].endOf('day').toDate() : null,
      });

      return filters;
    },
  });

  return {
    ...result,
  };
};
