import { useTable, useTableReturnType } from '@pankod/refine-antd';
import { CrudFilters, CrudSort, HttpError } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { RESOURCE_FIELDS } from '../constant';
import { IFanSubscriptionSummary, IFanTransactionSummaryFilterVariables } from 'interfaces';

export const useListMyFanSubscriptionSummary = (): useTableReturnType<
  IFanSubscriptionSummary,
  IFanTransactionSummaryFilterVariables
> => {
  const initialSorter: CrudSort[] = [];
  const initialPageSize = 10;

  const result = useTable<IFanSubscriptionSummary, HttpError, IFanTransactionSummaryFilterVariables>({
    resource: '',
    metaData: {
      variablesOverrides: {
        where: {
          type: 'FanSubscriptionSummaryWhereInput',
        },
      },
      operation: 'listMyFanSubscriptionSummary',
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter: [],
    initialSorter,
    initialPageSize,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { transactionDates = [] } = params;

      filters.push({
        field: 'dateFrom',
        operator: 'null',
        value: transactionDates.length > 0 ? transactionDates[0].startOf('day').toDate() : null,
      });

      filters.push({
        field: 'dateTo',
        operator: 'null',
        value: transactionDates.length > 0 ? transactionDates[1].endOf('day').toDate() : null,
      });

      return filters;
    },
  });

  return {
    ...result,
  };
};
