import { Button, Col, Divider, Form, Icons, Input, Row, Space } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { QuillEditor } from 'components/quill/quill-editor';
import { removeHtmlTags } from 'libs/remove-html-tag';

const languageMap = {
  en: '',
  cn: 'Cn',
  zh: 'Zh',
};

export const MultiLanguageForm = ({ postfix = '' }) => {
  const t = useTranslate();

  return (
    <>
      <Form.Item
        label={t('nftCollections.fields.title')}
        name={`title${languageMap[postfix]}`}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t('nftCollections.fields.description')}
        name={`description${languageMap[postfix]}`}
        rules={[{ required: true }]}
      >
        <QuillEditor style={{ color: '' }} />
      </Form.Item>
      <Form.Item
        label={t('nftCollections.fields.shortDescription')}
        name={`shortDescription${languageMap[postfix]}`}
        rules={[
          { required: true },
          () => ({
            validator(_, value) {
              if (!value || removeHtmlTags(value).length <= 85) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Short description must not exceed 85 characters'));
            },
          }),
        ]}
      >
        <QuillEditor style={{ color: '' }} />
      </Form.Item>

      <Divider />

      <div>
        <Space direction="vertical" style={{ width: '100%' }}>
          <b>{t('nftCollections.fields.roadmap')}</b>
          <Form.List
            name={`roadmap${languageMap[postfix]}`}
            rules={[
              {
                validator: async (_, roadmap) => {
                  if (!roadmap || roadmap.length < 1) {
                    return Promise.reject(new Error(t('nftCollections.errors.roadmap.atLeastOne')));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...rest }) => (
                  <Row align="middle" justify="start" key={key} style={{ alignItems: 'baseline' }} gutter={16}>
                    <Col>{key + 1}</Col>
                    <Col span={22}>
                      <Form.Item
                        {...rest}
                        name={[name, 'header']}
                        rules={[{ required: true, message: t('nftCollections.errors.roadmap.missingHeader') }]}
                      >
                        <Input placeholder="Header" />
                      </Form.Item>
                      <Form.Item
                        {...rest}
                        name={[name, 'description']}
                        rules={[
                          { required: true },
                          () => ({
                            validator(_, value) {
                              if (!value || removeHtmlTags(value).length <= 0) {
                                return Promise.reject(new Error(t('nftCollections.errors.roadmap.missingDescription')));
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <QuillEditor style={{ color: '' }} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Icons.MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<Icons.PlusOutlined />}>
                    {t('nftCollections.addRoadmap')}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Space>
      </div>

      <Divider />

      <div>
        <Space direction="vertical" style={{ width: '100%' }}>
          <b>{t('nftCollections.fields.faqs')}</b>
          <Form.List
            name={`faqs${languageMap[postfix]}`}
            rules={[
              {
                validator: async (_, faq) => {
                  if (!faq || faq.length < 1) {
                    return Promise.reject(new Error(t('nftCollections.errors.faqs.atLeastOne')));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...rest }) => (
                  <Row align="middle" justify="start" key={key} style={{ alignItems: 'baseline' }} gutter={16}>
                    <Col>{key + 1}</Col>
                    <Col span={22}>
                      <Form.Item
                        {...rest}
                        name={[name, 'question']}
                        rules={[{ required: true, message: t('nftCollections.errors.faqs.missingQuestion') }]}
                      >
                        <Input placeholder="Question" />
                      </Form.Item>
                      <Form.Item
                        {...rest}
                        name={[name, 'answer']}
                        rules={[
                          { required: true },
                          () => ({
                            validator(_, value) {
                              if (!value || removeHtmlTags(value).length <= 0) {
                                return Promise.reject(new Error(t('nftCollections.errors.faqs.missingAnswer')));
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <QuillEditor style={{ color: '' }} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Icons.MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<Icons.PlusOutlined />}>
                    {t('nftCollections.addFaq')}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Space>
      </div>

      <Divider />

      <div>
        <Space direction="vertical" style={{ width: '100%' }}>
          <b>{t('nftCollections.fields.holderPerks')}</b>
          <Form.List
            name={`holderPerks${languageMap[postfix]}`}
            rules={[
              {
                validator: async (_, holderPerks) => {
                  if (!holderPerks || holderPerks.length < 1) {
                    return Promise.reject(new Error(t('nftCollections.errors.holderPerks.atLeastOne')));
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...rest }) => (
                  <Row align="middle" justify="start" key={key} style={{ alignItems: 'baseline' }} gutter={16}>
                    <Col>{key + 1}</Col>
                    <Col span={22}>
                      <Form.Item
                        {...rest}
                        name={[name, 'value']}
                        rules={[
                          { required: true },
                          () => ({
                            validator(_, value) {
                              if (!value || removeHtmlTags(value).length <= 0) {
                                return Promise.reject(new Error(t('nftCollections.errors.holderPerks.missingValue')));
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <QuillEditor style={{ color: '' }} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Icons.MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<Icons.PlusOutlined />}>
                    {t('nftCollections.addHolderPerks')}
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Space>
      </div>

      <Divider />
    </>
  );
};
