import React from 'react';
import {
  Breadcrumb,
  Card,
  Col,
  DateField,
  ExportButton,
  List,
  Row,
  Space,
  Table,
  Typography,
} from '@pankod/refine-antd';
import { IResourceComponentsProps, useTranslate } from '@pankod/refine-core';
import { WatchHistoryFilter } from './components/watch-history-filter';
import { useExportViewReport, useGetWatchHistorySummary, useListWatchHistories } from './hooks';
import { Frequency } from 'api';
import moment from 'moment';

const { Text, Title } = Typography;

const responsive = {
  xs: 24,
  sm: 24,
  md: 8,
  lg: 8,
  xl: 8,
};

export const WatchHistoryList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, searchFormProps, tableQueryResult, filters } = useListWatchHistories({
    frequency: Frequency.Daily,
  });

  const channel = searchFormProps.form.getFieldValue('channel');
  const searchDates = searchFormProps.form.getFieldValue('searchDates') || [];

  const summaryQueryResult = useGetWatchHistorySummary({
    frequency: Frequency.AllTime,
    channelId: channel?.id || null,
    searchDates: [searchDates[0], searchDates[1]],
  });

  const { triggerExport, isLoading: exportLoading } = useExportViewReport({
    filters,
    headers: [
      'View Report',
      `Export Date: ${moment().format('DD-MMM-YYYY')}`,
      `Channel Name: ${channel?.name || 'ALL'}`,
      `Selected Period: ${searchDates?.map((date) => date.format('DD-MMM-YYYY')).join(' - ') || 'ALL'}`,
    ],
  });

  const Actions: React.FC = () => (
    <Space>
      <ExportButton
        loading={exportLoading}
        onClick={() => {
          triggerExport();
        }}
      />
    </Space>
  );

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col {...responsive}>
          <Card>
            <Title>{t('views.totalViews')}</Title>
            <Text>{summaryQueryResult?.totalViewCount}</Text>
          </Card>
        </Col>
        <Col {...responsive}>
          <Card>
            <Title>{t('views.totalUniqueView')}</Title>
            <Text>{summaryQueryResult?.totalUniqueViewCount}</Text>
          </Card>
        </Col>
        <Col {...responsive}>
          <Card>
            <Title>{t('views.totalUniqueVideo')}</Title>
            <Text>{summaryQueryResult?.totalUniqueVideoCount}</Text>
          </Card>
        </Col>
      </Row>

      <br />

      <Row gutter={[16, 16]}>
        <Col xl={24} lg={24} xs={24}>
          <WatchHistoryFilter formProps={searchFormProps} tableQueryResult={tableQueryResult} filters={filters || []} />
        </Col>

        <Col xl={24} xs={24}>
          <List
            pageHeaderProps={{
              title: t('views.viewReport'),
              breadcrumb: <Breadcrumb hideIcons />,
              extra: <Actions />,
            }}
          >
            <Table {...tableProps} rowKey="date">
              <Table.Column
                dataIndex="date"
                title={t('views.fields.date')}
                render={(value) => <DateField value={value} format="LL" />}
              />
              <Table.Column dataIndex="totalViewCount" title={t('views.fields.totalViewCount')} />
              <Table.Column dataIndex="totalUniqueViewCount" title={t('views.fields.totalUniqueView')} />
              <Table.Column dataIndex="totalUniqueVideoCount" title={t('views.fields.totalUniqueVideo')} />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};
