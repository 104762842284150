import React from 'react';
import { TitleProps } from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  return (
    <Link to="/">
      {collapsed ? (
        <img
          src={'/prestocolor_logo_head.png'}
          alt="Presto Color"
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px 24px',
          }}
        />
      ) : (
        <img
          src={'/prestocolor_logo.png'}
          alt="Presto Color"
          style={{
            width: '200px',
            margin: '1.0rem 1.5rem',
            // margin: '0px 0px 0px 18px',
            // padding: '10px 4px',
          }}
        />
      )}
    </Link>
  );
};
