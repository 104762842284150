import React, { useCallback, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DateField, ImageField } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { BannerForm } from './BannerForm';
import { CloseOutlined } from '@ant-design/icons';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Table, Typography } from 'antd';
import update from 'immutability-helper';
import { cloneDeep } from 'lodash';

export interface Banner {
  key: string;
  bannersUrl: string;
  url: string;
  scheduleStart: string | Date;
  scheduleEnd: string | Date;
}

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

export const BannerTable: React.FC<{
  banners: any[];
  onChange: (data: any[]) => void;
}> = ({ banners, onChange }) => {
  const t = useTranslate();

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = banners[dragIndex];
      onChange(
        update(banners, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [banners, onChange],
  );

  const [createModalVisible, setCreateModalVisible] = React.useState(false);
  const showCreateModal = () => setCreateModalVisible(true);
  const closeCreateModal = () => setCreateModalVisible(false);

  const [editModalKey, setEditModalKey] = React.useState('');
  const showEditModal = (key: string) => setEditModalKey(key);
  const closeEditModal = () => setEditModalKey('');

  return (
    <>
      <BannerForm
        modalVisible={createModalVisible}
        onClose={closeCreateModal}
        onSubmit={(values) => {
          onChange([
            ...banners,
            {
              key: `banners ` + Date.now().toString(), // dirty way to set the key
              url: values?.url,
              bannersUrl: values?.bannersUrl || '',
              scheduleStart: values?.scheduleStart || '',
              scheduleEnd: values?.scheduleEnd || '',
            },
          ]);
        }}
      />
      <BannerForm
        modalVisible={!!editModalKey}
        values={banners.find((item) => item.key === editModalKey)}
        onClose={closeEditModal}
        onSubmit={(values) => {
          const newBanners = cloneDeep(banners);
          const index = banners.findIndex((banner) => banner.key === editModalKey);
          newBanners[index] = {
            key: editModalKey,
            ...values,
          };
          onChange(newBanners);
        }}
      />

      <Row gutter={[10, 0]} justify="start" align="middle">
        <Col span={22}>
          <Button type="link" icon={<PlusCircleOutlined />} onClick={showCreateModal} disabled={banners.length >= 4}>
            {t('myChannel.bannerTable.addPromotionBanner')}
          </Button>
        </Col>

        <Col span={2}>
          <Typography.Text>{banners.length}/4</Typography.Text>
        </Col>
      </Row>

      <Row gutter={[10, 0]} justify="start" align="middle">
        <Col span={24}>
          <DndProvider backend={HTML5Backend}>
            <Table
              rowKey="key"
              dataSource={banners}
              components={{
                body: {
                  row: DraggableBodyRow,
                },
              }}
              onRow={(_, index) => {
                const attr = {
                  index,
                  moveRow,
                };
                return attr as React.HTMLAttributes<any>;
              }}
              pagination={false}
            >
              <Table.Column<Banner>
                title={t('myChannel.bannerTable.banners')}
                dataIndex="bannersUrl"
                key="bannersUrl"
                render={(_: any, record: Banner) => {
                  return <ImageField value={record.bannersUrl} title={record.bannersUrl} height={100} />;
                }}
              />
              <Table.Column<Banner>
                title={t('myChannel.bannerTable.link')}
                dataIndex="url"
                key="url"
                render={(value) => <Typography.Text>{value || 'N/A'}</Typography.Text>}
              />
              <Table.Column<Banner>
                title={t('myChannel.bannerTable.scheduleStart')}
                dataIndex="scheduleStart"
                key="scheduleStart"
                render={(value) => <DateField value={value} format="LL" />}
              />
              <Table.Column<Banner>
                width="20%"
                title={t('myChannel.bannerTable.action')}
                key="action"
                render={(_: any, record: Banner) => (
                  <Space size="middle">
                    <Button type="link" icon={<EditOutlined />} onClick={() => showEditModal(record.key)} />

                    <Button
                      type="link"
                      icon={<CloseOutlined />}
                      onClick={() => {
                        const newBanners = banners.filter((banner) => banner.key !== record.key);
                        onChange(newBanners);
                      }}
                    />
                  </Space>
                )}
              />
            </Table>
          </DndProvider>
        </Col>
      </Row>

      <br />
    </>
  );
};
