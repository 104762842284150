import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Breadcrumb,
  Col,
  DatePicker,
  Edit,
  Form,
  Input,
  InputNumber,
  ListButton,
  RefreshButton,
  Row,
  Select,
  Switch,
  Tabs,
  Typography,
  useForm,
} from '@pankod/refine-antd';
import { HttpError, IResourceComponentsProps, useTranslate } from '@pankod/refine-core';
import { MultiLanguageForm } from './components/multi-language-form';
import { AliyunOSSUpload } from 'components/upload';
import { mapValuesToSetter } from '../../libs';
import { RESOURCE_FIELDS, RESOURCE_NAME } from './constant';
import { CurrencyCode, ThirdWebPrebuiltContractVariant, Web3Network } from 'api';
import { INftCollectionForm } from 'interfaces';
import { startCase } from 'lodash';
import moment from 'moment';

const { Link } = Typography;

export const NftCollectionEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { id } = useParams();

  const { formProps, queryResult, saveButtonProps } = useForm<INftCollectionForm, HttpError, INftCollectionForm>({
    id,
    action: 'edit',
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
  });
  const result = queryResult?.data?.data;

  const freeMintStartDate = Form.useWatch('freeMintStartDate', formProps.form);
  const preSalesStartDate = Form.useWatch('preSalesStartDate', formProps.form);
  const publicSalesStartDate = Form.useWatch('publicSalesStartDate', formProps.form);
  const fiatCheckoutEnabled = Form.useWatch('fiatCheckoutEnabled', formProps.form);

  React.useEffect(() => {
    return () => {
      formProps.form.resetFields();
    };
  }, [formProps.form]);

  return (
    <Edit
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        ...saveButtonProps,
      }}
      pageHeaderProps={{
        title: t('nftCollections.editNFTCollection'),
        breadcrumb: <Breadcrumb hideIcons />,
        extra: (
          <>
            <ListButton />
            <RefreshButton onClick={() => queryResult?.refetch({})} />
          </>
        ),
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
          freeMintStartDate: result?.freeMintStartDate ? moment(result.freeMintStartDate) : null,
          freeMintEndDate: result?.freeMintEndDate ? moment(result.freeMintEndDate) : null,
          preSalesStartDate: result?.preSalesStartDate ? moment(result.preSalesStartDate) : null,
          preSalesEndDate: result?.preSalesEndDate ? moment(result.preSalesEndDate) : null,
          publicSalesStartDate: result?.publicSalesStartDate ? moment(result.publicSalesStartDate) : null,
          publicSalesEndDate: result?.publicSalesEndDate ? moment(result.publicSalesEndDate) : null,
        }}
        onFinish={({
          roadmap,
          roadmapCn,
          roadmapZh,
          faqs,
          faqsCn,
          faqsZh,
          holderPerks,
          holderPerksCn,
          holderPerksZh,
          contractAbi,
          ...values
        }) => {
          const payload = {
            ...mapValuesToSetter({
              ...values,
            }),
            contractAbi,
            roadmap: roadmap.map(({ header, description }) => ({
              header,
              description,
            })),
            ...(roadmapCn && {
              roadmapCn: roadmapCn.map(({ header, description }) => ({
                header,
                description,
              })),
            }),
            ...(roadmapZh && {
              roadmapZh: roadmapZh.map(({ header, description }) => ({
                header,
                description,
              })),
            }),
            faqs: faqs.map(({ answer, question }) => ({
              answer,
              question,
            })),
            ...(faqsCn && {
              faqsCn: faqsCn.map(({ answer, question }) => ({
                answer,
                question,
              })),
            }),
            ...(faqsZh && {
              faqsZh: faqsZh.map(({ answer, question }) => ({
                answer,
                question,
              })),
            }),
            holderPerks: holderPerks.map(({ value }) => ({ value })),
            ...(holderPerksCn && {
              holderPerksCn: holderPerksCn.map(({ value }) => ({ value })),
            }),
            ...(holderPerksZh && {
              holderPerksZh: holderPerksZh.map(({ value }) => ({ value })),
            }),
            fiatCheckoutUrl: values.fiatCheckoutEnabled ? { set: values.fiatCheckoutUrl } : null,
          };

          // @ts-ignore
          formProps?.onFinish?.(payload);
        }}
      >
        <Tabs defaultActiveKey="en">
          <Tabs.TabPane tab="English" key="en">
            <MultiLanguageForm postfix={'en'} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Chinese (简体)" key="cn">
            <MultiLanguageForm postfix={'cn'} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Chinese (繁体)" key="zh">
            <MultiLanguageForm postfix={'zh'} />
          </Tabs.TabPane>
        </Tabs>

        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.banner')} name="banner" rules={[{ required: false }]}>
              <AliyunOSSUpload
                purpose="Banner"
                accept="image/*"
                description="Extension Allowed: .jpg/.jpeg/.png<br>Recommended Dimension: 1280 x 720 pixels"
                onChange={(response) => {
                  formProps.form?.setFieldsValue({
                    banner: response.url,
                  });
                }}
              />
              URL: <Link onClick={() => window.open(result?.banner)}>{result?.banner}</Link>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.image')} name="image" rules={[{ required: false }]}>
              <AliyunOSSUpload
                purpose="NFTImage"
                accept="image/*"
                description="Extension Allowed: .jpg/.jpeg/.png<br>Recommended Dimension: 1280 x 720 pixels"
                onChange={(response) => {
                  formProps.form?.setFieldsValue({
                    image: response.url,
                  });
                }}
              />
              URL: <Link onClick={() => window.open(result?.image)}>{result?.image}</Link>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={t('nftCollections.fields.creatorWalletAddress')}
          name="contractAddress"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('nftCollections.fields.contractNetwork')}
          name="contractNetwork"
          rules={[{ required: false }]}
        >
          <Select style={{ width: '100%' }}>
            {Object.values(Web3Network).map((option, index) => (
              <Select.Option key={`options${index}`} value={option}>
                {startCase(option)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('nftCollections.fields.contractAbi')} name="contractAbi" rules={[{ required: false }]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={t('nftCollections.fields.creatorWalletAddress')}
          name="creatorWalletAddress"
          rules={[{ required: false }]}
        >
          <Input />
        </Form.Item>

        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.freeMintStartDate')} name="freeMintStartDate">
              <DatePicker showTime format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.freeMintEndDate')} name="freeMintEndDate">
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm"
                style={{ width: '100%' }}
                disabledDate={(current) => {
                  return current && current < freeMintStartDate.subtract(1, 'day').endOf('day');
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('nftCollections.fields.freeMintPriceCurrencyCode')}
              name="freeMintPriceCurrencyCode"
              rules={[{ required: false }]}
            >
              <Select>
                {Object.values(CurrencyCode).map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('nftCollections.fields.freeMintPrice')}
              name="freeMintPrice"
              rules={[{ required: false }]}
            >
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.preSalesStartDate')} name="preSalesStartDate">
              <DatePicker showTime format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.preSalesEndDate')} name="preSalesEndDate">
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm"
                style={{ width: '100%' }}
                disabledDate={(current) => {
                  return current && current < preSalesStartDate.subtract(1, 'day').endOf('day');
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('nftCollections.fields.preSalesPriceCurrencyCode')}
              name="preSalesPriceCurrencyCode"
              rules={[{ required: false }]}
            >
              <Select>
                {Object.values(CurrencyCode).map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('nftCollections.fields.preSalesPrice')}
              name="preSalesPrice"
              rules={[{ required: false }]}
            >
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.publicSalesStartDate')} name="publicSalesStartDate">
              <DatePicker showTime format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.publicSalesEndDate')} name="publicSalesEndDate">
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm"
                style={{ width: '100%' }}
                disabledDate={(current) => {
                  return current && current < publicSalesStartDate.subtract(1, 'day').endOf('day');
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('nftCollections.fields.publicSalesPriceCurrencyCode')}
              name="publicSalesPriceCurrencyCode"
              rules={[{ required: false }]}
            >
              <Select>
                {Object.values(CurrencyCode).map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('nftCollections.fields.publicSalesPrice')}
              name="publicSalesPrice"
              rules={[{ required: false }]}
            >
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label={t('nftCollections.fields.fiatCheckout')} name="fiatCheckoutEnabled" valuePropName="checked">
          <Switch />
        </Form.Item>
        {fiatCheckoutEnabled && (
          <Form.Item
            label={t('nftCollections.fields.fiatCheckoutUrl')}
            name="fiatCheckoutUrl"
            rules={[{ required: false }, { type: 'url' }]}
          >
            <Input placeholder="https://example.com" />
          </Form.Item>
        )}

        <Form.Item
          label={t('nftCollections.fields.thirdwebPrebuiltContractVariant')}
          name="thirdwebPrebuiltContractVariant"
          rules={[{ required: true }]}
        >
          <Select>
            {Object.values(ThirdWebPrebuiltContractVariant).map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Edit>
  );
};
