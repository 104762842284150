import { memo } from 'react';
import { Button, Icons, Image, Tooltip, Typography, message } from '@pankod/refine-antd';
import { useCustom, useGetIdentity, useNavigation, useTranslate } from '@pankod/refine-core';
import { useFindMyChannel } from 'hooks/use-find-my-channel';
import { Identity } from 'providers';

const Profile = ({ collapsed }) => {
  const t = useTranslate();
  const { data: identity } = useGetIdentity<Identity>();
  const { push } = useNavigation();

  const isUser = identity?.role === 'user';

  const queryResult = useCustom({
    url: '',
    method: 'get',
    metaData: {
      operation: 'getMyChannel',
      fields: ['id', 'name', 'appLogo'],
    },
    queryOptions: {
      enabled: isUser,
    },
  });
  const result = queryResult?.data?.data;
  const channelUrl = `${process.env.REACT_APP_H5_API_BASE_URL}/c/${result?.name}`;

  if (!isUser) {
    return null;
  }

  return (
    <div className="flex flex-col items-center m-6 text-center text-white">
      {collapsed ? (
        <>
          <Image
            width={32}
            height={32}
            className="object-contain"
            src={result?.appLogo}
            style={{
              borderRadius: 16,
            }}
            placeholder={true}
          />

          <Button
            className="mt-6"
            type="primary"
            onClick={() => {
              push('/contents/videos/create');
            }}
            icon={<Icons.UploadOutlined />}
          />
        </>
      ) : (
        <>
          <Image
            width={80}
            height={80}
            className="object-contain"
            src={result?.appLogo}
            style={{ borderRadius: 40 }}
            placeholder={true}
          />

          <div className="w-52 truncate mb-6">
            <b>{result?.name}</b>

            <div className="flex flex-row items-center">
              <div className="truncate text-xs">{channelUrl}</div>
              <Tooltip placement="right" title={t('myChannel.copyToClipboard')}>
                <Button
                  type="link"
                  icon={<Icons.CopyOutlined />}
                  onClick={() => {
                    navigator.clipboard.writeText(channelUrl);
                    message.success(t('myChannel.copySuccess'));
                  }}
                />
              </Tooltip>
            </div>
          </div>

          <Button
            type="primary"
            style={{ width: '100%' }}
            onClick={() => {
              push('/contents/videos/create');
            }}
          >
            {t('myChannel.uploadContent')}
          </Button>
        </>
      )}
    </div>
  );
};

export default memo(Profile);
