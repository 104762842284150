import 'react-router-dom';
import { CrudFilters, CrudSorting, DataProvider, LogicalFilter } from '@pankod/refine-core';
import { GraphQLClient } from '@pankod/refine-graphql';
import camelCase from 'camelcase';
import { pascalCase } from 'change-case';
import * as gql from 'gql-query-builder';
import pluralize from 'pluralize';

const primaStringFilters = {
  eq: 'equals',
  lt: 'lt',
  gt: 'gt',
  lte: 'lte',
  gte: 'gte',
  in: 'in',
  nin: 'notIn',
  contains: 'contains',
  ne: false,
  ncontains: false,
  containss: false,
  ncontainss: false,
  null: false,
  empty: false,
};

const prismaInputType = {
  paginate: (name: string) => pascalCase(`${name}WhereUniqueInput`),
  findUnique: (name: string) => pascalCase(`${name}WhereUniqueInput`),
  findFirst: (name: string) => pascalCase(`${name}WhereInput`),
  findMany: (name: string) => pascalCase(`${name}WhereInput`),
  create: (name: string) => pascalCase(`${name}CreateInput`),
  createMany: (name: string) => pascalCase(`${name}CreateManyInput`),
  update: (name: string) => pascalCase(`${name}UpdateInput`),
  updateMany: (name: string) => pascalCase(`${name}UpdateManyInput`),
  orderBy: (name: string) => pascalCase(`${name}OrderByWithRelationInput`),
};

const generateSort = (sort?: CrudSorting) => {
  if (sort && sort.length > 0) {
    const sortQuery = sort.map((i) => {
      return {
        [i.field]: i.order,
      };
    });

    return sortQuery;
  }

  return [];
};

const generateFilter = (filters?: CrudFilters) => {
  if (filters && filters.length > 0) {
    let queryFilters: { [key: string]: any } = {};

    (filters as LogicalFilter[])
      .filter((f) => f.operator === 'null')
      .map((filter) => {
        queryFilters[filter.field] = {
          equals: null,
        };
      });
    (filters as LogicalFilter[])
      .filter((f) => f.operator === 'null' && !!f.value)
      .map((filter) => {
        queryFilters[filter.field] = filter.value;
      });

    (filters as LogicalFilter[])
      .filter((f) => f.operator === 'nnull')
      .map((filter) => {
        queryFilters[filter.field] = {
          not: { equals: null },
        };
      });

    (filters as LogicalFilter[])
      .filter((f) => f.operator === 'contains' && !!f.value)
      .map((filter) => {
        queryFilters[filter.field] = {
          [primaStringFilters[filter.operator]]: filter.value,
          mode: 'insensitive',
        };
      });

    (filters as LogicalFilter[])
      .filter((f) => f.operator === 'eq')
      .map((filter) => {
        queryFilters[filter.field] = {
          [primaStringFilters[filter.operator]]: filter.value,
        };
      });

    (filters as LogicalFilter[])
      .filter((f) => f.operator === 'between' && f.value.length === 2)
      .map((filter) => {
        queryFilters[filter.field] = {
          gte: filter.value[0],
          lte: filter.value[1],
        };
      });

    return queryFilters;
  }

  return {};
};

export const gqlDataProvider = (gqlClient: GraphQLClient): DataProvider => {
  return {
    getList: async ({ resource, pagination, sort, filters, metaData }) => {
      // metaData = { operation: 'customEndpoint', variablesOverrides: { where: '', orderBy: '' } }
      //
      const orderBy = generateSort(sort);
      const filterBy = generateFilter(filters);
      const operation = metaData?.operation || camelCase(`list_${resource}`);
      const singularResource = pluralize.singular(resource);

      const { query, variables } = gql.query({
        operation,
        variables: {
          page: pagination?.current || 1,
          perPage: pagination?.pageSize || 10,
          ...(orderBy.length > 0 && {
            orderBy: {
              value: orderBy,
              type: `[${prismaInputType.orderBy(singularResource)}!]`,
              ...metaData?.variablesOverrides?.orderBy,
            },
          }),
          ...(filterBy && {
            where: {
              value: filterBy,
              type: prismaInputType.findMany(singularResource),
              ...metaData?.variablesOverrides?.where,
            },
          }),
        },
        fields: [
          {
            items: metaData?.items,
            metadata: metaData?.metadata,
          },
        ],
      });

      const response = await gqlClient.request(query, variables);

      return {
        data: response[operation].items || [],
        total: response[operation].metadata?.totalCount || 0,
      };
    },

    getMany: async ({ resource, ids, metaData }) => {
      const { operation: op, operationType, fields } = metaData;
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(resource);

      const { query, variables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: { id: { in: ids } },
            type: operationType || prismaInputType.findMany(singularResource),
          },
        },
        fields: fields || [],
      });

      const response = await gqlClient.request(query, variables);

      return {
        data: response[operation],
      };
    },

    getOne: async ({ resource, id, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation ?? camelCase(singularResource);

      const { query, variables } = gql.query({
        operation,
        variables: {
          where: {
            value: { id },
            type: prismaInputType.findUnique(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields,
      });

      const response = await gqlClient.request(query, variables);

      return {
        data: response[operation],
      };
    },

    create: async ({ resource, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(`create_${singularResource}`);

      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          data: {
            value: variables,
            type: metaData?.operationType || prismaInputType.create(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ['id'],
      });

      const response = await gqlClient.request(query, gqlVariables);

      return {
        data: response[operation],
      };
    },

    createMany: async ({ resource, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation ?? camelCase(`create_many_${singularResource}`);

      const response = await Promise.all(
        variables.map(async (param) => {
          const { query, variables: gqlVariables } = gql.mutation({
            operation,
            variables: {
              input: {
                value: { data: param },
                type: prismaInputType.createMany(singularResource),
              },
            },
            fields: metaData?.fields ?? ['id'],
          });
          const result = await gqlClient.request(query, gqlVariables);

          return result[operation];
        }),
      );
      return {
        data: response,
      };
    },

    update: async ({ resource, id, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(`update_${singularResource}`);

      const { query, variables: gqlVariables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: { id },
            type: prismaInputType.findUnique(singularResource),
            required: true,
          },
          data: {
            value: variables,
            type: metaData?.operationType || prismaInputType.update(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ['id'],
      });

      const response = await gqlClient.request(query, gqlVariables);

      return {
        data: response[operation],
      };
    },

    updateMany: async ({ resource, ids, variables, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const camelUpdateName = camelCase(`update-${singularResource}`);

      const operation = metaData?.operation ?? camelUpdateName;

      const response = await Promise.all(
        ids.map(async (id) => {
          const { query, variables: gqlVariables } = gql.mutation({
            operation,
            variables: {
              input: {
                value: { where: { id }, data: variables },
                type: prismaInputType.updateMany(singularResource),
              },
            },
            fields: metaData?.fields ?? ['id'],
          });
          const result = await gqlClient.request(query, gqlVariables);

          return result[operation];
        }),
      );
      return {
        data: response,
      };
    },

    deleteOne: async ({ resource, id, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(`delete_${singularResource}`);

      const { query, variables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: { id },
            type: prismaInputType.findUnique(singularResource),
            required: true,
          },
        },
        fields: metaData?.fields ?? ['id'],
      });

      const response = await gqlClient.request(query, variables);

      return {
        data: response[operation],
      };
    },

    deleteMany: async ({ resource, ids, metaData }) => {
      const singularResource = pluralize.singular(resource);
      const operation = metaData?.operation || camelCase(`delete_many_${singularResource}`);

      const { query, variables } = gql.mutation({
        operation,
        variables: {
          where: {
            value: {
              id: { in: ids },
            },
            type: prismaInputType.findMany(singularResource),
            required: true,
          },
        },
        fields: ['count'],
      });

      const response = await gqlClient.request(query, variables);

      return {
        data: response[operation],
      };
    },
    getApiUrl: () => {
      throw Error('Not implemented on refine-graphql data provider.');
    },
    custom: async ({ url, method, headers, metaData }) => {
      if (!metaData) {
        throw Error('GraphQL need to operation, fields and variables values in metaData object.');
      }

      if (metaData.operation) {
        if (method === 'get') {
          const { query, variables } = gql.query({
            operation: metaData.operation,
            fields: metaData.fields,
            variables: metaData.variables,
          });

          const response = await gqlClient.request(query, variables);

          return {
            data: response[metaData.operation],
          };
        } else {
          const { query, variables } = gql.mutation({
            operation: metaData.operation,
            fields: metaData.fields,
            variables: metaData.variables,
          });

          const response = await gqlClient.request(query, variables);

          return {
            data: response[metaData.operation],
          };
        }
      } else {
        throw Error('GraphQL operation name required.');
      }
    },
  };
};
