import React from 'react';
import { HttpError, useNotification, useTranslate } from '@pankod/refine-core';
import { RESOURCE_NAME } from '../constant';
import { dataProvider } from 'App';

export const useDeletePlaylistVideo = ({ onSuccess }: { onSuccess: () => void }) => {
  const t = useTranslate();
  const { open } = useNotification();

  const [isFetching, setIsFetching] = React.useState(false);

  const deleteOne = async ({ videoId, playlistId }) => {
    try {
      setIsFetching(true);

      const result = await dataProvider.custom!({
        url: '',
        method: 'post',
        metaData: {
          operation: 'deletePlaylistVideo',
          fields: ['playlistId', 'videoId'],
          variables: {
            where: {
              value: {
                videoId_playlistId: {
                  videoId,
                  playlistId,
                },
              },
              type: `PlaylistVideoWhereUniqueInput`,
              required: true,
            },
          },
        },
      });

      if (result) {
        open({
          key: `success` + Date.now(),
          type: 'success',
          description: t('notifications.success'),
          message: t('notifications.deleteSuccess', {
            resource: t('playlists.playlistVideos'),
          }),
        });

        onSuccess();

        return result;
      }
    } catch (error: unknown) {
      open({
        key: `error` + Date.now(),
        type: 'error',
        description: t('notifications.error', {
          // ts-ignore
          statusCode: (error as HttpError).statusCode || 'unknown',
        }),
        message: error as any,
      });
    } finally {
      setIsFetching(false);
    }
  };

  const deleteMany = async ({ playlistId, videoIds }: { playlistId: string; videoIds: React.Key[] }) => {
    try {
      setIsFetching(true);

      const result = await dataProvider.custom!({
        url: '',
        method: 'post',
        metaData: {
          operation: 'updatePlaylist',
          fields: ['id'],
          variables: {
            where: {
              value: {
                id: playlistId,
              },
              type: `PlaylistWhereUniqueInput`,
              required: true,
            },
            data: {
              value: {
                playlistVideos: {
                  deleteMany: [{ videoId: { in: videoIds } }],
                },
              },
              type: `PlaylistUpdateInput`,
              required: true,
            },
          },
        },
      });

      if (result) {
        open({
          key: `success` + Date.now(),
          type: 'success',
          description: t('notifications.success'),
          message: t('notifications.deleteSuccess', {
            resource: t('playlists.playlistVideos'),
          }),
        });

        onSuccess();

        return result;
      }
    } catch (error: unknown) {
      open({
        key: `error` + Date.now(),
        type: 'error',
        description: t('notifications.error', {
          // ts-ignore
          statusCode: (error as HttpError).statusCode || 'unknown',
        }),
        message: error as any,
      });
    } finally {
      setIsFetching(false);
    }
  };

  return {
    deleteOne,
    deleteMany,
    isFetching,
  };
};
