import React from 'react';
import { useTranslate } from '@pankod/refine-core';
import { AliyunOSSUploadCroppable } from '../upload/aliyun-oss-upload-croppable';
import { EditOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

export const UploadImageDialog: React.FC<{
  title?: string;
  onSubmit: (url: string) => void;
}> = ({ title = '', onSubmit }) => {
  const t = useTranslate();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const showModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);
  const [url, setUrl] = React.useState('');
  const [key, setKey] = React.useState('');

  React.useEffect(() => {
    if (isModalVisible) {
      setUrl('');
      setKey(Date.now().toString());
    }
  }, [isModalVisible]);

  return (
    <>
      <Button type="link" icon={<EditOutlined />} onClick={showModal} />

      <Modal
        title={title}
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              onSubmit(url);
              closeModal();
            }}
          >
            {t('buttons.submit')}
          </Button>,
          <Button
            key="back"
            onClick={() => {
              closeModal();
            }}
          >
            {t('buttons.close')}
          </Button>,
        ]}
      >
        <AliyunOSSUploadCroppable
          aspect={80 / 80}
          purpose="PersonalBrandedSite"
          key={key}
          accept="image/*"
          description={
            t('upload.extension', {
              extension: '.jpg/.jpeg/.png',
            }) +
            '<br/>' +
            t('upload.recommendedDimension', { dimension: '80 x 80 pixels' })
          }
          onChange={(response) => {
            setUrl(response.url);
          }}
        />
      </Modal>
    </>
  );
};
