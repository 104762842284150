import { Button, Form, Input } from '@pankod/refine-antd';
import { useDataProvider, useTranslate } from '@pankod/refine-core';

export interface IForgotPasswordForm {
  email: string;
}

type Props = {
  onSubmit: (values: IForgotPasswordForm) => void;
};

export const ForgotPasswordForm: React.FC<Props> = ({ onSubmit }) => {
  const t = useTranslate();
  const [form] = Form.useForm<IForgotPasswordForm>();
  const dataProvider = useDataProvider();

  const emailRegisteredValidator = async (_: any, value: any) => {
    const { data } = await dataProvider().custom({
      url: '',
      method: 'get',
      metaData: {
        operation: 'checkEmailRegistered',
        variables: {
          email: {
            value: value ?? '',
            required: true,
          },
        },
      },
    });

    if (!data) {
      return Promise.reject(
        <div className="text-red-500 text-xs first-letter:capitalize">
          Seems like this is an unregistered email address. Please{' '}
          <a className="underline hover:text-blue-800 text-xs" href="/sign-up">
            sign&nbsp;up
          </a>{' '}
          a new Colorverse account with this email address!
        </div>,
      );
    }
    return Promise.resolve();
  };

  return (
    <Form<IForgotPasswordForm>
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      requiredMark={false}
      initialValues={{
        email: '',
      }}
    >
      <Form.Item
        name="email"
        label={t('pages.login.email', 'Email Address')}
        rules={[
          { required: true, message: 'Email Address is required' },
          { type: 'email', message: 'Valid Email is required' },
          {
            validator: emailRegisteredValidator,
          },
        ]}
        validateTrigger="onBlur"
        validateFirst={true}
      >
        <Input size="large" placeholder="Email address" />
      </Form.Item>
      <Form.Item shouldUpdate>
        {({ isFieldsTouched, getFieldsError }) => (
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="mt-8"
            block
            disabled={
              !isFieldsTouched(['email'], true) || getFieldsError().filter(({ errors }) => errors.length).length > 0
            }
          >
            {t('pages.forgotPassword.resetPassword', 'Reset Password')}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
