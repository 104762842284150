import React from 'react';
import { BsArrowUpRightSquare } from 'react-icons/bs';
import { Button, Col, Edit, Form, Icons, Image, Input, Row, Space, Tooltip, message } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { BannerTable } from 'pages/my-channel/BannerTable';
import { UploadImageDialog } from 'components/personal-brand';
import { useSearchPublicChannel } from './hooks/use-search-channel';
import { useUpdateMyChannel } from './hooks/use-update-my-channel';
import { useFindMyChannel } from 'hooks/use-find-my-channel';
import { useLeaveFormConfirmation } from 'hooks/use-leave-form-confirmation';
import { MediaTable } from './MediaTable';
import { mapValuesToSetter } from 'libs';

interface Link {
  key: string;
  title: string;
  url: string;
}

interface Banner {
  key: string;
  url: string;
  bannersUrl: string;
  scheduleEnd: string;
  scheduleStart: string;
}

interface Channel {
  id: string;
  name: string;
  appLogo: string;
  promotionalBanners: Banner[];
  aboutLinks: Link[];
}

export const MyChannelEdit: React.FC = () => {
  const t = useTranslate();

  const [form] = Form.useForm();
  const { onFieldsChange, onMutateSuccess } = useLeaveFormConfirmation();

  const queryResult = useFindMyChannel();
  const result = queryResult?.data?.data;

  const { update, isUpdating } = useUpdateMyChannel({
    onMutateSuccess: () => {
      onMutateSuccess();
    },
  });

  const baseChannelUrl = process.env.REACT_APP_H5_API_BASE_URL + '/c/';
  const channelUrl = baseChannelUrl + Form.useWatch('name', form);
  const appLogo = Form.useWatch('appLogo', form);

  const [mediaLinks, setMediaLinks] = React.useState<Channel['aboutLinks']>([]);
  const [banners, setBanners] = React.useState<Channel['promotionalBanners']>([]);

  React.useEffect(() => {
    if (result) {
      setMediaLinks(
        (result?.aboutLinks || []).map((link, index) => ({
          key: `link_${index}`,
          title: link.title,
          url: link.url,
        })),
      );
      setBanners(
        (result?.promotionalBanners || []).map((banner, index) => ({
          key: `banners_${index}`,
          url: banner['promotional_url'] || '',
          bannersUrl: banner['banner_url'] || '',
          scheduleStart: banner['schedule_start'] || '',
          scheduleEnd: banner['schedule_end'] || '',
        })),
      );
    }
    return () => {
      form.resetFields();
    };
  }, [form, result]);

  const {
    search: debouncedSearch,
    isSearching,
    isValidName,
  } = useSearchPublicChannel({
    form,
    exclude: result?.name,
  });

  const isFetching = queryResult.isFetching || isUpdating;

  return (
    <Edit
      pageHeaderProps={{
        title: t('myChannel.editMyChannel'),
        extra: <></>,
      }}
      isLoading={isFetching}
      saveButtonProps={{
        disabled: isUpdating || !isValidName,
        onClick: () => {
          form.submit();
        },
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name: result?.name,
          appLogo: result?.appLogo,
          aboutMe: result?.aboutMe,
        }}
        onFieldsChange={onFieldsChange}
        onFinish={(values) => {
          update({
            ...mapValuesToSetter({
              appLogo: values.appLogo,
              name: values.name,
              aboutMe: values.aboutMe,
            }),
            promotionalBanners: banners.map((banner) => {
              return {
                archived: null,
                promotional_url: banner?.url || null,
                banner_url: banner?.bannersUrl || null,
                schedule_start: banner?.scheduleStart || null,
                schedule_end: banner?.scheduleEnd || null,
              };
            }),
            aboutLinks: mediaLinks.map((item) => ({
              title: item.title,
              url: item.url,
            })),
          });
        }}
      >
        <Row gutter={[10, 0]} justify="start" align="bottom">
          <Col span={24}>
            <Form.Item label={t('myChannel.logo')} name="appLogo">
              <Space>
                <Image width={80} height={80} className="object-contain" src={appLogo} />
                <UploadImageDialog
                  title={t('myChannel.uploadLogo')}
                  onSubmit={(url) => {
                    form.setFieldValue('appLogo', url);
                  }}
                />
              </Space>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('myChannel.url')}
              name="name"
              rules={[
                {
                  min: 3,
                  message: t('errors.min', { field: t('myChannel.url'), min: 3 }),
                },
                {
                  required: true,
                  message: t('errors.required', { field: t('myChannel.url') }),
                },
                {
                  pattern: /^(?!\d+$)[a-zA-Z0-9\s]+$/,
                  message: t('errors.string', { field: t('myChannel.url') }),
                },
                {
                  pattern: /^\S+$/,
                  message: t('errors.space', { field: t('myChannel.url') }),
                },
              ]}
            >
              <Input
                placeholder={'Url'}
                onChange={(e) => {
                  debouncedSearch(e.target.value);
                }}
                suffix={
                  isSearching ? (
                    <Icons.LoadingOutlined />
                  ) : isValidName ? (
                    <Icons.CheckCircleOutlined style={{ color: 'green' }} />
                  ) : (
                    <Icons.CloseCircleOutlined style={{ color: 'red' }} />
                  )
                }
              />
            </Form.Item>

            <div className="mb-2">
              {channelUrl}

              <Tooltip placement="top" title={t('myChannel.copyToClipboard')}>
                <Button
                  type="link"
                  style={{ marginLeft: '5px' }}
                  icon={<Icons.CopyOutlined />}
                  onClick={() => {
                    navigator.clipboard.writeText(channelUrl);
                    message.success(t('myChannel.copySuccess'));
                  }}
                />
              </Tooltip>
              <Tooltip placement="top" title={t('myChannel.visitInNewTab')}>
                <Button
                  disabled={result?.name !== form.getFieldValue('name')}
                  type="link"
                  icon={<BsArrowUpRightSquare />}
                  onClick={() => {
                    window.open(baseChannelUrl + result?.name);
                  }}
                />
              </Tooltip>
            </div>
          </Col>
        </Row>

        <Row gutter={[10, 0]} justify="start" align="bottom">
          <Col span={12}>
            <Form.Item label={t('myChannel.aboutMe')} name="aboutMe">
              <Input.TextArea placeholder={t('myChannel.aboutMe')} />
            </Form.Item>
          </Col>

          <Col span={24}>
            <BannerTable
              banners={banners}
              onChange={(values) => {
                onFieldsChange();
                setBanners(values);
              }}
            />
          </Col>

          <Col span={24}>
            <MediaTable
              data={mediaLinks || []}
              onChange={(values) => {
                onFieldsChange();
                setMediaLinks(values);
              }}
            />
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
