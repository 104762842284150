import React from 'react';
import { useTranslate, useUpdate } from '@pankod/refine-core';

export const useToggleChannelGift = ({ channelId }: { channelId: string }) => {
  const { mutate, isSuccess } = useUpdate();
  const t = useTranslate();

  const toggle = async (checked: boolean) => {
    if (channelId) {
      mutate({
        resource: 'channel',
        id: channelId,
        values: {
          isGiftEnabled: { set: checked },
        },
        successNotification: {
          type: 'success',
          message: t('notifications.editSuccess', {
            resource: t('myChannel.channel'),
          }),
        },
      });
    }
  };

  return { toggle, isSuccess };
};
