import { useEffect } from 'react';
import { Breadcrumb, Col, Edit, Form, Icons, Input, Row, Select, Tooltip, Typography } from '@pankod/refine-antd';
import { IResourceComponentsProps, useCustom, useTranslate } from '@pankod/refine-core';
import { useUpdateMyAccount } from 'pages/my-channel/hooks/use-update-my-account';
import { useUpdateMyChannel } from 'pages/my-channel/hooks/use-update-my-channel';
import { useLeaveFormConfirmation } from 'hooks/use-leave-form-confirmation';
import { mapValuesToSetter } from '../../libs';
import { RESOURCE_FIELDS } from './constant';
import { CurrencyCode } from 'api';
import { IChannel, IUser } from 'interfaces';
import { get, omit } from 'lodash';

const { Text } = Typography;
const { Option } = Select;

export const AccountSettingEditPayout: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const [form] = Form.useForm();
  const { onFieldsChange, onMutateSuccess } = useLeaveFormConfirmation();

  const queryResult = useCustom<IUser & { channel: IChannel }>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'me',
      fields: [...RESOURCE_FIELDS, { channel: ['id', 'currencyCode'] }],
    },
  });
  const result = queryResult?.data?.data;

  const { update } = useUpdateMyChannel({
    silence: true,
    onMutateSuccess: () => {
      onMutateSuccess();
    },
  });
  const { updateAccount } = useUpdateMyAccount();

  useEffect(() => {
    form.setFieldsValue(result);

    return () => {
      form.resetFields();
    };
  }, [form, result]);

  return (
    <Edit
      title={t('payout.accountSettingPayout')}
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        disabled: queryResult?.isFetching,
        onClick: () => {
          form.submit();
        },
      }}
      pageHeaderProps={{
        breadcrumb: <Breadcrumb hideIcons />,
        extra: <></>,
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onFieldsChange={onFieldsChange}
        onFinish={async (values) => {
          await updateAccount({
            ...mapValuesToSetter(omit(values, 'channel')),
          });

          if (process.env.REACT_APP_ENABLE_MULTI_CURRENCY === 'true') {
            await update({
              ...mapValuesToSetter(get(values, 'channel')),
            });
          }
        }}
      >
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} lg={24}>
            <div style={{ marginBottom: '20px' }}>
              <Typography.Title level={4} underline>
                {t('payout.payoutInformation')}
              </Typography.Title>
            </div>

            <Row>
              <Col xs={24} lg={12}>
                <Row align={'middle'}>
                  <Text strong style={{ marginRight: '5px' }}>
                    * {t('payout.fields.bankAccountHolderName')}
                  </Text>
                  <Tooltip title={t('payout.payoutInformationTips')}>
                    <Icons.InfoCircleOutlined />
                  </Tooltip>
                </Row>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name="bankAccountHolderName" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={12}>
                <Text strong>* {t('payout.fields.bankName')}</Text>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name="bankName" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={12}>
                <Text strong>* {t('payout.fields.bankAccountNumber')}</Text>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name="bankAccountNumber" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={12}>
                <Row align={'middle'}>
                  <Text strong style={{ marginRight: '5px' }}>
                    {t('payout.fields.swiftCode')}
                  </Text>
                  <Text style={{ fontSize: '12px' }}>{t('payout.swiftCodeTips')}</Text>
                </Row>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name="bankSwiftCode" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={12}>
                <Row align={'middle'}>
                  <Text strong style={{ marginRight: '5px' }}>
                    {t('payout.fields.branchCode')}
                  </Text>
                  <Text style={{ fontSize: '12px' }}>{t('payout.swiftCodeTips')}</Text>
                </Row>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name="bankBranchCode" rules={[{ required: false }]}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} lg={12}>
                <Row align={'middle'}>
                  <Text strong style={{ marginRight: '5px' }}>
                    * {t('payout.fields.currencyToReceived')}
                  </Text>
                  <Tooltip title={t('payout.currencyToReceivedTips')}>
                    <Icons.InfoCircleOutlined />
                  </Tooltip>
                </Row>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item name="currencyCode" rules={[{ required: true }]}>
                  <Select>
                    <Option key={'MYR'} value={'MYR'}>
                      MYR
                    </Option>
                    <Option key={'USD'} value={'USD'}>
                      USD
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {process.env.REACT_APP_ENABLE_MULTI_CURRENCY === 'true' && (
              <Row>
                <Col xs={24} lg={12}>
                  <Row align={'middle'}>
                    <Text strong style={{ marginRight: '5px' }}>
                      * {t('payout.fields.displayCurrency')}
                    </Text>
                    <Tooltip title={t('payout.displayCurrencyTips')}>
                      <Icons.InfoCircleOutlined />
                    </Tooltip>
                  </Row>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item name={['channel', 'currencyCode']} rules={[{ required: true }]}>
                    <Select>
                      <Option key={'MYR'} value={CurrencyCode.Myr}>
                        MYR
                      </Option>
                      <Option key={'USD'} value={CurrencyCode.Usd}>
                        USD
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
