import { useState } from 'react';
import { message } from '@pankod/refine-antd';
import { CrudFilters, LogicalFilter, useNotification } from '@pankod/refine-core';
import { dataProvider } from 'App';
import { IChannelTransactionSummary } from 'interfaces';
import { simplifyErrorMessage } from 'libs';
import { formatMoney } from 'libs/number';
import { utils, writeFile } from 'xlsx';

const requestData = async ({ filters }: { filters: CrudFilters }) => {
  const channelId = (filters as LogicalFilter[]).find(({ field }) => field === 'channelId')?.value || '';
  const [dateFrom, dateTo] = (filters as LogicalFilter[]).find(({ field }) => field === 'createdAt')?.value || [
    null,
    null,
  ];

  return dataProvider.custom<IChannelTransactionSummary[]>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'getChannelsTransactionSummary',
      fields: [
        'currencyCode',
        'amount',
        'payPerView',
        'gift',
        'subscription',
        'tips',
        'userId',
        'username',
        'bankAccountHolderName',
        'bankName',
        'bankSwiftCode',
        'bankBranchCode',
        'bankAccountNumber',
        'channelId',
        'channelName',
      ],
      variables: {
        where: {
          value: {
            channelId: channelId || null,
            dateFrom,
            dateTo,
          },
          type: 'ChannelsTransactionSummaryInput',
          required: true,
        },
      },
    },
  });
};

export const useExportChannelTransactionSummary = ({
  filters,
  headers,
}: {
  filters: CrudFilters;
  headers: string[];
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { open } = useNotification();
  const filename = `channel-transaction-report-${new Date().toLocaleString()}`;

  const triggerExport = async () => {
    try {
      setIsLoading(true);

      const result = (await requestData({ filters }))?.data || [];

      if (result.length === 0) {
        setIsLoading(false);

        open({
          key: `error` + Date.now(),
          type: 'error',
          description: `Error on exporting!`,
          message: 'No record available to export',
        });
        return;
      }

      const workbook = utils.book_new();

      const rowData = result.map((item) => {
        let values = {
          'Creator Channel ID': item?.channelId,
          'Creator Channel name': item?.channelName,
          'Creator Username': item?.username,
          Currency: item.currencyCode,
          'Total Pay-Per-View Amount': formatMoney((item.payPerView || 0) / 100, {
            currency: item.currencyCode,
          }),
          'Total Subscription Amount': formatMoney((item.subscription || 0) / 100, {
            currency: item.currencyCode,
          }),
          'Total Gift Amount': formatMoney((item.gift || 0) / 100, {
            currency: item.currencyCode,
          }),
          'Total Tips Amount': formatMoney((item.tips || 0) / 100, {
            currency: item.currencyCode,
          }),
          'Total Transaction Amount': formatMoney((item.amount || 0) / 100, {
            currency: item.currencyCode,
          }),
          'Total Creator Revenue Amount': formatMoney((item.amount * 0.8 || 0) / 100, {
            currency: item.currencyCode,
          }),
          'Bank Account Holder Name': item.bankAccountHolderName,
          'Bank Name': item.bankName,
          'Bank SwiftCode': item.bankSwiftCode,
          'Bank Branch Code': item.bankBranchCode,
          'Bank Account Number': item.bankAccountNumber,
        };

        Object.keys(values).forEach((key) => {
          values[key] = values[key] || 'N/A';
        });

        return values;
      });

      const worksheet = utils.json_to_sheet([]);

      let rowNumber = 1;
      headers.map((header) => {
        utils.sheet_add_aoa(worksheet, [[header]], { origin: `A${rowNumber++}` });
      });
      utils.sheet_add_aoa(worksheet, [[]], { origin: `A${rowNumber++}` });
      utils.sheet_add_json(worksheet, rowData, { skipHeader: false, origin: `A${rowNumber++}` });
      utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

      writeFile(workbook, `${filename}.xlsx`, { compression: true });
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || 'Error while exporting');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    triggerExport,
  };
};
