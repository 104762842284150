import React from 'react';
import {
  Breadcrumb,
  Col,
  CreateButton,
  DateField,
  EditButton,
  ImageField,
  List,
  Row,
  Space,
  Table,
} from '@pankod/refine-antd';
import { IResourceComponentsProps, useTranslate } from '@pankod/refine-core';
import { NFTCollectionFilter } from './components/nft-collection-filter';
import { useListNFTCollections } from 'hooks/use-list-nft-collections';
import { INftCollection } from 'interfaces';
import { removeHtmlTags } from 'libs/remove-html-tag';

export const NftCollectionList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, searchFormProps } = useListNFTCollections({});

  const Actions: React.FC = () => (
    <Space>
      <CreateButton />
    </Space>
  );

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={24} lg={24} xs={24}>
          <NFTCollectionFilter formProps={searchFormProps} />
        </Col>

        <Col xl={24} xs={24}>
          <List
            pageHeaderProps={{
              title: t('nftCollections.nftCollections'),
              breadcrumb: <Breadcrumb hideIcons />,
              extra: <Actions />,
            }}
          >
            <Table {...tableProps} rowKey="id">
              {/* <Table.Column<INftCollection> dataIndex="id" title={'Id'} /> */}
              <Table.Column<INftCollection>
                dataIndex="image"
                title={t('nftCollections.fields.image')}
                render={(_, record) => (
                  <ImageField
                    value={record?.image ?? record?.image}
                    title={record.image ?? record?.image}
                    width={120}
                  />
                )}
              />
              <Table.Column<INftCollection>
                dataIndex="title"
                title={t('nftCollections.fields.titleLanguage', { language: 'EN' })}
              />
              <Table.Column<INftCollection>
                dataIndex="description"
                title={t('nftCollections.fields.descriptionLanguage', { language: 'EN' })}
                render={(_, record) => (
                  <div style={{ width: 200 }} className="truncate">
                    {removeHtmlTags(record?.description ?? '')}
                  </div>
                )}
              />
              <Table.Column<INftCollection>
                dataIndex="contractAddress"
                title={t('nftCollections.fields.contractAddress')}
                render={(_, record) => (
                  <div style={{ width: 200 }} className="truncate">
                    {record?.contractAddress}
                  </div>
                )}
              />
              <Table.Column<INftCollection>
                dataIndex="contractNetwork"
                title={t('nftCollections.fields.contractNetwork')}
              />
              <Table.Column<INftCollection>
                title={t('nftCollections.fields.createdAt')}
                dataIndex="createdAt"
                key="createdAt"
                render={(_, record) => (record.createdAt ? <DateField value={record.createdAt} format="LLL" /> : '-')}
              />
              <Table.Column<INftCollection>
                width="5%"
                key="actions"
                title={t('tables.actions')}
                dataIndex="actions"
                render={(_, record) => (
                  <Space>
                    <EditButton hideText size="small" recordItemId={record.id} />
                    {/* <Button size="small" icon={<Icons.MessageOutlined />} onClick={() => {}} /> */}
                  </Space>
                )}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};
