import React, { useEffect } from 'react';
import { message } from '@pankod/refine-antd';
import { useNavigation, usePermissions, useTranslate } from '@pankod/refine-core';
import { useCustom } from '@pankod/refine-core';
import { IUser } from 'interfaces';
import { Identity } from 'providers/jwtAuthProvider';

export const withRouteGuard =
  <P,>(WrappedComponent: React.ComponentType<P>, role: Identity['role'], verifyEmailBeforeEnter?: boolean) =>
  (props: P) => {
    const { data: currentRole } = usePermissions();
    const { replace } = useNavigation();
    const t = useTranslate();

    const queryResult = useCustom<IUser>({
      url: '',
      method: 'get',
      metaData: {
        operation: 'me',
        fields: ['id', 'isEmailVerified'],
      },
      queryOptions: {
        enabled: currentRole === 'user',
      },
    });

    useEffect(() => {
      if (currentRole && currentRole !== role) {
        replace('/');
        message.error(t('notifications.unauthorizedAccessMessage'));
      }
    }, [currentRole, replace, t]);

    useEffect(() => {
      if (queryResult.data) {
        if (verifyEmailBeforeEnter && !queryResult.data?.data?.isEmailVerified) {
          replace('/account-settings/profile');
          message.error(t('notifications.unverifiedEmailAccessMessage'));
        }
      }
    }, [queryResult, replace, t]);

    return currentRole ? <WrappedComponent {...props} /> : null;
  };
