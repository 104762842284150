export const RESOURCE_NAME = 'videos';

export const RESOURCE_FIELDS = [
  'id',
  'title',
  'description',
  'playbackUrl',
  'duration',
  'language',
  'genre',
  'rating',
  'subtitleLanguage',
  'thumbnailUrl',
  'subtitleUrl',
  'status',
  'scheduleStart',
  'scheduleEnd',
  'isPayPerView',
  'payPerViewPrice',
  'productUrl',
  'isVertical',
] as const;

export const PAGINATION_FIELDS = ['currentPage', 'perPage', 'totalCount', 'totalPages'] as const;

export const voLanguageOptions = ['Chinese (Cantonese)', 'Chinese (Mandarin)', 'Malay', 'English', 'Other'].map(
  (o) => ({ label: o, value: o }),
);

export const subtitleLanguageOptions = [
  'Chinese (Cantonese)',
  'Traditional Chinese',
  'Simplified Chinese',
  'Malay',
  'English',
  'Other',
].map((o) => ({ label: o, value: o }));

export const ratingOptions = [
  { label: 'General Audiences', value: 'GENERAL' },
  { label: 'PG-13', value: 'PG13' },
  { label: 'R (18+)', value: 'R18' },
];

export const genreOptions = [
  'AFO Radio',
  'Animation',
  'Beauty',
  'Dance',
  'Documentary',
  'Drama',
  'Fitness',
  'Food',
  'Health',
  'Infotainment',
  'Kids',
  'Music',
  'News',
  'Personal Blog',
  'Travel',
  'Variety',
].map((o) => ({ label: o, value: o }));

export const videoStatusOptions = [
  {
    label: 'Video is processing',
    value: 'WAITING_FOR_UPLOAD',
  },
  {
    label: 'Released',
    value: 'RELEASED',
  },
  {
    label: 'Deactivated by Admin',
    value: 'DEACTIVATED_BY_ADMIN',
  },
];
