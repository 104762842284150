import React from 'react';
import { useTable, useTableReturnType } from '@pankod/refine-antd';
import { CrudFilters, CrudSort, HttpError, MetaDataQuery } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { RESOURCE_FIELDS, RESOURCE_NAME } from '../constant';
import { IVideo, IVideoFilterVariables } from 'interfaces';

export const useListVideos = ({
  metadata,
}: {
  metadata?: MetaDataQuery;
}): useTableReturnType<IVideo, IVideoFilterVariables> => {
  const initialSorter: CrudSort[] = [{ field: 'uploadedAt', order: 'desc' }];
  const initialPageSize = 10;
  const initialFilter = [{ field: 'archivedAt', operator: 'null', value: '' }];

  const result = useTable<IVideo, HttpError, IVideoFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      ...metadata,
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter,
    initialPageSize,
    initialFilter: initialFilter as CrudFilters,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { title, createdDates, genre, status } = params;

      filters.push({
        field: 'title',
        operator: 'contains',
        value: title,
      });

      filters.push({
        field: 'genre',
        operator: 'eq',
        value: genre || null,
      });

      filters.push({
        field: 'status',
        operator: 'eq',
        value: status || null,
      });

      filters.push({
        field: 'createdAt',
        operator: 'between',
        value:
          createdDates?.length > 0
            ? [createdDates[0].startOf('day').toDate(), createdDates[1].endOf('day').toDate()]
            : [],
      });

      return filters;
    },
  });

  return {
    ...result,
  };
};
