import { Trans } from 'react-i18next';
import { AntdLayout, Button, Card, Checkbox, Col, Form, Input, Row, Typography } from '@pankod/refine-antd';
import { useLogin, useNavigation, useTranslate } from '@pankod/refine-core';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { ILoginForm } from 'interfaces';

const { Text, Title } = Typography;

export const AdminLoginPage: React.FC = () => {
  const t = useTranslate();
  const [form] = Form.useForm<ILoginForm>();
  const { mutate: login } = useLogin<ILoginForm>();

  const CardTitle = (
    <>
      <Title level={1} className="float-left">
        <Trans i18nKey="pages.login.message" defaults="Admin Log in" />
      </Title>
    </>
  );

  return (
    <AntdLayout
      style={{
        backgroundImage: `url(/images/login-bg.png)`,
        backgroundSize: 'cover',
      }}
    >
      <Row className="min-h-[100vh]" justify="center" align="middle">
        <Col xs={22}>
          <div className="container px-2 md:px-4 md:py-24 mx-auto flex flex-wrap justify-end items-center mt-10">
            <div className="lg:w-2/8 xl:w-2/6 md:w-3/6 sm:w2/6 max-h-[90vh] h-4/6 shadow-2xl rounded-b-lg">
              <Card title={CardTitle} headStyle={{ borderBottom: 0 }} bodyStyle={{ paddingTop: 0 }}>
                <Form<ILoginForm>
                  className="space-y-2 md:space-y-4 h-full"
                  layout="vertical"
                  form={form}
                  onFinish={(values) => {
                    login({
                      ...values,
                      role: 'admin',
                    });
                  }}
                  requiredMark={false}
                  initialValues={{
                    remember: false,
                    username: '',
                    password: '',
                  }}
                >
                  <Form.Item
                    className="mb-2 block"
                    name="username"
                    label={t('pages.login.email', 'Email Address')}
                    rules={[
                      {
                        required: true,
                        message: 'Email Address is required',
                      },
                      {
                        type: 'email',
                        message: 'Valid Email is required',
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Email address" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label={t('pages.login.password', 'Password')}
                    rules={[
                      { required: true, message: 'Password is required' },
                      { min: 6, message: 'Minimum 6 characters is required' },
                    ]}
                    className="mb-2 block"
                  >
                    <Input.Password
                      placeholder="Enter password"
                      size="large"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone className="text-gray-500" /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                  <Form.Item className="mb-2 block" shouldUpdate>
                    {({ isFieldsTouched, getFieldsError }) => (
                      <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        block
                        disabled={
                          !isFieldsTouched(['username', 'password'], true) ||
                          getFieldsError().filter(({ errors }) => errors.length).length > 0
                        }
                      >
                        {t('pages.login.signin', 'Sign in')}
                      </Button>
                    )}
                  </Form.Item>
                  <div className="my-1 flex flex-row justify-between items-center">
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox
                        style={{
                          fontSize: '12px',
                        }}
                      >
                        {t('pages.login.remember', 'Remember me')}
                      </Checkbox>
                    </Form.Item>
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
