import { Button, Col, Collapse, Form, FormProps, Icons, Input, Row } from '@pankod/refine-antd';
import { CrudFilters, useTranslate } from '@pankod/refine-core';
import { DatePicker } from 'antd';
import { IMyFanTransactionFilterVariables } from 'interfaces';

export const RevenueFilter: React.FC<{
  formProps?: FormProps<IMyFanTransactionFilterVariables>;
  filters?: CrudFilters;
  tableQueryResult: any;
}> = (props) => {
  const t = useTranslate();
  return (
    <Collapse>
      <Collapse.Panel header={t('filters.filter')} key="1">
        <Form layout="vertical" {...props.formProps}>
          <Row gutter={[10, 0]} align="bottom">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label={t('revenueReport.filters.videoTitle')} name="title">
                <Input prefix={<Icons.SearchOutlined />} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label={t('revenueReport.filters.transactionDate')} name="transactionDates">
                <DatePicker.RangePicker
                  style={{ width: '100%' }}
                  allowClear={false}
                  placeholder={[t('revenueReport.filters.startDate'), t('revenueReport.filters.endDate')]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[10, 0]} align="bottom">
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button style={{ width: '100%' }} htmlType="submit" type="primary">
                  {t('buttons.submit')}
                </Button>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button
                  style={{ width: '100%' }}
                  onClick={() => {
                    props.formProps.form.resetFields();
                    props.formProps.form.submit();
                  }}
                  type="primary"
                >
                  {t('buttons.reset')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};
