import React from 'react';
import { Button, Col, Modal, Row, Typography } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { useGetChannel } from 'hooks/use-get-channel';

const { Text, Paragraph } = Typography;

export const ChannelDetailDialog: React.FC<{
  channelId: string;
  close: () => void;
}> = ({ channelId, close }) => {
  const t = useTranslate();

  const queryResult = useGetChannel(channelId);

  const result = queryResult?.data?.data;

  return (
    <Modal
      title={t('channelsManagement.channelDetails')}
      width={800}
      visible={queryResult?.isSuccess}
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          OK
        </Button>,
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Paragraph>
            <Text strong>{t('channelsManagement.fields.addressLine1')}: </Text>
            <Text>{result?.user?.companyAddressLine1 || 'N/A'} </Text>
          </Paragraph>
          <Paragraph>
            <Text strong>{t('channelsManagement.fields.addressLine2')}: </Text>
            <Text>{result?.user?.companyAddressLine2 || 'N/A'}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>{t('channelsManagement.fields.city')}: </Text>
            <Text>{result?.user?.companyAddressCity || 'N/A'}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>{t('channelsManagement.fields.state')}: </Text>
            <Text>{result?.user?.companyAddressState || 'N/A'}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>{t('channelsManagement.fields.postcode')}: </Text>
            <Text>{result?.user?.companyAddressPostcode || 'N/A'}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>{t('channelsManagement.fields.joinDate')}: </Text>
            <Text>{result?.approvedAt || 'N/A'}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>{t('channelsManagement.fields.type')}: </Text>
            <Text>Channel</Text>
          </Paragraph>
        </Col>

        <Col span={24}>
          <Paragraph>
            <Text strong>{t('channelsManagement.fields.personInChargeContact')}: </Text>
            <ul>
              <li>{result?.user?.contactPersonName || 'N/A'}</li>
              <li>{result?.user?.contactPersonMobileNumber || 'N/A'}</li>
              <li>{result?.user?.contactPersonEmail || 'N/A'}</li>
            </ul>
          </Paragraph>
        </Col>

        <Col span={12}>
          <Paragraph>
            <Text strong>{t('channelsManagement.fields.genre')}: </Text>
            <ul>
              {(result?.genres || []).map((genre, index) => (
                <li key={index}>{genre || ''}</li>
              ))}
            </ul>
          </Paragraph>
        </Col>

        <Col span={12}>
          <Paragraph>
            <Text strong>{t('channelsManagement.fields.bankAccountDetails')}: </Text> <br />
            <Text>{result?.user?.bankName}</Text> <br />
            <Text>{result?.user?.bankAccountNumber}</Text> <br />
          </Paragraph>
        </Col>
      </Row>
    </Modal>
  );
};
