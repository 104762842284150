import React from 'react';
import { Breadcrumb, Col, DateField, ExportButton, List, Row, Space, Table } from '@pankod/refine-antd';
import { IResourceComponentsProps, useTranslate } from '@pankod/refine-core';
import { RevenueFilter } from './components/revenue-filter';
import { RevenueSummary } from './components/revenue-summary';
import { useExportRevenueReport } from './hooks/use-export-revenue-report';
import { useListMyFanTransactions } from 'hooks/list-my-fan-transactions';
import { ProductType } from 'api';
import { ITransaction } from 'interfaces';
import { formatMoney } from 'libs/number';

export const MyRevenueReport: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { tableProps, searchFormProps, tableQueryResult, filters } = useListMyFanTransactions();

  const transactionDates = searchFormProps.form.getFieldValue('transactionDates') || [];
  const title = searchFormProps.form.getFieldValue('title');

  const exportRevenue = useExportRevenueReport({
    title,
    transactionDates,
  });

  const Actions: React.FC = () => (
    <Space>
      <ExportButton
        loading={exportRevenue.isLoading}
        onClick={() => {
          exportRevenue.triggerExport();
        }}
        disabled={tableQueryResult?.data?.total < 1}
      >
        {t('revenueReport.exportRevenueReport')}
      </ExportButton>
    </Space>
  );

  return (
    <>
      <RevenueSummary dateFrom={transactionDates[0] || null} dateTo={transactionDates[1] || null} />

      <br />

      <Row gutter={[16, 16]}>
        <Col xl={24} lg={24} xs={24}>
          <RevenueFilter formProps={searchFormProps} tableQueryResult={tableQueryResult} filters={filters || []} />
        </Col>

        <Col xl={24} xs={24}>
          <List
            pageHeaderProps={{
              title: t('revenueReport.revenueReportResult', { count: tableQueryResult?.data?.total }),
              breadcrumb: <Breadcrumb hideIcons />,
              extra: <Actions />,
            }}
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column<ITransaction>
                dataIndex="createdAt"
                title={t('revenueReport.fields.createdAt')}
                render={(_, record) => <DateField value={record.createdAt} format="lll" />}
                fixed="left"
              />
              <Table.Column<ITransaction>
                title={t('revenueReport.fields.monetizationTools')}
                render={(_, record) => record?.type || '-'}
              />
              <Table.Column<ITransaction> title={t('revenueReport.fields.description')} dataIndex="description" />
              <Table.Column<ITransaction>
                title={t('revenueReport.fields.videoTitle')}
                render={(_, record) => {
                  if (record.type === ProductType.PayPerView) {
                    return <>{record?.lineItem?.title}</>;
                  }
                  return 'N/A';
                }}
              />
              <Table.Column<ITransaction>
                dataIndex="amount"
                title={t('revenueReport.fields.revenue', { currencyCode: 'MYR' })}
                render={(_, record) => formatMoney((record.amount * 0.8 || 0) / 100)}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};
