import { message } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { dataProvider } from 'App';
import { IComment } from 'interfaces';
import { mapValuesToSetter, simplifyErrorMessage } from 'libs';

export const useSoftDeleteChannelGift = ({ onSuccess }: { onSuccess: () => void }) => {
  const t = useTranslate();

  const softDelete = async (id: string) => {
    try {
      const result = await dataProvider.custom<IComment>({
        url: '',
        method: 'post',
        metaData: {
          operation: 'updateGift',
          variables: {
            where: {
              value: { id },
              type: `GiftWhereUniqueInput`,
              required: true,
            },
            data: {
              value: mapValuesToSetter({
                archivedAt: new Date(),
              }),
              type: 'GiftUpdateInput',
              required: true,
            },
          },
          fields: ['id'],
        },
      });

      message.success(t('notifications.deleteSuccess', { resource: t('virtualGifting.virtualGifting') }));

      if (result.data.id) {
        onSuccess();
      }
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || 'Error while delete virtual gift');
    }
  };

  return {
    softDelete,
  };
};
