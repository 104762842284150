import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, message } from '@pankod/refine-antd';
import { useDataProvider, useTranslate } from '@pankod/refine-core';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { VerificationPurpose } from 'api';
import { simplifyErrorMessage } from 'libs';

export interface IResetPasswordForm {
  otpToken: string;
  password: string;
}

type Props = {
  email: string;
  isSuccessCallback?: Function;
};

export const ResetPasswordForm: React.FC<Props> = ({ email, isSuccessCallback }) => {
  const dataProvider = useDataProvider();
  const t = useTranslate();
  const [form] = Form.useForm<IResetPasswordForm>();
  const [isResend, setIsResend] = useState(false);
  const [nextOTPRequestDate, setNextOTPRequestDate] = useState('');
  const [countdownSeconds, setCountdownSeconds] = useState(0);

  const onSubmit = async (values: IResetPasswordForm) => {
    try {
      const { data } = await dataProvider().custom!({
        url: '',
        method: 'post',
        metaData: {
          operation: 'resetPassword',
          variables: {
            data: {
              value: {
                otpChallengeID: email,
                otpToken: values.otpToken,
                password: values.password,
              },
              type: 'ResetPasswordInput',
              required: true,
            },
          },
          fields: ['success'],
        },
      });

      if (data.success) {
        message.success('Reset password success!');
        isSuccessCallback();
      }
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      if (errorMessage === 'OTP_TOKEN_INVALID_ERROR') {
        message.error('OTP token is invalid');
      } else {
        message.error(errorMessage || 'Reset password failed!');
      }
    }
  };

  const onRequestOTP = async () => {
    try {
      const { data } = await dataProvider().custom!({
        url: '',
        method: 'post',
        metaData: {
          operation: 'requestOtp',
          variables: {
            data: {
              value: {
                email,
                purpose: VerificationPurpose.PasswordReset,
                isResend,
              },
              type: 'RequestOtpInput',
              required: true,
            },
          },
          fields: ['success', 'nextRequestOn'],
        },
      });

      if (data.success) {
        setIsResend(true);
        setNextOTPRequestDate(data.nextRequestOn);
        message.success(`Sent OTP to ${email}!`);
      }
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || 'Request OTP failed!');
      return;
    }
  };

  useEffect(() => {
    if (nextOTPRequestDate) {
      const updateTimer = setInterval(() => {
        const nowTs = new Date().getTime();
        const nextRequestTs = new Date(nextOTPRequestDate).getTime();
        const seconds = Math.floor((nextRequestTs - nowTs) / 1000);

        setCountdownSeconds(seconds);

        if (seconds <= 0) {
          setNextOTPRequestDate('');
        }
      }, 1000);

      return () => {
        clearInterval(updateTimer);
      };
    }
  }, [nextOTPRequestDate]);
  return (
    <Form<IResetPasswordForm>
      layout="vertical"
      form={form}
      onFinish={onSubmit}
      requiredMark={false}
      initialValues={{
        otpToken: '',
        password: '',
        confirmPassword: '',
      }}
    >
      <Form.Item
        name="password"
        label={t('pages.login.password', 'Password')}
        rules={[
          { required: true, message: 'Password is required' },
          {
            pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()\-__+.,])(?=.{8,20})/,
            message:
              'Password must contain 8 -20 characters, 1 UPPERCASE letter, 1 lowercase letter, 1 number and 1 special character',
          },
        ]}
        className="mb-3"
        validateTrigger="onBlur"
        validateFirst={true}
      >
        <Input.Password
          placeholder="Enter New Password"
          size="large"
          iconRender={(visible) => (visible ? <EyeTwoTone className="text-gray-500" /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label={t('pages.forgotPassword.confirmPassword', 'Confirm New Password')}
        dependencies={['password']}
        rules={[
          { required: true, message: 'Please confirm your password' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('New password and confirm password must be matched'));
            },
          }),
        ]}
        className="mb-3"
        validateTrigger="onBlur"
        validateFirst={true}
      >
        <Input.Password
          placeholder="Confirm New Password"
          size="large"
          iconRender={(visible) => (visible ? <EyeTwoTone className="text-gray-500" /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>
      <Form.Item label={t('pages.forgotPassword.otp', 'OTP')} name="otpToken">
        <Row gutter={8}>
          <Col span={16}>
            <Form.Item
              noStyle
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
              validateTrigger="onBlur"
            >
              <Input placeholder="OTP" size="large" />{' '}
              <span className="text-gray-500 text-xs">Enter the 6 digits OTP we just sent to your email</span>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button
              className={`w-full text-white rounded-md ${
                nextOTPRequestDate !== '' ? 'opacity-50 cursor-not-allowed p-0' : ''
              }`}
              size="large"
              type="primary"
              onClick={onRequestOTP}
              disabled={nextOTPRequestDate !== ''}
            >
              Request{countdownSeconds > 0 && `(${countdownSeconds}s)`}
            </Button>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item shouldUpdate>
        {({ isFieldsTouched, getFieldsError }) => (
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="mt-8"
            block
            disabled={
              !isFieldsTouched(['password', 'confirmPassword', 'otpToken'], true) ||
              getFieldsError().filter(({ errors }) => errors.length).length > 0
            }
          >
            {t('pages.forgotPassword.proceed', 'Proceed')}
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};
