import React, { useEffect } from 'react';
import {
  Button,
  DateField,
  Icons,
  ImageField,
  List,
  Popconfirm,
  Space,
  Switch,
  Table,
  useModalForm,
  useTable,
} from '@pankod/refine-antd';
import {
  CrudFilters,
  CrudSort,
  HttpError,
  IResourceComponentsProps,
  useCustom,
  useTranslate,
} from '@pankod/refine-core';
import { VirtualGiftCreateForm } from './components/create';
import { VirtualGiftEditForm } from './components/edit';
import { useSoftDeleteChannelGift } from './hooks/use-soft-delete-channel-gift';
import { useToggleChannelGift } from './hooks/use-toggle-channel-gift';
import { PAGINATION_FIELDS, RESOURCE_FIELDS, RESOURCE_NAME } from './constant';
import { IUser, IVirtualGift } from 'interfaces';

export const VirtualGiftingList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const initialSorter: CrudSort[] = [{ field: 'id', order: 'asc' }];
  const initialPageSize = 10;
  const initialFilter = [{ field: 'archivedAt', operator: 'null', value: '' }];

  const {
    data: channelData,
    isLoading,
    isSuccess,
    refetch,
  } = useCustom<IUser>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'me',
      fields: [{ channel: ['id', 'isGiftEnabled', 'currencyCode'] }],
    },
  });

  const isCompletedLoad = isSuccess && !isLoading;
  const canShowList = isCompletedLoad && channelData?.data?.channel?.isGiftEnabled;

  const { tableProps, tableQueryResult } = useTable<IVirtualGift, HttpError>({
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'listMyGifts',
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter,
    initialPageSize,
    initialFilter: initialFilter as CrudFilters,
  });

  const { toggle, isSuccess: isUpdated } = useToggleChannelGift({
    channelId: channelData?.data.channel.id,
  });
  useEffect(() => {
    if (isUpdated) {
      refetch();
    }
  }, [refetch, isUpdated]);

  const { show: showCreate, ...createFromProps } = useModalForm({
    action: 'create',
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'createMyGift',
      operationType: 'CreateMyGiftInput',
    },
    successNotification: {
      type: 'success',
      message: t('notifications.createSuccess', { resource: t('virtualGifting.virtualGifting') }),
    },
    autoSubmitClose: true,
    redirect: false,
  });

  const { show: showEdit, ...editFromProps } = useModalForm({
    action: 'edit',
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
    successNotification: {
      type: 'success',
      message: t('notifications.editSuccess', { resource: t('virtualGifting.virtualGifting') }),
    },
    autoSubmitClose: true,
    redirect: false,
  });

  const { softDelete } = useSoftDeleteChannelGift({
    onSuccess: () => {
      tableQueryResult.refetch();
    },
  });

  return (
    <>
      <h1>{t('virtualGifting.virtualGifting')}</h1>

      <VirtualGiftCreateForm
        currencyCode={channelData?.data?.channel?.currencyCode}
        modalProps={createFromProps.modalProps}
        formProps={createFromProps.formProps}
      />

      <VirtualGiftEditForm
        values={editFromProps.queryResult?.data}
        modalProps={editFromProps.modalProps}
        formProps={editFromProps.formProps}
        currencyCode={channelData?.data?.channel?.currencyCode}
      />

      <Space direction="horizontal" size="large">
        <p>{t('virtualGifting.header')}:</p>
        {isCompletedLoad && (
          <Switch
            defaultChecked={channelData?.data?.channel?.isGiftEnabled}
            onChange={toggle}
            style={{ marginBottom: '16px' }}
          />
        )}
      </Space>

      {canShowList && (
        <List
          title=""
          pageHeaderProps={{
            breadcrumb: <Space></Space>,
          }}
          createButtonProps={{
            onClick: () => showCreate(),
          }}
        >
          <Table {...tableProps} rowKey="id">
            <Table.Column<IVirtualGift>
              dataIndex="thumbnailUrl"
              title={t('virtualGifting.fields.icon')}
              render={(_, record) => (
                <ImageField
                  value={record.thumbnailUrl}
                  title={record.thumbnailUrl}
                  width={100}
                  height={100}
                  style={{ objectFit: 'contain' }}
                />
              )}
            />
            <Table.Column dataIndex="name" title={t('virtualGifting.fields.name')} />
            <Table.Column
              dataIndex="displayPrice"
              title={t('virtualGifting.fields.displayPrice', {
                currencyCode: channelData?.data?.channel?.currencyCode,
              })}
            />
            <Table.Column<IVirtualGift>
              dataIndex="updatedAt"
              title={t('virtualGifting.fields.updatedAt')}
              render={(record) => <DateField format="LLL" value={record} />}
            />
            <Table.Column<IVirtualGift>
              width="5%"
              key="actions"
              title={t('tables.actions')}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <Button size="small" icon={<Icons.EditFilled />} onClick={() => showEdit(record.id)} />
                  {/* <DeleteButton resourceName={RESOURCE_NAME} hideText size="small" recordItemId={record.id} /> */}
                  <Popconfirm
                    title={t('virtualGifting.delete')}
                    onConfirm={() => {
                      softDelete(record.id);
                    }}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger={true} size="small" icon={<Icons.DeleteOutlined />} />
                  </Popconfirm>
                </Space>
              )}
            />
          </Table>
        </List>
      )}
    </>
  );
};
