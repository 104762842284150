import { useState } from 'react';
import { CrudFilters, useDataProvider, useNotification } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { RESOURCE_FIELDS } from '../constant';
import { IFanSubscriptionSummary } from 'interfaces';
import { formatMoney } from 'libs/number';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';

export const useExportMyFanSubscriptionSummaryReport = (params: { filters: CrudFilters; headers: string[] }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const { getList } = dataProvider();
  const { open } = useNotification();
  const pageSize = 1000;

  const filename = `fan-report-${new Date().toLocaleString()}`;

  const request = async () => {
    let rawData: IFanSubscriptionSummary[] = [];
    let current = 1;
    let preparingData = true;

    while (preparingData) {
      try {
        const { data, total } = await getList({
          resource: '',
          pagination: {
            current,
            pageSize,
          },
          metaData: {
            variablesOverrides: {
              where: {
                type: 'FanSubscriptionSummaryWhereInput',
              },
            },
            operation: 'listMyFanSubscriptionSummary',
            items: [...RESOURCE_FIELDS],
            metadata: [...PAGINATION_FIELDS],
          },
          filters: [...params.filters],
        });

        current++;
        rawData.push(...(data as IFanSubscriptionSummary[]));
        if (total === rawData.length) {
          preparingData = false;
        }
      } catch (error) {
        preparingData = false;
        return;
      }
    }
    return rawData;
  };

  const triggerExport = async () => {
    setIsLoading(true);

    const fanSubscriptions = (await request()) || [];

    if (fanSubscriptions.length === 0) {
      setIsLoading(false);

      open({
        key: `error` + Date.now(),
        type: 'error',
        description: `Error on exporting!`,
        message: 'No record available to export',
      });
      return;
    }

    const workbook = utils.book_new();

    const rowData = fanSubscriptions.map((item) => {
      let values = {
        'Fans Username': item.username,
        'Fans Email': item.email,
        'Joined Channel Date': item.joinedDate ? moment(item.joinedDate).format('DD-MMM-YYYY') : '',
        'Latest Payment Date': item.latestTransactionDate
          ? moment(item.latestTransactionDate).format('DD-MMM-YYYY')
          : '',
        'Total Spent': formatMoney(item?.totalAmount / 100 || 0, { currency: 'MYR' }),
        'Subscribed Plan': item.planStatus,
      };

      Object.keys(values).forEach((key) => {
        values[key] = values[key] || 'N/A';
      });

      return values;
    });

    const worksheet = utils.json_to_sheet([]);

    let rowNumber = 1;
    params.headers.map((header) => {
      utils.sheet_add_aoa(worksheet, [[header]], { origin: `A${rowNumber++}` });
    });
    utils.sheet_add_aoa(worksheet, [[]], { origin: `A${rowNumber++}` });
    utils.sheet_add_json(worksheet, rowData, { skipHeader: false, origin: `A${rowNumber++}` });
    utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

    writeFile(workbook, `${filename}.xlsx`, { compression: true });

    setIsLoading(false);
  };

  return {
    isLoading,
    triggerExport,
  };
};
