import { CSSProperties } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill-editor.css';

interface QuillEditorProps {
  value?: string;
  onChange?: (value: string) => void;
  style?: CSSProperties;
}

export const QuillEditor = (props?: QuillEditorProps) => {
  return <ReactQuill theme="snow" value={props?.value} onChange={props?.onChange} style={props?.style} />;
};
