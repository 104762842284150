import React from 'react';
import { usePrompt } from './use-prompt';

export const useLeaveFormConfirmation = () => {
  const [isFormDirty, setIsFormDirty] = React.useState(false);

  React.useEffect(() => {
    if (isFormDirty) {
      window.onbeforeunload = function () {
        return true;
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [isFormDirty]);

  const onFieldsChange = () => {
    setIsFormDirty(true);
  };

  const onMutateSuccess = () => {
    setIsFormDirty(false);
  };

  // on navigate away
  usePrompt('Are you sure you want to leave this page?', isFormDirty);

  return {
    onFieldsChange,
    onMutateSuccess,
    isFormDirty,
  };
};
