import React from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  DateField,
  EditButton,
  ExportButton,
  Icons,
  ImageField,
  List,
  Row,
  Space,
  Table,
  Tag,
} from '@pankod/refine-antd';
import { IResourceComponentsProps, LogicalFilter, useTranslate } from '@pankod/refine-core';
import { VideoDetailDialog } from 'components/video';
import { VideoFilter } from 'components/video/video-filter';
import { useExportVideoContentReport } from './hooks/use-export-video-content-report';
import { useListVideos, useSoftDeleteVideo } from './hooks';
import { Popconfirm, Radio } from 'antd';
import { IVideo } from 'interfaces';
import moment from 'moment';

export const VideoManagementList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const [viewRecord, setViewRecord] = React.useState<IVideo | null>(null);
  const showRecordModal = (record: IVideo) => setViewRecord(record);
  const closeRecordModal = () => setViewRecord(null);

  const { tableProps, searchFormProps, tableQueryResult, filters, setFilters } = useListVideos({});

  const title = searchFormProps.form.getFieldValue('title');
  const genre = searchFormProps.form.getFieldValue('genre');
  const status = searchFormProps.form.getFieldValue('status');
  const createdDates = searchFormProps.form.getFieldValue('createdDates') || [];

  const { triggerExport, isLoading } = useExportVideoContentReport({
    filters,
    headers: [
      'Video Content Report',
      `Export Date: ${moment().format('DD-MMM-YYYY')}`,
      `Selected Period: ${createdDates?.map((date) => date.format('DD-MMM-YYYY')).join(' - ') || 'ALL'}`,
      `Genre: ${genre || 'ALL'}`,
      `Search Video Title: ${title || 'ALL'}`,
      `Status: ${status || 'ALL'}`,
    ],
  });

  const { softDelete, restoreDelete } = useSoftDeleteVideo({
    onSuccess: () => {
      tableQueryResult.refetch();
    },
  });

  const defaultIsArchivedAt = (filters as LogicalFilter[]).find((filter) => filter.field === 'archivedAt')?.operator;

  const Actions: React.FC = () => (
    <Space>
      <Radio.Group
        value={defaultIsArchivedAt}
        onChange={(e) => {
          setFilters(
            [
              {
                field: 'archivedAt',
                operator: e.target.value,
                value: '',
              },
            ],
            'replace',
          );
        }}
      >
        <Radio.Button value="null">{t('videos.active')}</Radio.Button>
        <Radio.Button value="nnull">{t('videos.archived')}</Radio.Button>
      </Radio.Group>
      <ExportButton
        loading={isLoading}
        onClick={() => {
          triggerExport();
        }}
      >
        {t('buttons.export')}
      </ExportButton>
    </Space>
  );

  return (
    <Row gutter={[16, 16]}>
      {viewRecord && <VideoDetailDialog values={viewRecord} close={closeRecordModal} />}

      <Col xl={24} lg={24} xs={24}>
        <VideoFilter formProps={searchFormProps} />
      </Col>

      <Col xl={24} xs={24}>
        <List
          pageHeaderProps={{
            title: t('videos.contentLibrary'),
            breadcrumb: <Breadcrumb hideIcons />,
            extra: <Actions />,
          }}
        >
          <Table {...tableProps} rowKey="id">
            <Table.Column<IVideo>
              dataIndex="thumbnailUrl"
              title={t('videos.fields.thumbnail')}
              render={(_, record) => (
                <ImageField
                  value={record?.thumbnailUrl ?? record?.coverThumbnailUrl}
                  title={record.thumbnailUrl ?? record?.coverThumbnailUrl}
                  width={120}
                  height={68}
                  style={{ objectFit: 'contain' }}
                />
              )}
            />
            <Table.Column dataIndex="title" title={t('videos.fields.title')} />
            <Table.Column<IVideo>
              dataIndex="status"
              title={t('videos.fields.status')}
              render={(_, record) => <Tag>{record.status}</Tag>}
            />
            <Table.Column<IVideo>
              title={t('videos.fields.uploadedBy')}
              key="uploadedBy"
              render={(_, record) => {
                return record?.channel?.name ?? '';
              }}
            />
            <Table.Column<IVideo>
              title={t('videos.fields.uploadedAt')}
              dataIndex="uploadedAt"
              key="uploadedAt"
              render={(_, record) =>
                record?.uploadedAt ? <DateField value={record?.uploadedAt} format="LLL" /> : 'N/A'
              }
            />
            <Table.Column<IVideo>
              width="5%"
              key="actions"
              title={t('tables.actions')}
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <Button
                    size="small"
                    icon={<Icons.EyeOutlined />}
                    onClick={() => {
                      showRecordModal(record);
                    }}
                  />
                  {defaultIsArchivedAt === 'null' && (
                    <Popconfirm
                      title="Archive video?"
                      onConfirm={() => {
                        softDelete(record.id);
                      }}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger={true} size="small" icon={<Icons.DeleteOutlined />} />
                    </Popconfirm>
                  )}

                  {defaultIsArchivedAt === 'nnull' && (
                    <Popconfirm
                      title="Restore video?"
                      onConfirm={() => {
                        restoreDelete(record.id);
                      }}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger={true} size="small" icon={<Icons.RollbackOutlined />} />
                    </Popconfirm>
                  )}
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
