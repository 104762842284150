const RESOURCE_NAME = 'watchHistorySummary';

const RESOURCE_FIELDS = [
  'frequency',
  'totalViewCount',
  'totalUniqueViewCount',
  'totalUniqueVideoCount',
  'date',
] as const;

export { RESOURCE_NAME, RESOURCE_FIELDS };
