import { Trans } from 'react-i18next';
import { AntdLayout, Button, Card, Checkbox, Col, Form, Input, Row, Typography } from '@pankod/refine-antd';
import { useLogin, useNavigation, useTitle, useTranslate } from '@pankod/refine-core';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { ILoginForm } from 'interfaces';

const { Text, Title } = Typography;

export const UserLoginPage: React.FC = () => {
  const t = useTranslate();
  const HeaderTitle = useTitle();
  const [form] = Form.useForm<ILoginForm>();
  const { mutate: login } = useLogin<ILoginForm>();

  const CardTitle = (
    <>
      <p className="text-lg font-bold whitespace-pre-line">
        <Trans i18nKey="pages.login.message" defaults="Welcome to Creator Portal for Colorverse!" />
      </p>
    </>
  );

  const { push } = useNavigation();

  return (
    <AntdLayout
      style={{
        backgroundImage: `url(/images/login-bg.png)`,
        backgroundSize: 'cover',
        height: '100vh',
      }}
    >
      <AntdLayout.Header
        style={{
          backgroundColor: '#FFF',
          borderWidth: '0.1px',
          borderBottom: 'solid',
        }}
      >
        <HeaderTitle collapsed={false} />
      </AntdLayout.Header>

      <AntdLayout.Content>
        <div className="container px-2 md:px-4 md:py-24 mx-auto flex flex-wrap justify-end items-center mt-10">
          <div className="w-full md:w-[450px] max-h-[90vh] h-4/6 shadow-2xl rounded-b-lg">
            <Card
              title={CardTitle}
              // note(victor): temporary hide sign up page
              // https://sansan-tech.atlassian.net/browse/PRES-574
              // className="rounded-b-none"
              headStyle={{ borderBottom: 0 }}
              bodyStyle={{ paddingTop: 0 }}
            >
              <div className="text-xs md:text-sm text-slate-400 mb-4">
                Login to your Colorverse account to start your creator journey in Colorverse!
              </div>
              <Form<ILoginForm>
                className="space-y-2 md:space-y-4 h-full"
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login({
                    ...values,
                    role: 'user',
                  });
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                  username: '',
                  password: '',
                }}
              >
                <Form.Item
                  className="mb-2 block"
                  name="username"
                  label={t('pages.login.email', 'Email Address')}
                  rules={[
                    {
                      required: true,
                      message: 'Email Address is required',
                    },
                    {
                      type: 'email',
                      message: 'Valid Email is required',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Email address" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t('pages.login.password', 'Password')}
                  rules={[
                    { required: true, message: 'Password is required' },
                    { min: 6, message: 'Minimum 6 characters is required' },
                  ]}
                  className="mb-2 block"
                >
                  <Input.Password
                    placeholder="Enter password"
                    size="large"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone className="text-gray-500" /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item className="mb-2 block" shouldUpdate>
                  {({ isFieldsTouched, getFieldsError }) => (
                    <Button
                      type="primary"
                      size="large"
                      htmlType="submit"
                      block
                      disabled={
                        !isFieldsTouched(['username', 'password'], true) ||
                        getFieldsError().filter(({ errors }) => errors.length).length > 0
                      }
                    >
                      {t('pages.login.signin', 'Sign in')}
                    </Button>
                  )}
                </Form.Item>
                <div className="my-1 flex flex-row justify-between items-center">
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{
                        fontSize: '12px',
                      }}
                    >
                      {t('pages.login.remember', 'Remember me')}
                    </Checkbox>
                  </Form.Item>

                  <div>
                    <Button type="link" onClick={() => push('/forgot-password')}>
                      {t('pages.login.forgotPassword', 'Forgot password?')}
                    </Button>
                  </div>
                </div>
              </Form>
            </Card>

            <Row
              className="md:relative inset-x-0 bottom-0 p-4 rounded-b-lg"
              style={{ background: '#F1F1F1' }}
              align="middle"
              justify="space-between"
            >
              <Col>
                <Text className="text-xs uppercase inline-block w-48 sm:w-80">
                  {t('pages.login.noAccount', 'Not a creator yet? Sign up Colorverse account now!')}{' '}
                </Text>
              </Col>

              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    if (String(process.env.NODE_ENV).toLowerCase() === 'production') {
                      window.open(`https://colorverse.social/sign-up`);
                    } else {
                      window.open(`https://staging.colorverse.social/sign-up`);
                    }
                  }}
                >
                  {t('pages.login.signup', 'Sign up')}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </AntdLayout.Content>
    </AntdLayout>
  );
};
