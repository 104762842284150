import { useTranslation } from 'react-i18next';
import { AntdLayout, Button, Dropdown, Icons, Menu, Space } from '@pankod/refine-antd';
import { useGetLocale, useSetLocale } from '@pankod/refine-core';

const { DownOutlined } = Icons;

export const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();

  const currentLocale = locale();

  const languages = {
    en: 'English',
    sc: '简体',
    tc: '繁体',
  };

  const menu = (
    <Menu selectedKeys={[currentLocale]}>
      {[...(i18n.languages || [])].sort().map((lang: string) => (
        <Menu.Item key={lang} onClick={() => changeLanguage(lang)}>
          {languages[lang]}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <AntdLayout.Header
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0px 24px',
        height: '50px',
        backgroundColor: '#FFF',
      }}
    >
      <Dropdown overlay={menu}>
        <Button type="link">
          <Space>
            {languages[currentLocale]}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>

      {/* <Space style={{ marginLeft: "8px" }}>
        {user?.name && (
          <Text ellipsis strong>
            {user.name}
          </Text>
        )}
        {user?.avatar && <Avatar src={user?.avatar} alt={user?.name} />}
      </Space> */}
    </AntdLayout.Header>
  );
};
