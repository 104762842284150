import { Trans, useTranslation } from 'react-i18next';
import { Collapse, Icons, Tooltip, Typography } from '@pankod/refine-antd';
import { IResourceComponentsProps, useTranslate } from '@pankod/refine-core';

const { Panel } = Collapse;

const ExternalLink = ({ children, href }) => {
  return (
    <Typography.Link
      href={href}
      target="_blank"
      rel="noopener  noreferrer" // security measure
      style={{ color: '#fa3532' }}
    >
      {children}
    </Typography.Link>
  );
};

export const HelpCenter: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  return (
    <>
      <h1>{t('helpCenter.helpCenter')}</h1>
      <Collapse className="help-center__panels" defaultActiveKey={['1']}>
        <Panel header={t('helpCenter.creatorGuideBook')} key="1">
          <Trans
            i18nKey="helpCenter.referToGuideBook"
            components={{
              externalLink: <ExternalLink href={'https://rb.gy/1vluis'}>Link</ExternalLink>,
            }}
          />
        </Panel>

        <Panel header={t('helpCenter.tutorial')} key="2">
          {t('helpCenter.comingSoon')}
        </Panel>

        <Panel header={t('helpCenter.faq')} key="3">
          <Trans
            i18nKey="helpCenter.referToFaq"
            components={{
              externalLink: <ExternalLink href={'https://rb.gy/owtjpe'}>Link</ExternalLink>,
            }}
          />
        </Panel>

        <Panel header={t('helpCenter.termsOfUse')} key="4">
          <ul style={{ listStyleType: 'decimal' }}>
            <li style={{ marginBottom: '8px' }}>
              <ExternalLink href={`${process.env.REACT_APP_H5_API_BASE_URL || ''}/terms-of-use/user-terms`}>
                {t('helpCenter.termsForAllUsers')}
              </ExternalLink>
            </li>
            <li style={{ marginBottom: '8px' }}>
              <ExternalLink href={`${process.env.REACT_APP_H5_API_BASE_URL || ''}/terms-of-use/creator-terms`}>
                {t('helpCenter.termsForCreators')}
              </ExternalLink>
            </li>
            <li style={{ marginBottom: '8px' }}>
              <ExternalLink href={`${process.env.REACT_APP_H5_API_BASE_URL || ''}/terms-of-use/acceptable-policy`}>
                {t('helpCenter.acceptableUsePolicy')}
              </ExternalLink>
            </li>
            <li style={{ marginBottom: '8px' }}>
              <ExternalLink href={`${process.env.REACT_APP_H5_API_BASE_URL || ''}/terms-of-use/standard-contract`}>
                {t('helpCenter.standardContract')}
              </ExternalLink>
            </li>
            <li style={{ marginBottom: '8px' }}>
              <ExternalLink href={`${process.env.REACT_APP_H5_API_BASE_URL || ''}/privacy-policy`}>
                {t('helpCenter.privacyPolicy')}
              </ExternalLink>
            </li>
          </ul>
        </Panel>
      </Collapse>
    </>
  );
};
