const RESOURCE_NAME = 'channels';

const RESOURCE_FIELDS = [
  'id',
  'name',
  'aboutMe',
  'aboutLinks',
  'appLogo',
  'rollLogo',
  'splashScreenLogo',
  'loginBanner',
  'promotionalBanners',
  'themeColor',
  'primaryHex',
  'secondaryHex',
  'isGiftEnabled',
  'isSubscriptionEnabled',
  'currencyCode',
  'userId',
  {
    user: [
      'id',
      'fullName',
      'username',
      'role',
      'mobileCountryCode',
      'mobileNumber',
      'email',
      'status',
      'companyName',
      'companyAddressLine1',
      'companyAddressLine2',
      'companyAddressPostcode',
      'companyAddressCity',
      'companyAddressState',
      'companyAddressCountry',
      'bankName',
      'bankSwiftCode',
      'bankBranchCode',
      'bankAccountHolderName',
      'bankAccountNumber',
      'contactPersonName',
      'contactPersonMobileNumber',
      'contactPersonEmail',
      'countryCode',
      'subscribeInfoAgreed',
      'tosAgreed',
      'selfRegistered',
      'isEmailVerified',
      'preferredLanguage',
      'currencyCode',
    ],
  },
  'totalReleasedVideoCount',
  'totalFollowerCount',
  'genres',
  'status',
  'lastVideoUploadedAt',
  'setupCompletedAt',
  'approvedAt',
  'createdAt',
  'updatedAt',
] as const;

export { RESOURCE_NAME, RESOURCE_FIELDS };
