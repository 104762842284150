import React from 'react';
import { Breadcrumb, Col, DateField, ExportButton, List, Row, Space, Table } from '@pankod/refine-antd';
import { IResourceComponentsProps, useTranslate } from '@pankod/refine-core';
import { TransactionFilter } from './components/transaction-filter';
import { TransactionSummary } from './components/transaction-summary';
import { useExportChannelTransactionSummary, useExportTransactions } from './hooks';
import { useListTransactions } from 'hooks';
import { ITransaction } from 'interfaces';
import { formatMoney } from 'libs/number';
import moment from 'moment';

export const TransactionList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { tableProps, searchFormProps, tableQueryResult, filters } = useListTransactions();
  const channel = searchFormProps.form.getFieldValue('channel');
  const transactionDates = searchFormProps.form.getFieldValue('transactionDates') || [];

  const exportTransactions = useExportTransactions({
    filters,
    headers: [
      'Transaction Report',
      `Export Date: ${moment().format('DD-MMM-YYYY')}`,
      `Channel Name: ${channel?.name || 'ALL'}`,
      `Selected Period: ${transactionDates?.map((date) => date.format('DD-MMM-YYYY')).join(' - ') || 'ALL'}`,
    ],
  });

  const exportCreatorTransactions = useExportChannelTransactionSummary({
    filters,
    headers: [
      `Channel Name: ${channel?.name || 'ALL'}`,
      `Selected Period: ${transactionDates?.map((date) => date.format('DD-MMM-YYYY')).join(' - ') || 'ALL'}`,
    ],
  });

  const Actions: React.FC = () => (
    <Space>
      <ExportButton
        loading={exportTransactions.isLoading}
        onClick={() => {
          exportTransactions.triggerExport();
        }}
        disabled={tableQueryResult?.data?.total < 1}
      >
        {t('transactions.exportSettlementReport')}
      </ExportButton>

      <ExportButton
        loading={exportCreatorTransactions.isLoading}
        onClick={() => {
          exportCreatorTransactions.triggerExport();
        }}
        disabled={tableQueryResult?.data?.total < 1}
      >
        {t('transactions.exportSettlementReportSummary')}
      </ExportButton>
    </Space>
  );

  return (
    <>
      <TransactionSummary
        channelId={channel?.id || null}
        dateFrom={transactionDates[0] || null}
        dateTo={transactionDates[1] || null}
      />

      <br />

      <Row gutter={[16, 16]}>
        <Col xl={24} lg={24} xs={24}>
          <TransactionFilter formProps={searchFormProps} tableQueryResult={tableQueryResult} filters={filters || []} />
        </Col>

        <Col xl={24} xs={24}>
          <List
            pageHeaderProps={{
              title: t('transactions.transactionsReportResult', { total: tableQueryResult?.data?.total }),
              breadcrumb: <Breadcrumb hideIcons />,
              extra: <Actions />,
            }}
          >
            <Table {...tableProps} scroll={{ x: 1800 }} rowKey="id">
              <Table.Column<ITransaction>
                dataIndex="createdAt"
                title={t('transactions.fields.createdAt')}
                render={(_, record) => <DateField value={record.createdAt} format="lll" />}
                fixed="left"
                width="2200"
              />
              <Table.Column dataIndex="id" title={t('transactions.fields.id')} />
              <Table.Column<ITransaction>
                title={t('transactions.fields.paymentRefNumber')}
                render={(_, record) => record?.paymentRefNumber || '-'}
              />
              <Table.Column<ITransaction>
                title={t('transactions.fields.channelName')}
                render={(_, record) => {
                  return <>{record?.paymentTo?.name}</>;
                }}
              />
              <Table.Column<ITransaction>
                title={t('transactions.fields.description')}
                render={(_, record) => {
                  return <>{record?.description}</>;
                }}
              />
              <Table.Column<ITransaction>
                dataIndex="amount"
                title={t('transactions.fields.totalTransactions', { currencyCode: 'MYR' })}
                render={(_, record) => formatMoney(record?.amount / 100 || 0)}
              />
              <Table.Column<ITransaction>
                dataIndex="creatorRevenue"
                title={t('transactions.fields.creatorRevenue', { currencyCode: 'MYR' })}
                render={(_, record) => formatMoney((record?.amount * 0.8) / 100 || 0)}
              />
              <Table.Column<ITransaction>
                title={t('transactions.fields.audienceId')}
                render={(_, record) => record?.paymentBy?.id || '-'}
              />
              <Table.Column<ITransaction>
                title={t('transactions.fields.audiencePhoneNumber')}
                render={(_, record) => record?.paymentBy?.mobileNumber || '-'}
              />
              <Table.Column<ITransaction>
                title={t('transactions.fields.audienceEmail')}
                render={(_, record) => record?.paymentBy?.email || '-'}
              />
            </Table>
          </List>
        </Col>
      </Row>
    </>
  );
};
