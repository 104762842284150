import { useTranslation } from 'react-i18next';
import { ErrorComponent, notificationProvider } from '@pankod/refine-antd';
import { Refine } from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';
import { DashboardPage } from 'pages/dashboard';
import { ForgotPasswordPage } from 'pages/forgot-password';
import { AdminLoginPage } from 'pages/login/admin-login';
import { UserLoginPage } from 'pages/login/user-login';
import { Footer, Header, Layout, OffLayoutArea, Sider, Title } from 'components/layout';
import { gqlClient } from 'gql-client';
import { gqlDataProvider, jwtAuthProvider } from 'providers';
import { AdminRoutes } from 'routes/admin';
import { UserRoutes } from 'routes/user';
//
//  Styles
import 'styles/antd.less';
import 'styles/custom.less';
import 'styles/globals.css';

export const dataProvider = gqlDataProvider(gqlClient);
export const authProvider = jwtAuthProvider(gqlClient);

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <Refine
      catchAll={<ErrorComponent />}
      dataProvider={gqlDataProvider(gqlClient)}
      authProvider={authProvider}
      notificationProvider={notificationProvider}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            element: <AdminLoginPage />,
            path: '/admin',
          },
          {
            element: <ForgotPasswordPage />,
            path: '/forgot-password',
          },
        ],
      }}
      reactQueryClientConfig={{
        defaultOptions: {
          mutations: {
            retry: 0,
          },
          queries: {
            retry: 0,
          },
        },
      }}
      DashboardPage={DashboardPage}
      LoginPage={UserLoginPage}
      resources={[...AdminRoutes, ...UserRoutes]}
      Title={Title}
      Header={Header}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
      i18nProvider={i18nProvider}
    />
  );
}

export default App;
