import React from 'react';
import {
  Breadcrumb,
  Col,
  Create,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tabs,
  useForm,
} from '@pankod/refine-antd';
import {
  HttpError,
  IResourceComponentsProps,
  useRedirectionAfterSubmission,
  useResourceWithRoute,
  useTranslate,
} from '@pankod/refine-core';
import { MultiLanguageForm } from './components/multi-language-form';
import { AliyunOSSUpload } from 'components/upload';
import { RESOURCE_FIELDS, RESOURCE_NAME } from './constant';
import { CurrencyCode, ThirdWebPrebuiltContractVariant, Web3Network } from 'api';
import { INftCollectionForm } from 'interfaces';
import { startCase } from 'lodash';

export const NftCollectionCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const resourceWithRoute = useResourceWithRoute();
  const resource = resourceWithRoute(RESOURCE_NAME);
  const handleSubmitWithRedirect = useRedirectionAfterSubmission();

  const { formProps, saveButtonProps, mutationResult } = useForm<INftCollectionForm, HttpError, INftCollectionForm>({
    action: 'create',
    resource: RESOURCE_NAME,
    metaData: {
      fields: [...RESOURCE_FIELDS],
    },
  });

  const freeMintStartDate = Form.useWatch('freeMintStartDate', formProps.form);
  const preSalesStartDate = Form.useWatch('preSalesStartDate', formProps.form);
  const publicSalesStartDate = Form.useWatch('publicSalesStartDate', formProps.form);
  const fiatCheckoutEnabled = Form.useWatch('fiatCheckoutEnabled', formProps.form);

  React.useEffect(() => {
    if (mutationResult.isSuccess) {
      handleSubmitWithRedirect({
        redirect: 'list',
        resource: resource,
      });
    }
  }, [handleSubmitWithRedirect, mutationResult, resource]);

  React.useEffect(() => {
    return () => {
      formProps.form.resetFields();
    };
  }, [formProps.form]);

  return (
    <Create
      isLoading={mutationResult?.isLoading}
      saveButtonProps={{
        ...saveButtonProps,
      }}
      pageHeaderProps={{
        title: t('nftCollections.createNFTCollection'),
        breadcrumb: <Breadcrumb hideIcons />,
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
        }}
        onFinish={({
          roadmap,
          roadmapCn,
          roadmapZh,
          faqs,
          faqsCn,
          faqsZh,
          holderPerks,
          holderPerksCn,
          holderPerksZh,
          contractAbi,
          ...values
        }) => {
          const payload = {
            ...values,
            contractAbi: contractAbi || [],
            roadmap: roadmap.map(({ header, description }) => ({
              header,
              description,
            })),
            roadmapCn: roadmapCn.map(({ header, description }) => ({
              header,
              description,
            })),
            roadmapZh: roadmapZh.map(({ header, description }) => ({
              header,
              description,
            })),
            faqs: faqs.map(({ answer, questions }) => ({
              answer,
              questions,
            })),
            faqsCn: faqsCn.map(({ answer, questions }) => ({
              answer,
              questions,
            })),
            faqsZh: faqsZh.map(({ answer, questions }) => ({
              answer,
              questions,
            })),
            holderPerks: holderPerks.map(({ value }) => ({ value })),
            holderPerksCn: holderPerksCn.map(({ value }) => ({ value })),
            holderPerksZh: holderPerksZh.map(({ value }) => ({ value })),
          };
          formProps.onFinish(payload);
        }}
      >
        <Tabs defaultActiveKey="en">
          <Tabs.TabPane tab="English" key="en">
            <MultiLanguageForm postfix={'en'} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Chinese (简体)" key="cn">
            <MultiLanguageForm postfix={'cn'} />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Chinese (繁体)" key="zh">
            <MultiLanguageForm postfix={'zh'} />
          </Tabs.TabPane>
        </Tabs>

        <Form.Item label={t('nftCollections.fields.banner')} name="banner" rules={[{ required: true }]}>
          <AliyunOSSUpload
            purpose="Banner"
            accept="image/*"
            description={
              t('upload.extension', {
                extension: '.jpg/.jpeg/.png',
              }) +
              '<br/>' +
              t('upload.recommendedDimension', { dimension: '1280 x 720 pixels' })
            }
            onChange={(response) => {
              formProps.form?.setFieldsValue({
                banner: response.url,
              });
            }}
          />
        </Form.Item>
        <Form.Item label={t('nftCollections.fields.image')} name="image" rules={[{ required: true }]}>
          <AliyunOSSUpload
            purpose="NFTImage"
            accept="image/*"
            description={
              t('upload.extension', {
                extension: '.jpg/.jpeg/.png',
              }) +
              '<br/>' +
              t('upload.recommendedDimension', { dimension: '1280 x 720 pixels' })
            }
            onChange={(response) => {
              formProps.form?.setFieldsValue({
                image: response.url,
              });
            }}
          />
        </Form.Item>

        <Form.Item
          label={t('nftCollections.fields.creatorWalletAddress')}
          name="contractAddress"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('nftCollections.fields.contractNetwork')}
          name="contractNetwork"
          rules={[{ required: true }]}
        >
          <Select style={{ width: '100%' }}>
            {Object.values(Web3Network).map((option, index) => (
              <Select.Option key={`options${index}`} value={option}>
                {startCase(option)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('nftCollections.fields.contractAbi')} name="contractAbi" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={t('nftCollections.fields.creatorWalletAddress')}
          name="creatorWalletAddress"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.freeMintStartDate')} name="freeMintStartDate">
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.freeMintEndDate')} name="freeMintEndDate">
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={(current) => {
                  return current && current < freeMintStartDate.subtract(1, 'day').endOf('day');
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('nftCollections.fields.freeMintPriceCurrencyCode')}
              name="freeMintPriceCurrencyCode"
              rules={[{ required: true }]}
            >
              <Select>
                {Object.values(CurrencyCode).map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('nftCollections.fields.freeMintPrice')}
              name="freeMintPrice"
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.preSalesStartDate')} name="preSalesStartDate">
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.preSalesEndDate')} name="preSalesEndDate">
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={(current) => {
                  return current && current < preSalesStartDate.subtract(1, 'day').endOf('day');
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('nftCollections.fields.preSalesPriceCurrencyCode')}
              name="preSalesPriceCurrencyCode"
              rules={[{ required: true }]}
            >
              <Select>
                {Object.values(CurrencyCode).map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('nftCollections.fields.preSalesPrice')}
              name="preSalesPrice"
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.publicSalesStartDate')} name="publicSalesStartDate">
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('nftCollections.fields.publicSalesEndDate')} name="publicSalesEndDate">
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={(current) => {
                  return current && current < publicSalesStartDate.subtract(1, 'day').endOf('day');
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('nftCollections.fields.publicSalesPriceCurrencyCode')}
              name="publicSalesPriceCurrencyCode"
              rules={[{ required: true }]}
            >
              <Select>
                {Object.values(CurrencyCode).map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('nftCollections.fields.publicSalesPrice')}
              name="publicSalesPrice"
              rules={[{ required: true }]}
            >
              <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label={t('nftCollections.fields.fiatCheckout')} name="fiatCheckoutEnabled" valuePropName="checked">
          <Switch />
        </Form.Item>
        {fiatCheckoutEnabled && (
          <Form.Item
            label={t('nftCollections.fields.fiatCheckoutUrl')}
            name="fiatCheckoutUrl"
            rules={[{ required: false }, { type: 'url' }]}
          >
            <Input placeholder="https://example.com" />
          </Form.Item>
        )}
        <Form.Item
          label={t('nftCollections.fields.thirdwebPrebuiltContractVariant')}
          name="thirdwebPrebuiltContractVariant"
          rules={[{ required: true }]}
        >
          <Select>
            {Object.values(ThirdWebPrebuiltContractVariant).map((item) => (
              <Select.Option key={item} value={item}>
                {startCase(item)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Create>
  );
};
