import React from 'react';
import { Card, Col, Row, Typography } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { useGetMyTransactionSummary } from '../hooks/use-get-my-transaction-summary';
import { formatMoney } from 'libs/number';

const { Text, Title } = Typography;

export const RevenueSummary = ({ dateFrom, dateTo }) => {
  const t = useTranslate();

  const summaryQueryResult = useGetMyTransactionSummary({
    dateFrom: dateFrom || null,
    dateTo: dateTo || null,
  });

  return (
    <Row justify="space-between" align="middle">
      <Col span={4}>
        <Card style={{ height: 150 }}>
          <Title>{t('revenueReport.totalRevenue')}</Title>
          <Text strong>
            {formatMoney((summaryQueryResult?.amount * 0.8 || 0) / 100, {
              currency: summaryQueryResult?.currencyCode,
            })}
          </Text>
        </Card>
      </Col>
      <Col span={4}>
        <Card style={{ height: 150 }}>
          <Title>{t('revenueReport.totalVirtualGifting')}</Title>
          <Text>
            {formatMoney(summaryQueryResult?.gift / 100, {
              currency: summaryQueryResult?.currencyCode,
            })}
          </Text>
        </Card>
      </Col>
      <Col span={4}>
        <Card style={{ height: 150 }}>
          <Title>{t('revenueReport.totalSubscription')}</Title>
          <Text>
            {formatMoney(summaryQueryResult?.subscription / 100, {
              currency: summaryQueryResult?.currencyCode,
            })}
          </Text>
        </Card>
      </Col>
      <Col span={4}>
        <Card style={{ height: 150 }}>
          <Title>{t('revenueReport.totalPayPerView')}</Title>
          <Text>
            {formatMoney(summaryQueryResult?.payPerView / 100, {
              currency: summaryQueryResult?.currencyCode,
            })}
          </Text>
        </Card>
      </Col>
      <Col span={4}>
        <Card style={{ height: 150 }}>
          <Title>{t('revenueReport.totalTips')}</Title>
          <Text>
            {formatMoney(summaryQueryResult?.tips / 100, {
              currency: summaryQueryResult?.currencyCode,
            })}
          </Text>
        </Card>
      </Col>
    </Row>
  );
};
