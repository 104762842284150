import React from 'react';
import { Button, Col, Form, FormInstance, Icons, Input, Modal, Row, Select } from '@pankod/refine-antd';
import { useCreate, useList, useTranslate, useUpdate } from '@pankod/refine-core';
import { AliyunOSSUpload } from 'components/upload';
import { IPlaylist } from 'interfaces';

export const UpsertPlaylistDialog: React.FC<{
  videoIds: React.Key[];
  onSuccess: () => void;
}> = ({ videoIds, onSuccess }) => {
  const t = useTranslate();

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 20,
  });

  const queryResult = useList<IPlaylist>({
    resource: 'playlists',
    metaData: {
      operation: 'getMyPlaylists',
      items: ['id', 'name'],
      metadata: ['currentPage', 'perPage', 'totalCount', 'totalPages'],
    },
    config: {
      pagination: {
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
    },
  });
  const playlistOptions = (queryResult?.data?.data || []).map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const [isCreate, setIsCreate] = React.useState(false);
  const toCreate = () => setIsCreate(true);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const showModal = () => setIsModalVisible(true);
  const closeModal = () => {
    setIsModalVisible(false);
    setIsCreate(false);
  };

  const [form] = Form.useForm<any>();

  return (
    <>
      <Button type="primary" icon={<Icons.PlusSquareOutlined />} onClick={showModal} disabled={videoIds.length === 0}>
        {t('videos.addToPlaylist')}
      </Button>

      <Modal
        title={t('videos.addToPlaylist')}
        visible={isModalVisible}
        onCancel={closeModal}
        footer={[
          isCreate ? (
            <Button
              key="create"
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              {t('videos.createAndSaveIntoPlaylist')}
            </Button>
          ) : (
            <Button
              key="save"
              type="primary"
              onClick={() => {
                form.submit();
              }}
            >
              {t('videos.saveIntoPlaylist')}
            </Button>
          ),
          <Button
            key="back"
            onClick={() => {
              closeModal();
            }}
          >
            {t('buttons.close')}
          </Button>,
        ]}
      >
        <Row gutter={[10, 10]}>
          {isCreate ? (
            <Col span={24}>
              <NewPlaylistForm
                form={form}
                videoIds={videoIds}
                onSuccess={() => {
                  onSuccess();
                  closeModal();
                }}
              />
            </Col>
          ) : (
            <>
              <Col span={24}>
                <SaveIntoPlaylistForm
                  form={form}
                  videoIds={videoIds}
                  options={playlistOptions}
                  onSuccess={() => {
                    onSuccess();
                    closeModal();
                  }}
                />
              </Col>
              <Col span={24}>
                <Button onClick={toCreate}>{t('playlists.createPlaylist')}</Button>
              </Col>
            </>
          )}
        </Row>
      </Modal>
    </>
  );
};

const NewPlaylistForm: React.FC<{
  form: FormInstance;
  videoIds: React.Key[];
  onSuccess: () => void;
}> = ({ form, videoIds, onSuccess }) => {
  const t = useTranslate();
  const { mutate, isSuccess } = useCreate();

  React.useEffect(() => {
    form.setFieldsValue({});
    return () => {
      form.resetFields();
    };
  }, [form]);

  React.useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => {
        mutate({
          resource: 'playlist',
          metaData: {
            operation: 'createMyPlaylist',
            operationType: 'MyPlaylistCreateInput',
          },
          values: {
            ...values,
            playlistVideos: {
              createMany: {
                data: videoIds.map((videoId) => ({
                  videoId,
                })),
              },
            },
          },
          successNotification: {
            type: 'success',
            message: t('notifications.createSuccess', { resource: t('playlists.playlists') }),
          },
        });
      }}
    >
      <Form.Item
        label={t('playlists.fields.name')}
        name="name"
        rules={[
          {
            required: true,
            message: t('errors.required', {
              field: t('playlists.fields.name'),
            }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={t('playlists.fields.description')} name="description">
        <Input />
      </Form.Item>
      <Form.Item label={t('playlists.fields.thumbnail.label')} name="thumbnailUrl" rules={[{ required: false }]}>
        <AliyunOSSUpload
          purpose="PlaylistThumbnail"
          accept="image/*"
          description={
            t('upload.extension', {
              extension: '.jpg/.jpeg/.png',
            }) +
            '<br/>' +
            t('upload.recommendedDimension', { dimension: '1280 x 720 pixels' })
          }
          onChange={(response) => {
            form.setFieldsValue({
              thumbnailUrl: response.url,
            });
          }}
        />
      </Form.Item>
    </Form>
  );
};

const SaveIntoPlaylistForm: React.FC<{
  form: FormInstance;
  videoIds: React.Key[];
  options: { label: string; value: string }[];
  onSuccess: () => void;
}> = ({ form, videoIds, options, onSuccess }) => {
  const t = useTranslate();

  const { mutate, isSuccess } = useUpdate();

  React.useEffect(() => {
    form.setFieldsValue({});
    return () => {
      form.resetFields();
    };
  }, [form]);

  React.useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess, onSuccess]);

  const [value, setValue] = React.useState('');
  const handleChange = (value: string) => setValue(value);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(value) => {
        mutate({
          id: value.playlistId,
          resource: 'playlists',
          values: {
            playlistVideos: {
              connectOrCreate: videoIds.map((videoId) => {
                return {
                  where: {
                    videoId_playlistId: {
                      videoId,
                      playlistId: value.playlistId,
                    },
                  },
                  create: {
                    video: {
                      connect: {
                        id: videoId,
                      },
                    },
                  },
                };
              }),
            },
          },
          successNotification: {
            type: 'success',
            message: t('notifications.editSuccess', {
              resource: t('playlists.playlists'),
            }),
          },
        });
      }}
    >
      <Form.Item
        label={t('videos.playlist')}
        name="playlistId"
        rules={[
          {
            required: true,
            message: t('errors.required', {
              field: t('playlists.fields.name'),
            }),
          },
        ]}
      >
        <Select defaultValue={value} style={{ width: '100%' }} onChange={handleChange}>
          {options.map((option, index) => (
            <Select.Option key={`options${index}`} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};
