export const RESOURCE_FIELDS = [
  'userId',
  'username',
  'email',
  'fullName',
  'totalAmount',
  'latestTransactionDate',
  'planId',
  'planStatus',
  'joinedDate',
];
