import React from 'react';
import { Modal, Progress } from '@pankod/refine-antd';
import { aliUploader } from 'libs/ali-upload';

export const useUploadVideoDialog = ({ onSuccess, onCancel, onError }) => {
  React.useEffect(() => {
    const aliyunSDKPaths = [
      '/aliyun-upload-sdk-1.5.3/aliyun-upload-sdk-1.5.3.min.js',
      '/aliyun-upload-sdk-1.5.3/lib/aliyun-oss-sdk-6.17.1.min.js',
      '/aliyun-upload-sdk-1.5.3/lib/es6-promise.min.js',
    ];
    const appendedScripts = aliyunSDKPaths.map((path) => {
      const script = document.createElement('script');
      script.src = path;
      script.type = 'text/javascript';
      document.body.appendChild(script);
      return script;
    });

    return () => {
      appendedScripts.forEach((script) => document.body.removeChild(script));
    };
  }, []);

  let modal = null;

  const showUploading = () => {
    modal = Modal.info({
      icon: null,
      closable: false,
      keyboard: false,
      okButtonProps: {
        style: { display: 'none' },
      },
      title: 'Uploading your video...',
      content: (
        <div>
          <p>Please don't close this page until the video is uploaded.</p>
        </div>
      ),
    });
  };

  const uploadProgress = (percentage: number) => {
    modal.update({
      content: (
        <div>
          <p>Please don't close this page until the video is uploaded.</p>
          <Progress percent={percentage || 0} />
        </div>
      ),
    });
  };

  const upload = async ({ ossUploadInfo, video }) => {
    console.log({
      ossUploadInfo,
      video,
    });
    const uploader = aliUploader.create(video.file, {
      userId: process.env.REACT_APP_ALI_CLOUD_USER_ID as string,
      region: process.env.REACT_APP_ALI_CLOUD_REGION as string,
      getAuthByuploadInfo: async () => ({
        uploadAuth: ossUploadInfo!.uploadAuth,
        uploadAddress: ossUploadInfo!.uploadAddress,
        videoId: ossUploadInfo!.videoId,
      }),
      getNewTokenWhenExpire: async (uploadInfo) => {
        return '';
      },
      onSuccess: (uploadInfo) => {
        modal.destroy();
        onSuccess();
      },
      onUpload: () => {
        showUploading();
      },
      onProgress: (uploadInfo, totalSize, progress) => {
        console.log(`${progress}%`);
        uploadProgress(progress);
      },
      onCancel: () => {
        modal.destroy();
        onCancel();
      },
      onError: () => {
        modal.destroy();
        onError();
      },
    });

    await uploader.start();
  };

  return upload;
};
