import { Button, Col, Collapse, Form, FormProps, Icons, Input, Row } from '@pankod/refine-antd';
import { CrudFilters, useTranslate } from '@pankod/refine-core';
import { SearchChannelInput } from 'components/search-channel-input';
import { DatePicker } from 'antd';
import { IChannelFilterVariables } from 'interfaces';

export const ChannelFilter: React.FC<{
  formProps?: FormProps<IChannelFilterVariables>;
  filters?: CrudFilters;
  tableQueryResult: any;
}> = ({ formProps }) => {
  const t = useTranslate();

  return (
    <Collapse>
      <Collapse.Panel header={t('filters.filter')} key="1">
        <Form layout="vertical" {...formProps}>
          <Row gutter={[10, 0]} align="bottom">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item label={t('channelsManagement.filters.channelName')} name="channel">
                <SearchChannelInput placeholder={t('channelsManagement.filters.searchChannelName')} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item label={t('channelsManagement.filters.joinedOn')} name="setupCompleteDates">
                <DatePicker.RangePicker
                  style={{ width: '100%' }}
                  allowClear={false}
                  placeholder={[t('channelsManagement.filters.startDate'), t('channelsManagement.filters.endDate')]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[10, 0]} align="bottom">
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button style={{ width: '100%' }} htmlType="submit" type="primary">
                  {t('buttons.submit')}
                </Button>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Form.Item>
                <Button
                  style={{ width: '100%' }}
                  onClick={() => {
                    formProps.form.resetFields();
                    formProps.form.submit();
                  }}
                  type="primary"
                >
                  {t('buttons.reset')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};
