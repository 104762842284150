const RESOURCE_NAME = 'videos';

const RESOURCE_FIELDS = [
  'id',
  'title',
  'description',
  'playbackUrl',
  'duration',
  'language',
  'genre',
  'rating',
  'subtitleLanguage',
  'thumbnailUrl',
  'subtitleUrl',
  'status',
  'scheduleStart',
  'scheduleEnd',
  'isPayPerView',
  'payPerViewPrice',
  'productUrl',
  'isVertical',
  'uploadedAt',
  'updatedAt',
  'archivedAt',
  'coverThumbnailUrl',
  {
    channel: ['id', 'name'],
  },
] as const;

export { RESOURCE_NAME, RESOURCE_FIELDS };
