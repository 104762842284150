import { useTable } from '@pankod/refine-antd';
import { CrudFilters, CrudSort, HttpError, LogicalFilter } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { IMyFanTransactionFilterVariables, ITransaction } from 'interfaces';

const RESOURCE_NAME = 'transactions';

const RESOURCE_FIELDS = [
  'id',
  'amount',
  'creatorRevenue',
  'currencyCode',
  'description',
  'type',
  'createdAt',
  'status',
  'paymentRefNumber',
  'lineItem',
  {
    paymentTo: [
      'id',
      'name',
      {
        user: ['username', 'fullName', 'countryCode'],
      },
    ],
  },
  {
    paymentBy: ['id', 'fullName', 'mobileNumber', 'email', 'username'],
  },
] as const;

export const useListMyFanTransactions = (params?: { permanentFilter?: LogicalFilter[] }) => {
  const initialSorter: CrudSort[] = [{ field: 'createdAt', order: 'desc' }];
  const initialPageSize = 10;
  const permanentFilter = [
    {
      field: 'status',
      operator: 'eq',
      value: 'AUTHORISED',
    },
    ...(params?.permanentFilter || []),
  ];

  return useTable<ITransaction, HttpError, IMyFanTransactionFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'listMyFanTransactions',
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter: permanentFilter.filter(Boolean) as CrudFilters,
    initialSorter,
    initialPageSize,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { title, transactionDates } = params;

      filters.push({
        field: 'lineItem',
        operator: 'eq',
        value: title,
      });

      filters.push({
        field: 'createdAt',
        operator: 'between',
        value:
          transactionDates?.length > 0
            ? [transactionDates[0].startOf('day').toDate(), transactionDates[1].endOf('day').toDate()]
            : [],
      });

      return filters;
    },
  });
};
