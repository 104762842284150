import React from 'react';
import { Card, Col, Row, Typography } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { useGetTransactionSummary } from '../hooks';
import { formatMoney } from 'libs/number';

const { Text, Title } = Typography;

const responsive = {
  xs: 24,
  sm: 24,
  md: 6,
  lg: 6,
  xl: 6,
};

export const TransactionSummary = ({ channelId, dateFrom, dateTo }) => {
  const t = useTranslate();

  const summaryQueryResult = useGetTransactionSummary({
    channelId: channelId || null,
    dateFrom: dateFrom || null,
    dateTo: dateTo || null,
  });

  return (
    <Row gutter={[16, 16]}>
      <Col {...responsive}>
        <Card>
          <Title>{t('transactions.totalTransactions')}</Title>
          <Text>
            {formatMoney(summaryQueryResult?.amount / 100, {
              currency: summaryQueryResult?.currencyCode,
            })}
          </Text>
        </Card>
      </Col>
      <Col {...responsive}>
        <Card>
          <Title>{t('transactions.totalVirtualGifting')}</Title>
          <Text>
            {formatMoney(summaryQueryResult?.gift / 100, {
              currency: summaryQueryResult?.currencyCode,
            })}
          </Text>
        </Card>
      </Col>
      <Col {...responsive}>
        <Card>
          <Title>{t('transactions.totalSubscription')}</Title>
          <Text>
            {formatMoney(summaryQueryResult?.subscription / 100, {
              currency: summaryQueryResult?.currencyCode,
            })}
          </Text>
        </Card>
      </Col>
      <Col {...responsive}>
        <Card>
          <Title>{t('transactions.totalPayPerView')}</Title>
          <Text>
            {formatMoney(summaryQueryResult?.payPerView / 100, {
              currency: summaryQueryResult?.currencyCode,
            })}
          </Text>
        </Card>
      </Col>
    </Row>
  );
};
