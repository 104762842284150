import { useState } from 'react';
import { CrudFilters, useDataProvider, useExport, useNotification } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { RESOURCE_FIELDS, RESOURCE_NAME } from '../constant';
import { IWatchHistorySummary } from 'interfaces';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';

export const useExportViewReport = ({ filters, headers }: { filters: CrudFilters; headers: string[] }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const { getList } = dataProvider();
  const { open } = useNotification();
  const pageSize = 1000;
  const filename = `view-report-${new Date().toLocaleString()}`;

  const request = async () => {
    let rawData: IWatchHistorySummary[] = [];
    let current = 1;
    let preparingData = true;

    while (preparingData) {
      try {
        const { data, total } = await getList({
          resource: RESOURCE_NAME,
          pagination: {
            current,
            pageSize,
          },
          metaData: {
            operation: 'getWatchHistorySummary',
            items: [...RESOURCE_FIELDS],
            metadata: [...PAGINATION_FIELDS],
          },
          filters: [...filters],
        });

        current++;
        rawData.push(...(data as IWatchHistorySummary[]));
        if (total === rawData.length) {
          preparingData = false;
        }
      } catch (error) {
        preparingData = false;
        return;
      }
    }
    return rawData;
  };

  const triggerExport = async () => {
    setIsLoading(true);

    const views = (await request()) || [];

    if (views.length === 0) {
      setIsLoading(false);

      open({
        key: `error` + Date.now(),
        type: 'error',
        description: `Error on exporting!`,
        message: 'No record available to export',
      });
      return;
    }

    const workbook = utils.book_new();

    const rowData = views.map((item) => {
      const values = {
        Date: moment(item.date).format('DD-MMM-YYYY'),
        'Total View Count': item.totalViewCount,
        'Total Audiences': item.totalUniqueViewCount,
        'Total View Video': item.totalUniqueVideoCount,
      };

      Object.keys(values).forEach((key) => {
        values[key] = values[key] || 'N/A';
      });

      return values;
    });

    const worksheet = utils.json_to_sheet([]);

    let rowNumber = 1;
    headers.map((header) => {
      utils.sheet_add_aoa(worksheet, [[header]], { origin: `A${rowNumber++}` });
    });
    utils.sheet_add_aoa(worksheet, [[]], { origin: `A${rowNumber++}` });
    utils.sheet_add_json(worksheet, rowData, { skipHeader: false, origin: `A${rowNumber++}` });
    utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

    writeFile(workbook, `${filename}.xlsx`, { compression: true });

    setIsLoading(false);
  };

  return {
    isLoading,
    triggerExport,
  };
};
