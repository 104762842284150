import React from 'react';
import { Breadcrumb, Button, Col, DateField, ExportButton, Icons, List, Row, Space, Table } from '@pankod/refine-antd';
import { IResourceComponentsProps, useTranslate } from '@pankod/refine-core';
import { FanTransactionDialog } from './components/detail';
import { FansFilter } from './components/fans-filter';
import { useExportMyFanSubscriptionSummaryReport } from './hooks/use-export-my-fan-subscription-summary-report';
import { useExportMyFanTransactionReport } from './hooks/use-export-my-fan-transaction-report';
import { useListMyFanSubscriptionSummary } from './hooks/use-list-my-fan-subscription-summary';
import { IFanSubscriptionSummary } from 'interfaces';
import { formatMoney } from 'libs/number';
import moment from 'moment';

export const MyFansReport: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const [viewRecord, setViewRecord] = React.useState<IFanSubscriptionSummary | null>(null);
  const showRecordModal = (record: IFanSubscriptionSummary) => setViewRecord(record);
  const closeRecordModal = () => setViewRecord(null);

  const { tableProps, searchFormProps, tableQueryResult, filters } = useListMyFanSubscriptionSummary();

  const transactionDates = searchFormProps.form.getFieldValue('transactionDates');

  const exportFanSummaryReport = useExportMyFanSubscriptionSummaryReport({
    filters,
    headers: [
      'Fans Report',
      `Export Date: ${moment().format('DD-MMM-YYYY')}`,
      `Selected Period: ${transactionDates?.map((date) => date.format('DD-MMM-YYYY')).join(' - ') || 'ALL'}`,
    ],
  });

  const exportFanTransactionReport = useExportMyFanTransactionReport({
    filters,
    headers: [
      'Fans Transaction Report',
      `Export Date: ${moment().format('DD-MMM-YYYY')}`,
      `Selected Period: ${transactionDates?.map((date) => date.format('DD-MMM-YYYY')).join(' - ') || 'ALL'}`,
    ],
  });

  const Actions: React.FC = () => (
    <Space>
      <ExportButton
        loading={exportFanSummaryReport.isLoading}
        onClick={() => {
          exportFanSummaryReport.triggerExport();
        }}
        disabled={tableQueryResult?.data?.total < 1}
      >
        {t('fansReport.exportFanSummaryReport')}
      </ExportButton>

      <ExportButton
        loading={exportFanTransactionReport.isLoading}
        onClick={() => {
          exportFanTransactionReport.triggerExport();
        }}
        disabled={tableQueryResult?.data?.total < 1}
      >
        {t('fansReport.exportFanTransactionReport')}
      </ExportButton>
    </Space>
  );

  return (
    <Row gutter={[16, 16]}>
      {viewRecord && <FanTransactionDialog record={viewRecord} close={closeRecordModal} />}

      <Col xl={24} lg={24} xs={24}>
        <FansFilter formProps={searchFormProps} tableQueryResult={tableQueryResult} filters={filters || []} />
      </Col>

      <Col xl={24} xs={24}>
        <List
          pageHeaderProps={{
            title: t('fansReport.fansReportResult', { count: tableQueryResult?.data?.total || 0 }),
            breadcrumb: <Breadcrumb hideIcons />,
            extra: <Actions />,
          }}
        >
          <Table {...tableProps} rowKey={(record) => `${record.username}_${record.planId}`}>
            <Table.Column<IFanSubscriptionSummary> dataIndex="username" title={t('fansReport.fields.username')} />
            <Table.Column<IFanSubscriptionSummary> dataIndex="email" title={t('fansReport.fields.email')} />
            <Table.Column<IFanSubscriptionSummary>
              title={t('fansReport.fields.joinedDate')}
              dataIndex="joinedDate"
              render={(_, record) => <DateField value={record.joinedDate} format="lll" />}
            />
            <Table.Column<IFanSubscriptionSummary>
              dataIndex="latestPaymentDate"
              title={t('fansReport.fields.latestPaymentDate')}
              render={(_, record) => <DateField value={record.latestTransactionDate} format="lll" />}
            />
            <Table.Column<IFanSubscriptionSummary>
              dataIndex="amount"
              title={t('fansReport.fields.amount', { currencyCode: 'MYR' })}
              render={(_, record) => formatMoney(record?.totalAmount / 100 || 0)}
            />
            <Table.Column<IFanSubscriptionSummary> dataIndex="planStatus" title={t('fansReport.fields.planStatus')} />
            <Table.Column<IFanSubscriptionSummary>
              width="5%"
              title={t('tables.actions')}
              render={(_, record) => (
                <Space>
                  <Button
                    size="small"
                    icon={<Icons.EyeOutlined />}
                    onClick={() => {
                      showRecordModal(record);
                    }}
                  />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};
