import { message } from '@pankod/refine-antd';
import { GraphQLClient, gql } from '@pankod/refine-graphql';
import { Auth } from 'api';
import { simplifyErrorMessage } from 'libs';

const TOKEN_KEY = 'color-auth';
const IDENTITY_KEY = 'color-identity';

export interface Identity {
  username: string;
  role: 'user' | 'admin';
}

export const authStorage = {
  getAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    const identity = localStorage.getItem(IDENTITY_KEY);

    return {
      token: token ? JSON.parse(token) : null,
      identity: identity ? JSON.parse(identity) : null,
    };
  },
  setAuth: ({ token, identity }: { token: Auth; identity?: Identity }) => {
    token && localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
    identity && localStorage.setItem(IDENTITY_KEY, JSON.stringify(identity));
  },
  clean: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(IDENTITY_KEY);
  },
};

export const jwtAuthProvider = (gqlClient: GraphQLClient) => {
  return {
    login: async ({ username, password, role }) => {
      try {
        const mutations = {
          user: gql`
            mutation Login($username: String!, $password: String!) {
              login(data: { username: $username, password: $password }) {
                accessToken
                refreshToken
              }
            }
          `,
          admin: gql`
            mutation adminLogin($username: String!, $password: String!) {
              adminLogin(data: { username: $username, password: $password }) {
                accessToken
                refreshToken
              }
            }
          `,
        };

        const { login, adminLogin } = await gqlClient.request(mutations[role], {
          username,
          password,
        });

        const token: Auth = login || adminLogin;

        authStorage.setAuth({
          token,
          identity: { username, role },
        });

        gqlClient.setHeaders({ Authorization: `Bearer ${token?.accessToken}` });

        message.success('Login success!');

        return Promise.resolve();
      } catch (error) {
        return Promise.reject({
          name: simplifyErrorMessage(error),
          message: 'Login Failed!',
        });
      }
    },
    logout: () => {
      const { identity } = authStorage.getAuth();

      authStorage.clean();

      gqlClient.setHeaders({ Authorization: '' });

      if (identity) {
        return Promise.resolve(identity.role === 'admin' ? '/admin' : '/login');
      }

      return Promise.resolve();
    },
    checkError: (error) => {
      const isUnauthorizedError = (error?.response?.errors || []).some((err) => {
        return err?.extensions?.response?.statusCode === 401;
      });

      if (isUnauthorizedError) {
        return Promise.reject();
      }

      return Promise.resolve();
    },
    checkAuth: () => {
      const { token, identity } = authStorage.getAuth();

      if (token && identity) {
        gqlClient.setHeaders({
          Authorization: `Bearer ${token.accessToken}`,
        });

        return Promise.resolve();
      }

      authStorage.clean();

      gqlClient.setHeaders({ Authorization: '' });

      return Promise.reject();
    },
    getPermissions: () => {
      const { identity } = authStorage.getAuth();

      if (identity) {
        return Promise.resolve(identity?.role);
      }

      return Promise.reject();
    },
    getUserIdentity: async () => {
      const { token, identity } = authStorage.getAuth();

      if (!token || !identity) {
        authStorage.clean();

        gqlClient.setHeaders({ Authorization: '' });

        return Promise.reject();
      }

      return Promise.resolve(identity);
    },
  };
};
