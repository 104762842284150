import React from 'react';
import { useTranslate } from '@pankod/refine-core';
import { AliyunOSSUploadCroppable } from 'components/upload';
import { Banner } from './BannerTable';
import { Button, DatePicker, Form, Input, Modal } from 'antd';
import moment from 'moment';

interface BannerFormValues {
  url: string;
  bannersUrl: string;
  schedule: Date;
}

export const BannerForm: React.FC<{
  modalVisible: boolean;
  values?: Banner;
  onClose: () => void;
  onSubmit: (data: Omit<Banner, 'key'>) => void;
}> = ({ values, modalVisible, onClose, onSubmit }) => {
  const t = useTranslate();
  const [key, setKey] = React.useState('');
  const [form] = Form.useForm<BannerFormValues>();
  const isEdit = !!values;

  // reset form on close
  React.useEffect(() => {
    if (modalVisible && isEdit) {
      form.setFieldsValue({
        url: values.url,
        schedule: moment(values.scheduleStart),
        bannersUrl: values.bannersUrl,
      });

      setKey(Date.now().toString());
    }

    return () => {
      form.resetFields();
    };
  }, [form, isEdit, modalVisible, values]);

  return (
    <Modal
      title={isEdit ? t('myChannel.bannerTable.editPromotionBanner') : t('myChannel.bannerTable.addPromotionBanner')}
      visible={modalVisible}
      onCancel={onClose}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          {t('buttons.submit')}
        </Button>,
        <Button key="back" onClick={onClose}>
          {t('buttons.close')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          onSubmit({
            url: values.url,
            scheduleStart: values.schedule,
            scheduleEnd: null,
            bannersUrl: values.bannersUrl,
          });
          onClose();
        }}
      >
        <Form.Item
          label={t('myChannel.bannerTable.link')}
          name="url"
          rules={[
            { required: false },
            {
              type: 'url',
              message: t('errors.url', { field: t('myChannel.bannerTable.link') }),
            },
          ]}
        >
          <Input placeholder="https://example.com" />
        </Form.Item>

        <Form.Item
          label={t('myChannel.bannerTable.publishDate')}
          name="schedule"
          rules={[
            {
              required: true,
              message: t('errors.required', { field: t('myChannel.bannerTable.publishDate') }),
            },
          ]}
        >
          <DatePicker style={{ width: '100%' }} placeholder={t('myChannel.bannerTable.selectDate')} />
        </Form.Item>

        <Form.Item
          name="bannersUrl"
          rules={[
            {
              required: true,
              message: t('errors.required', { field: t('myChannel.bannerTable.banners') }),
            },
          ]}
        >
          <AliyunOSSUploadCroppable
            aspect={1500 / 400}
            key={key}
            purpose="Banner"
            accept="image/*"
            description={
              t('upload.extension', {
                extension: '.jpg/.jpeg/.png',
              }) +
              '<br/>' +
              t('upload.recommendedDimension', { dimension: '1500 x 400 pixels' })
            }
            onChange={(response) => {
              form.setFieldsValue({
                bannersUrl: response.url,
              });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
