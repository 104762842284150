import React from 'react';
import { useTable, useTableReturnType } from '@pankod/refine-antd';
import { CrudFilters, CrudSort, HttpError } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { RESOURCE_FIELDS, RESOURCE_NAME } from '../constant';
import { IChannel, IChannelFilterVariables } from 'interfaces';

export const useListChannels = (): useTableReturnType<IChannel, IChannelFilterVariables> => {
  const initialSorter: CrudSort[] = [{ field: 'id', order: 'asc' }];
  const initialPageSize = 10;

  const result = useTable<IChannel, HttpError, IChannelFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter: [
      {
        field: 'setupCompletedAt',
        operator: 'nnull',
        value: '',
      },
    ],
    initialSorter,
    initialPageSize,
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { channel, setupCompleteDates } = params;

      filters.push({
        field: 'id',
        operator: 'eq',
        value: channel?.id || null,
      });

      filters.push({
        field: 'setupCompletedAt',
        operator: 'between',
        value:
          setupCompleteDates?.length > 0
            ? [setupCompleteDates[0].startOf('day').toDate(), setupCompleteDates[1].endOf('day').toDate()]
            : [],
      });

      return filters;
    },
  });

  return {
    ...result,
  };
};
