import React from 'react';
import {
  Breadcrumb,
  Card,
  Col,
  Create,
  Form,
  Icons,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Upload,
  message,
  useForm,
} from '@pankod/refine-antd';
import {
  HttpError,
  IResourceComponentsProps,
  useRedirectionAfterSubmission,
  useResourceWithRoute,
  useTranslate,
} from '@pankod/refine-core';
import { AliyunOSSUpload } from 'components/upload';
import { useUploadVideoDialog } from 'components/video';
import { useFindMyChannel } from 'hooks/use-find-my-channel';
import { useLeaveFormConfirmation } from 'hooks/use-leave-form-confirmation';
import { RESOURCE_NAME, genreOptions, ratingOptions, voLanguageOptions } from './constant';
import { DatePicker } from 'antd';
import { CurrencyCode } from 'api';
import { IUploadVideoForm, IVideo } from 'interfaces';
import moment from 'moment';

const responsive = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
};

const metaData = {
  operation: 'uploadVideo',
  operationType: 'UploadVideoInput',
  fields: [
    'id',
    'title',
    'description',
    {
      uploadInfo: ['uploadAddress', 'videoId', 'requestId', 'uploadAuth'],
    },
  ],
};

export const VideoCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const resourceWithRoute = useResourceWithRoute();
  const resource = resourceWithRoute(RESOURCE_NAME);
  const handleSubmitWithRedirect = useRedirectionAfterSubmission();

  const { onFieldsChange, onMutateSuccess } = useLeaveFormConfirmation();

  const uploadVideo = useUploadVideoDialog({
    onSuccess: () => {
      handleSubmitWithRedirect({
        redirect: 'list',
        resource: resource,
      });
      message.success(t('videos.notification.uploadSuccess'));
    },
    onCancel: () => {
      message.error(t('videos.notification.uploadCancel'));
    },
    onError: () => {
      message.error(t('videos.notification.uploadError'));
    },
  });

  const queryResult = useFindMyChannel();
  const result = queryResult?.data?.data;

  const { formProps, saveButtonProps, mutationResult } = useForm<IVideo, HttpError, IUploadVideoForm>({
    action: 'create',
    metaData,
    redirect: false,
    successNotification: false,
    onMutationSuccess: (data) => {
      onMutateSuccess();

      uploadVideo({
        ossUploadInfo: data?.data?.uploadInfo,
        video: formProps.form.getFieldValue('video'),
      });
    },
  });
  const isPayPerView = Form.useWatch('isPayPerView', formProps.form);

  return (
    <Create
      isLoading={mutationResult?.isLoading}
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        title: t('videos.createContent'),
        breadcrumb: <Breadcrumb hideIcons />,
      }}
    >
      <Form
        {...formProps}
        initialValues={{
          scheduleStart: moment(),
          payPerViewPrice: 1,
        }}
        layout="vertical"
        onFieldsChange={onFieldsChange}
        onFinish={({ video, ...values }) => {
          formProps.onFinish({
            ...values,
            fileName: video.file.name,
            payPerViewPrice: Math.round(Math.max(values.payPerViewPrice * 100, 0)),
          });
        }}
      >
        <Row gutter={[24, 24]} justify="start" align="top">
          <Col {...responsive}>
            <Card title={t('videos.generalSettings')}>
              <Form.Item
                label={t('videos.fields.title')}
                name="title"
                rules={[
                  {
                    required: true,
                    message: t('errors.required', { field: t('videos.fields.title') }),
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item label={t('videos.fields.description')} name="description" rules={[{ required: false }]}>
                <Input.TextArea />
              </Form.Item>

              <Form.Item
                label={t('videos.fields.language')}
                name="language"
                rules={[
                  {
                    required: true,
                    message: t('errors.required', { field: t('videos.fields.language') }),
                  },
                ]}
              >
                <Select options={voLanguageOptions} />
              </Form.Item>

              <Form.Item
                label={t('videos.fields.genre')}
                name="genre"
                rules={[
                  {
                    required: true,
                    message: t('errors.required', { field: t('videos.fields.genre') }),
                  },
                ]}
              >
                <Select options={genreOptions} />
              </Form.Item>

              <Form.Item
                label={t('videos.fields.rating')}
                name="rating"
                rules={[
                  {
                    required: true,
                    message: t('errors.required', { field: t('videos.fields.rating') }),
                  },
                ]}
              >
                <Select options={ratingOptions} />
              </Form.Item>

              <Form.Item
                label={t('videos.fields.publishDate')}
                name="scheduleStart"
                rules={[
                  {
                    required: true,
                    message: t('errors.required', { field: t('videos.fields.publishDate') }),
                  },
                ]}
              >
                <DatePicker
                  placeholder={t('videos.fields.publishDate')}
                  style={{ width: '100%' }}
                  disabledDate={(current) => {
                    return current && current < moment().subtract(1, 'day').endOf('day');
                  }}
                />
              </Form.Item>
            </Card>
          </Col>

          <Col {...responsive}>
            <Card title={t('videos.monetizeVideo')}>
              <Form.Item
                label={t('videos.fields.isPayPerView')}
                name="isPayPerView"
                valuePropName="checked"
                initialValue={false}
              >
                <Switch />
              </Form.Item>

              {isPayPerView && (
                <Form.Item
                  label={t('videos.fields.pricePayPerView') + ` (${result.currencyCode || CurrencyCode.Myr})`}
                  name="payPerViewPrice"
                  rules={[
                    {
                      required: true,
                      message: t('errors.required', { field: t('videos.fields.pricePayPerView') }),
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} min={1} />
                </Form.Item>
              )}
            </Card>
          </Col>

          <Col {...responsive}>
            <Card>
              <Form.Item
                label={t('videos.fields.videoUpload')}
                name="video"
                rules={[
                  {
                    required: true,
                    message: t('errors.required', { field: t('videos.fields.videoUpload') }),
                  },
                ]}
              >
                <Upload.Dragger
                  accept="video/*"
                  maxCount={1}
                  beforeUpload={async (file: File) => {
                    return false;
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <Icons.InboxOutlined />
                  </p>
                  <p className="ant-upload-text">{t('upload.placeholder')}</p>
                  <p className="ant-upload-hint">
                    {t('upload.extension', {
                      extension: `.mov/.mp4/.mv4/.mpg`,
                      interpolation: { escapeValue: false },
                    })}
                  </p>
                </Upload.Dragger>
              </Form.Item>

              <Form.Item label={t('videos.fields.subtitlesUpload.label')} name="subtitleUrl">
                <AliyunOSSUpload
                  purpose="VideoSubtitle"
                  accept=".vtt,.srt"
                  description={t('upload.extension', { extension: '.srt/.vtt' })}
                  onChange={(response) => {
                    formProps.form?.setFieldsValue({
                      subtitleUrl: response.url,
                    });
                  }}
                />
              </Form.Item>
            </Card>
          </Col>

          <Col {...responsive}>
            <Card>
              <Form.Item
                label={t('videos.fields.thumbnailUpload.label')}
                name="thumbnailUrl"
                rules={[{ required: false }]}
              >
                <AliyunOSSUpload
                  purpose="VideoThumbnail"
                  accept="image/*"
                  description={
                    t('upload.extension', { extension: '.jpg/.jpeg/.png' }) +
                    '<br/>' +
                    t('videos.fields.thumbnailUpload.description')
                  }
                  onChange={(response) => {
                    formProps.form?.setFieldsValue({
                      thumbnailUrl: response.url,
                    });
                  }}
                />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
