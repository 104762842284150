import { useState } from 'react';
import { CrudFilters, LogicalFilter, useDataProvider, useNotification } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { FanSubscriptionSummary } from 'api';
import { ITransaction } from 'interfaces';
import { formatMoney } from 'libs/number';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';

export const useExportMyFanTransactionReport = ({ filters, headers }: { filters: CrudFilters; headers: string[] }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const { getList } = dataProvider();
  const pageSize = 1000;
  const { open } = useNotification();

  const filename = `transactions-${new Date().toLocaleString()}`;

  const requestFanSubscriptions = async () => {
    let rawData: FanSubscriptionSummary[] = [];
    let current = 1;
    let preparingData = true;
    while (preparingData) {
      try {
        const { data, total } = await getList({
          resource: '',
          pagination: {
            current,
            pageSize,
          },
          metaData: {
            variablesOverrides: {
              where: {
                type: 'FanSubscriptionSummaryWhereInput',
              },
            },
            operation: 'listMyFanSubscriptionSummary',
            items: [
              'userId',
              'username',
              'email',
              'fullName',
              'totalAmount',
              'latestTransactionDate',
              'planId',
              'planStatus',
              'joinedDate',
            ],
            metadata: [...PAGINATION_FIELDS],
          },
          filters,
        });

        current++;
        rawData.push(...(data as FanSubscriptionSummary[]));
        if (total === rawData.length) {
          preparingData = false;
        }
      } catch (error) {
        console.log(error);
        preparingData = false;
        return;
      }
    }
    return rawData;
  };

  const requestFanTransactions = async (userId: string) => {
    let rawData: ITransaction[] = [];
    let current = 1;
    let preparingData = true;

    const dateFrom = filters.find((f) => (f as LogicalFilter).field === 'dateFrom') || null;
    const dateTo = filters.find((f) => (f as LogicalFilter).field === 'dateTo') || null;

    while (preparingData) {
      try {
        const { data, total } = await getList({
          resource: 'transactions',
          pagination: {
            current,
            pageSize,
          },
          metaData: {
            operation: 'listMyFanTransactions',
            items: [
              'id',
              'amount',
              'creatorRevenue',
              'currencyCode',
              'description',
              'type',
              'createdAt',
              'status',
              'paymentRefNumber',
              'lineItem',
            ],
            metadata: [...PAGINATION_FIELDS],
          },
          filters: [
            {
              field: 'status',
              operator: 'eq',
              value: 'AUTHORISED',
            },
            {
              field: 'userId',
              operator: 'eq',
              value: userId,
            },
            {
              field: 'createdAt',
              operator: 'between',
              value: [
                dateFrom?.value ? moment(dateFrom.value).startOf('day').toDate() : '',
                dateTo?.value ? moment(dateTo.value).endOf('day').toDate() : '',
              ].filter(Boolean),
            },
          ],
        });

        current++;
        rawData.push(...(data as ITransaction[]));
        if (total === rawData.length) {
          preparingData = false;
        }
      } catch (error) {
        preparingData = false;
        return;
      }
    }
    return rawData;
  };

  const triggerExport = async () => {
    setIsLoading(true);

    const fans = (await requestFanSubscriptions()) || [];

    if (fans.length === 0) {
      setIsLoading(false);

      open({
        key: `error` + Date.now(),
        type: 'error',
        description: `Error on exporting!`,
        message: 'No record available to export',
      });
      return;
    }

    const workbook = utils.book_new();

    await Promise.all(
      fans
        .filter((o) => o?.userId)
        .map(async (fan) => {
          const transactions = (await requestFanTransactions(fan.userId)) || [];

          const rowData = transactions.map((transaction) => {
            return {
              'Transaction Date & Time': moment(transaction.createdAt).format('DD-MMM-YYYY'),
              'Transaction ID': transaction.id,
              'Monetization Tools': transaction.type,
              'Video Title': transaction?.lineItem?.title || '',
              Description: transaction?.lineItem?.name || '',
              [`Total Spent (${transaction?.currencyCode})`]: formatMoney(transaction?.amount / 100 || 0),
            };
          });

          // init
          const worksheet = utils.json_to_sheet([]);

          let rowNumber = 1;
          headers.map((header) => {
            utils.sheet_add_aoa(worksheet, [[header]], { origin: `A${rowNumber++}` });
          });
          utils.sheet_add_aoa(worksheet, [[]], { origin: `A${rowNumber++}` });
          utils.sheet_add_aoa(worksheet, [[`Fans Username: ${fan.username}`]], { origin: `A${rowNumber++}` });
          utils.sheet_add_aoa(worksheet, [[`Fans Email: ${fan.email}`]], { origin: `A${rowNumber++}` });
          utils.sheet_add_aoa(
            worksheet,
            [[`Joined Channel Date: ${fan?.joinedDate ? moment(fan.joinedDate).format('DD-MMM-YYYY') : ''}`]],
            {
              origin: `A${rowNumber++}`,
            },
          );
          utils.sheet_add_aoa(worksheet, [[`Subscribed Plan: ${fan.planStatus}`]], { origin: `A${rowNumber++}` });
          utils.sheet_add_aoa(worksheet, [[]], { origin: `A${rowNumber++}` });
          utils.sheet_add_json(worksheet, rowData, { skipHeader: false, origin: `A${rowNumber++}` });
          utils.book_append_sheet(workbook, worksheet, fan?.username);
        }),
    ).catch((error) => {
      console.log(error);
    });

    writeFile(workbook, `${filename}.xlsx`, { compression: true });

    setIsLoading(false);
  };

  return {
    isLoading,
    triggerExport,
  };
};
