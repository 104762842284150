import { Icons } from '@pankod/refine-antd';
import { ResourceProps } from '@pankod/refine-core';
import { ChannelManagementList } from 'pages/channel-management';
import { NftCollectionCreate, NftCollectionEdit } from 'pages/nft-collection';
import { NftCollectionList } from 'pages/nft-collection/list';
import { TransactionList } from 'pages/transaction-report';
import { VideoManagementList } from 'pages/video-management';
import { VideoEdit } from 'pages/videos';
import { WatchHistoryList } from 'pages/watch-history-report';
import { withRouteGuard } from 'components/route-guard';

const role = 'admin';

export const AdminRoutes: ResourceProps[] = [
  {
    key: 'management',
    icon: <Icons.UnorderedListOutlined />,
    name: 'management',
    options: {
      label: 'management',
      role,
    },
  },
  {
    name: 'videos-management',
    parentName: 'management',
    options: {
      label: 'videos',
    },
    list: withRouteGuard(VideoManagementList, 'admin'),
    edit: VideoEdit,
  },
  {
    name: 'channels-management',
    parentName: 'management',
    options: {
      label: 'channels',
    },
    list: withRouteGuard(ChannelManagementList, 'admin'),
  },
  //
  // NFT Collection
  //
  {
    key: 'nft-collections',
    icon: <Icons.UnorderedListOutlined />,
    name: 'nft-collections',
    options: {
      label: 'nftCollections',
      role,
    },
    list: withRouteGuard(NftCollectionList, 'admin'),
    create: withRouteGuard(NftCollectionCreate, 'admin'),
    edit: withRouteGuard(NftCollectionEdit, 'admin'),
  },
  //
  // Reports
  //
  {
    key: 'report',
    icon: <Icons.UnorderedListOutlined />,
    name: 'report',
    options: {
      label: 'report',
      role,
    },
  },
  {
    name: 'transactions',
    parentName: 'report',
    options: { label: 'transactions' },
    list: withRouteGuard(TransactionList, 'admin'),
  },
  {
    name: 'views',
    parentName: 'report',
    options: { label: 'views' },
    list: withRouteGuard(WatchHistoryList, 'admin'),
  },
];
