import { Form, FormProps, Input, Modal, ModalProps } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { AliyunOSSUpload } from 'components/upload';
import { mapValuesToSetter } from 'libs';

type EditPlaylistProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  hideCompanySelect?: boolean;
};

export const EditDialog: React.FC<EditPlaylistProps> = ({ modalProps, formProps }) => {
  const t = useTranslate();

  return (
    <Modal {...modalProps} title={t('playlists.editPlaylist')} width={500}>
      <Form
        {...formProps}
        onFinish={(values) => {
          formProps.onFinish(mapValuesToSetter(values));
        }}
        layout="vertical"
      >
        <Form.Item
          label={t('playlists.fields.name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('errors.required', {
                field: t('playlists.fields.name'),
              }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t('playlists.fields.description')} name="description" rules={[{ required: false }]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item label={t('playlists.fields.thumbnail.label')} name="thumbnailUrl" rules={[{ required: false }]}>
          <AliyunOSSUpload
            purpose="PlaylistThumbnail"
            accept="image/*"
            description={t('playlists.fields.thumbnail.description')}
            onChange={(response) => {
              formProps.form.setFieldsValue({
                thumbnailUrl: response.url,
              });
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
