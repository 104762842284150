import { useCustom } from '@pankod/refine-core';
import { PaymentStatus } from 'api';
import { ITransactionSummary } from 'interfaces';

export const useGetTransactionSummary = ({
  channelId,
  dateFrom,
  dateTo,
}: {
  channelId?: string;
  dateFrom?: Date;
  dateTo?: Date;
}) => {
  const result = useCustom<ITransactionSummary>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'getTransactionSummary',
      fields: ['currencyCode', 'amount', 'payPerView', 'gift', 'subscription', 'tips'],
      variables: {
        where: {
          value: {
            status: PaymentStatus.Authorised,
            channelId: channelId,
            dateFrom,
            dateTo,
          },
          type: 'TransactionSummaryInput',
          required: true,
        },
      },
    },
  });

  return result?.data?.data;
};
