import { useCustom } from '@pankod/refine-core';
import { PaymentStatus } from 'api';
import { ITransactionSummary } from 'interfaces';

export const useGetMyTransactionSummary = ({ dateFrom, dateTo }: { dateFrom?: Date; dateTo?: Date }) => {
  const result = useCustom<ITransactionSummary>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'getMyTransactionSummary',
      fields: ['currencyCode', 'amount', 'payPerView', 'gift', 'subscription', 'tips'],
      variables: {
        where: {
          value: {
            status: PaymentStatus.Authorised,
            dateFrom,
            dateTo,
          },
          type: 'TransactionSummaryInput',
          required: true,
        },
      },
    },
  });

  return result?.data?.data;
};
