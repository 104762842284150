import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Row, Typography } from '@pankod/refine-antd';
import { useGetIdentity, useNavigation } from '@pankod/refine-core';
import { Identity } from 'providers/jwtAuthProvider';

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const { data: user } = useGetIdentity<Identity>();
  const { replace } = useNavigation();

  React.useEffect(() => {
    if (user?.role === 'admin') {
      return replace('/management/videos-management');
    }

    if (user?.role === 'user') {
      return replace('/my-channel');
    }
  }, [replace, user]);

  return (
    <Row gutter={[16, 16]}>
      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Card
          bodyStyle={{
            height: 550,
          }}
          title={<Text strong /* style={{ fontSize: 24, fontWeight: 800 }} */>{t('dashboard.title')}</Text>}
        >
          Welcome to Color Dashboard, {user?.role}!
        </Card>
      </Col>
    </Row>
  );
};
