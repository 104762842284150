import { useCustom } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { IWatchHistorySummary } from 'interfaces';

export const useGetWatchHistorySummary = ({ frequency, channelId, searchDates }) => {
  const response = useCustom<{ items: IWatchHistorySummary[] }>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'getWatchHistorySummary',
      fields: [
        {
          items: ['frequency', 'totalViewCount', 'totalUniqueViewCount', 'totalUniqueVideoCount', 'date'],
        },
        { metadata: [...PAGINATION_FIELDS] },
      ],
      variables: {
        where: {
          value: {
            frequency,
            channelId: channelId || null,
            dateFrom: searchDates[0],
            dateTo: searchDates[1],
          },
          type: 'WatchHistorySummaryWhereInput',
          required: true,
        },
      },
    },
  });

  const items = response?.data?.data?.items[0] || null;

  return {
    totalViewCount: items?.totalViewCount || 0,
    totalUniqueViewCount: items?.totalUniqueViewCount || 0,
    totalUniqueVideoCount: items?.totalUniqueVideoCount || 0,
  };
};
