export const convertSecondsToDuration = (seconds: number) => {
  let hhmmss: string;
  if (seconds < 3600) {
    hhmmss = new Date(seconds * 1000).toISOString().substring(14, 14 + 5);
  } else {
    hhmmss = new Date(seconds * 1000).toISOString().substring(11, 11 + 8);
  }

  return hhmmss;
};
