import { useEffect } from 'react';
import { Form, FormProps, Input, InputNumber, Modal, ModalProps, Select, Typography } from '@pankod/refine-antd';
import { useList, useTranslate } from '@pankod/refine-core';
import iconList from '../../../data/virtual-gift-icon-list';
import { PAGINATION_FIELDS, RESOURCE_FIELDS, RESOURCE_NAME } from '../constant';
import '../style.less';
import { CurrencyCode } from 'api';
import { IVirtualGift } from 'interfaces';
import { isNumber } from 'lodash';

const { Option } = Select;

interface VirtualGiftCreateFormProps {
  modalProps: ModalProps;
  formProps: FormProps;
  currencyCode: CurrencyCode;
}

export const VirtualGiftCreateForm = ({
  modalProps,
  formProps,
  currencyCode = CurrencyCode.Myr,
}: VirtualGiftCreateFormProps) => {
  const t = useTranslate();

  const { data: virtualGiftList } = useList<IVirtualGift>({
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'listMyGifts',
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    config: {
      pagination: { current: 1, pageSize: 1000 },
    },
  });

  const filterIconURL = (iconsList: string[]) => {
    if (!virtualGiftList?.data) {
      return iconsList;
    }

    const addedGiftImg = virtualGiftList?.data
      .filter((gift) => gift.archivedAt === null)
      .map((gift) => gift.thumbnailUrl);

    const availableImages = iconList.filter((icon) => !addedGiftImg.includes(icon));

    return availableImages;
  };

  useEffect(() => {
    if (modalProps.visible) {
      formProps.form.resetFields();
    }
  }, [formProps, modalProps.visible]);

  return (
    <Modal {...modalProps} title={t('virtualGifting.createVirtualGifting')} width={600}>
      <Form
        {...formProps}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={(values: any) => {
          formProps.onFinish({
            ...values,
            price: Math.round(values.price * 100),
          });
        }}
      >
        <Form.Item
          label={t('virtualGifting.fields.icon')}
          className="gift-icon-selector"
          name="thumbnailUrl"
          rules={[
            {
              required: true,
              message: t('errors.required', { field: t('virtualGifting.fields.icon') }),
            },
          ]}
        >
          <Select>
            {filterIconURL(iconList).map((url) => {
              return (
                <Option key={url} value={url}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img alt="Gift Icon" style={{ width: 'auto', height: 45 }} src={url} />
                  </div>
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          label={t('virtualGifting.fields.name')}
          name="name"
          rules={[
            {
              required: true,
              message: t('errors.required', { field: t('virtualGifting.fields.name') }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('virtualGifting.fields.displayPrice', { currencyCode })}
          name="price"
          rules={[
            {
              required: true,
              message: t('errors.required', { field: t('virtualGifting.fields.price') }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (isNumber(value) && value > 0) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('virtualGifting.errors.priceInvalid')));
              },
            }),
          ]}
        >
          <InputNumber controls={false} style={{ width: '100%' }} addonAfter=".00" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
