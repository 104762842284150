import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Col,
  Edit,
  Form,
  Icons,
  Input,
  Row,
  Select,
  Tooltip,
  Typography,
  message,
} from '@pankod/refine-antd';
import { IResourceComponentsProps, useCustom, useDataProvider, useTranslate } from '@pankod/refine-core';
import VerifyEmailLabel from 'components/auth/VerifyEmailLabel';
import { useLeaveFormConfirmation } from 'hooks/use-leave-form-confirmation';
import { countries } from '../../data/countries.js';
import { mapValuesToSetter } from '../../libs';
import { RESOURCE_FIELDS } from './constant';
import { IUser } from 'interfaces';
import { simplifyErrorMessage } from 'libs';

const { Text } = Typography;

export const AccountSettingEditProfile: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const dataProvider = useDataProvider();
  const [form] = Form.useForm();
  const { onFieldsChange, onMutateSuccess } = useLeaveFormConfirmation();

  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const onVerifySuccess = () => setIsEmailVerified(true);

  const { data, isFetching } = useCustom<IUser>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'me',
      fields: [...RESOURCE_FIELDS],
    },
  });

  useEffect(() => {
    form.setFieldsValue({
      ...(data?.data as any),
    });

    setIsEmailVerified(!!data?.data.isEmailVerified);

    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetching]);

  const handleSubmit = async (payload: any) => {
    try {
      await dataProvider().custom!({
        url: '',
        method: 'put',
        metaData: {
          operation: 'updateMyAccount',
          variables: {
            data: {
              value: {
                ...payload,
              },
              type: 'UpdateMyAccountInput',
              required: true,
            },
          },
          fields: ['id'],
        },
      });
      message.success('Successfully edited profile');
      onMutateSuccess();
    } catch (error) {
      const errorMessage = simplifyErrorMessage(error);
      message.error(errorMessage || 'Error while editing profile');
    }
  };

  const renderVerifyEmailLabel = () => {
    return !isEmailVerified && !isFetching ? <VerifyEmailLabel onSuccess={onVerifySuccess} /> : null;
  };

  return (
    <>
      {renderVerifyEmailLabel()}

      <Edit
        title={t('profile.accountSettingProfile')}
        isLoading={isFetching}
        saveButtonProps={{
          disabled: isFetching,
          onClick: () => {
            form.submit();
          },
        }}
        pageHeaderProps={{
          breadcrumb: <Breadcrumb hideIcons />,
          extra: <></>,
        }}
      >
        {data && (
          <Form
            form={form}
            layout="vertical"
            onFieldsChange={onFieldsChange}
            onFinish={(values) => {
              const payload = mapValuesToSetter({
                ...values,
              });
              handleSubmit(payload);
            }}
          >
            <Row gutter={[64, 0]} wrap>
              <Col xs={24} lg={24}>
                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>{t('profile.fields.username')}</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="username" rules={[{ required: true }]}>
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>{t('profile.fields.loginEmail')}</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="email" rules={[{ required: true }]}>
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>

                <Row align={'middle'} style={{ marginTop: '40px', marginBottom: '20px' }}>
                  <Typography.Title level={4} underline style={{ marginRight: '5px' }}>
                    {t('profile.personalInformation')}
                  </Typography.Title>
                  <Tooltip title={t('profile.personalInformationTips')}>
                    <Icons.InfoCircleOutlined />
                  </Tooltip>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>* {t('profile.fields.fullName')}</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="fullName" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>* {t('profile.fields.phoneNumber')}</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row gutter={[6, 0]}>
                      <Col xs={8} lg={7}>
                        <Form.Item name="mobileCountryCode" rules={[{ required: true }]}>
                          <Select
                            showSearch
                            filterOption={(input, option) => {
                              return (option!.value as unknown as string).toLowerCase().includes(input.toLowerCase());
                            }}
                          >
                            {countries.map((country, index) => (
                              <Select.Option key={`country${index}`} label={country.name} value={country.dial_code}>
                                <Text>
                                  {country.flag} {country.dial_code}
                                </Text>
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={16} lg={17}>
                        <Form.Item name="mobileNumber" rules={[{ required: true }]}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>* {t('profile.fields.addressLine1')}</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="companyAddressLine1" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>{t('profile.fields.addressLine2')}</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="companyAddressLine2" rules={[{ required: false }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>* {t('profile.fields.state')}</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="companyAddressState" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>* {t('profile.fields.postcode')}</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="companyAddressPostcode" rules={[{ required: true }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong>* {t('profile.fields.country')}</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="companyAddressCountry" rules={[{ required: true }]}>
                      <Select
                        showSearch
                        filterOption={(input, option) => {
                          return (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase());
                        }}
                      >
                        {countries.map((country, index) => (
                          <Select.Option key={`country${index}`} label={country.name} value={country.name}>
                            <Text>{country.name}</Text>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row align={'middle'} style={{ marginTop: '40px', marginBottom: '20px' }}>
                  <Typography.Title level={4} underline style={{ marginRight: '5px' }}>
                    {t('profile.agentInformation')}
                  </Typography.Title>
                  <Tooltip title={t('profile.agentInformationTips')}>
                    <Icons.InfoCircleOutlined />
                  </Tooltip>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong> {t('profile.fields.nickname')}</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="contactPersonName" rules={[{ required: false }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong> {t('profile.fields.phoneNumber')}</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="contactPersonMobileNumber" rules={[{ required: false }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Text strong> {t('profile.fields.email')}</Text>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item name="contactPersonEmail" rules={[{ required: false }]}>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} lg={8}></Col>
            </Row>
          </Form>
        )}
      </Edit>
    </>
  );
};
