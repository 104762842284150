import { useTable, useTableReturnType } from '@pankod/refine-antd';
import { CrudFilters, CrudSort, HttpError } from '@pankod/refine-core';
import { RESOURCE_FIELDS, RESOURCE_NAME } from '../pages/transaction-report/constant';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { ITransaction, ITransactionFilterVariables } from 'interfaces';

export const useListTransactions = (): useTableReturnType<ITransaction, ITransactionFilterVariables> => {
  const initialSorter: CrudSort[] = [{ field: 'createdAt', order: 'desc' }];
  const initialPageSize = 10;

  const result = useTable<ITransaction, HttpError, ITransactionFilterVariables>({
    resource: RESOURCE_NAME,
    metaData: {
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    permanentFilter: [
      {
        field: 'status',
        operator: 'eq',
        value: 'AUTHORISED',
      },
    ],
    initialSorter,
    initialPageSize,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { channel, transactionDates } = params;

      filters.push({
        field: 'channelId',
        operator: 'eq',
        value: channel?.id || null,
      });

      if (transactionDates) {
        filters.push({
          field: 'createdAt',
          operator: 'between',
          value:
            transactionDates?.length > 0
              ? [transactionDates[0].startOf('day').toDate(), transactionDates[1].endOf('day').toDate()]
              : [],
        });
      }

      return filters;
    },
  });

  return {
    ...result,
  };
};
