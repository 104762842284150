import React, { useCallback, useRef } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslate } from '@pankod/refine-core';
import { MediaForm } from './MediaForm';
import { CloseOutlined } from '@ant-design/icons';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Table, Typography } from 'antd';
import update from 'immutability-helper';
import { cloneDeep, isNumber } from 'lodash';

interface DataType {
  key: string;
  title: string;
  url: string;
}

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

export const MediaTable: React.FC<{
  data: any[];
  onChange: (data: any[]) => void;
}> = ({ data, onChange }) => {
  const t = useTranslate();

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = data[dragIndex];
      onChange(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        }),
      );
    },
    [data, onChange],
  );

  const [createModalVisible, setCreateModalVisible] = React.useState(false);
  const showCreateModal = () => setCreateModalVisible(true);
  const closeCreateModal = () => setCreateModalVisible(false);

  const [editModalKey, setEditModalKey] = React.useState<number | undefined>();
  const showEditModal = (index: number) => setEditModalKey(index);
  const closeEditModal = () => setEditModalKey(undefined);

  return (
    <>
      <MediaForm
        modalVisible={createModalVisible}
        onClose={closeCreateModal}
        onSubmit={(values) => {
          onChange([
            ...data,
            {
              key: `link ` + Date.now().toString(), // dirty way to set the key
              url: values?.url || '',
              title: values?.title || '',
            },
          ]);
        }}
      />

      <MediaForm
        modalVisible={isNumber(editModalKey)}
        values={data[editModalKey]}
        onClose={closeEditModal}
        onSubmit={(values) => {
          const newMediaLinks = cloneDeep(data);
          newMediaLinks[editModalKey] = {
            key: data[editModalKey].key,
            ...values,
          };
          onChange(newMediaLinks);
        }}
      />

      <Row gutter={[10, 0]} justify="start" align="middle">
        <Col span={22}>
          <Button type="link" icon={<PlusCircleOutlined />} onClick={showCreateModal} disabled={data.length >= 5}>
            {t('myChannel.mediaTable.addUrlLink')}
          </Button>
        </Col>

        <Col span={2}>
          <Typography.Text>{data.length}/5</Typography.Text>
        </Col>
      </Row>

      <Row gutter={[10, 0]} justify="start" align="middle">
        <Col span={24}>
          <DndProvider backend={HTML5Backend}>
            <Table
              rowKey="key"
              dataSource={data}
              components={{
                body: {
                  row: DraggableBodyRow,
                },
              }}
              onRow={(_, index) => {
                const attr = {
                  index,
                  moveRow,
                };
                return attr as React.HTMLAttributes<any>;
              }}
              pagination={false}
            >
              <Table.Column width={'40%'} title={t('myChannel.mediaTable.title')} dataIndex="title" key="title" />
              <Table.Column title={t('myChannel.mediaTable.link')} dataIndex="url" key="url" />
              <Table.Column
                width="20%"
                title={t('myChannel.mediaTable.action')}
                key="action"
                render={(_: any, record: DataType, index: number) => (
                  <Space size="middle">
                    <Button type="link" icon={<EditOutlined />} onClick={() => showEditModal(index)} />
                    <Button
                      type="link"
                      icon={<CloseOutlined />}
                      onClick={() => {
                        const newMediaLinks = data.filter((o) => o.key !== record.key);
                        onChange(newMediaLinks);
                      }}
                    />
                  </Space>
                )}
              />
            </Table>
          </DndProvider>
        </Col>
      </Row>

      <br />
    </>
  );
};
