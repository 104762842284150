import React, { useEffect } from 'react';
import { Card, Col, DateField, EditButton, Row, Space, Switch, Table, Typography, useTable } from '@pankod/refine-antd';
import { CrudSort, HttpError, IResourceComponentsProps, useCustom, useTranslate, useUpdate } from '@pankod/refine-core';
import { useToggleChannelSubscription } from './hooks/use-toggle-channel-subscription';
import { PAGINATION_FIELDS, RESOURCE_FIELDS, RESOURCE_NAME } from './constant';
import { ISubscription, IUser } from 'interfaces';
import { camelCase } from 'lodash';

export const SubscriptionList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const initialSorter: CrudSort[] = [{ field: 'id', order: 'asc' }];
  const initialPageSize = 10;

  const {
    isLoading,
    isSuccess,
    data: channelData,
    refetch,
  } = useCustom<IUser>({
    url: '',
    method: 'get',
    metaData: {
      operation: 'me',
      fields: [{ channel: ['id', 'isSubscriptionEnabled', 'currencyCode'] }],
    },
  });
  const isCompletedLoad = isSuccess && !isLoading;

  const { tableProps } = useTable<ISubscription, HttpError>({
    resource: RESOURCE_NAME,
    metaData: {
      operation: 'listMySubscriptionPlans',
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialSorter,
    initialPageSize,
  });

  const { toggle, isSuccess: isUpdated } = useToggleChannelSubscription({
    channelId: channelData?.data.channel.id,
  });

  useEffect(() => {
    if (isUpdated) {
      refetch();
    }
  }, [refetch, isUpdated]);

  return (
    <>
      <h1>{t('subscription.subscriptionPlans')}</h1>

      {isCompletedLoad && (
        <>
          <Space direction="horizontal" size="large">
            <p>{t('subscription.header')}:</p>
            <Switch
              defaultChecked={channelData.data.channel.isSubscriptionEnabled}
              onChange={toggle}
              style={{ marginBottom: '16px' }}
            />
          </Space>

          {channelData.data.channel.isSubscriptionEnabled && (
            <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
              <Col xl={24} xs={24}>
                <Card>
                  <Table {...tableProps} rowKey="id">
                    <Table.Column<ISubscription>
                      dataIndex="name"
                      title={t('subscription.fields.name')}
                      render={(_, record) => {
                        return t(`subscription.${camelCase(record.name)}`, record.name);
                      }}
                    />
                    <Table.Column dataIndex="description" title={t('subscription.fields.description')} />
                    <Table.Column<ISubscription>
                      dataIndex="duration"
                      title={t('subscription.fields.duration')}
                      render={(_, record) => record?.duration}
                    />
                    <Table.Column<ISubscription>
                      dataIndex="displayPrice"
                      title={t('subscription.fields.displayPrice', {
                        currencyCode: channelData?.data?.channel?.currencyCode,
                      })}
                      render={(_, record) => `${Math.round(record?.price / 100)}`}
                    />
                    <Table.Column<ISubscription>
                      dataIndex="updatedAt"
                      title={t('subscription.fields.updatedAt')}
                      render={(record) => <DateField format="LLL" value={record} />}
                    />
                    <Table.Column<ISubscription>
                      width="5%"
                      key="actions"
                      title={t('tables.actions')}
                      dataIndex="actions"
                      render={(_, record) => (
                        <Space>
                          <EditButton hideText size="small" recordItemId={record.id} />
                        </Space>
                      )}
                    />
                  </Table>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};
