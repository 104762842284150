import React from 'react';
import { HttpError, useNotification, useTranslate } from '@pankod/refine-core';
import { RESOURCE_FIELDS, RESOURCE_NAME } from '../constant';
import { dataProvider } from 'App';

interface Config {
  silence?: boolean;
  onMutateSuccess?: () => void;
  onMutateError?: () => void;
}

export const useUpdateMyChannel = (config?: Config) => {
  const t = useTranslate();
  const { open } = useNotification();
  const [isUpdating, setIsUpdating] = React.useState(false);

  const update = async (values) => {
    try {
      setIsUpdating(true);

      await dataProvider.custom!({
        url: '',
        method: 'post',
        metaData: {
          operation: 'updateMyChannel',
          variables: {
            data: {
              value: { ...values },
              type: 'ChannelUpdateInput',
              required: true,
            },
          },
          fields: [...RESOURCE_FIELDS],
        },
      });

      if (!config?.silence) {
        open({
          key: `success` + Date.now(),
          type: 'success',
          description: t('notifications.success'),
          message: t('notifications.editSuccess', {
            resource: t('myChannel.channel'),
          }),
        });
      }

      config?.onMutateSuccess();
    } catch (error) {
      open({
        key: `error` + Date.now(),
        type: 'error',
        description: t('notifications.error', {
          // ts-ignore
          statusCode: (error as HttpError).statusCode || 'unknown',
        }),
        message: error as any,
      });
      config?.onMutateError();
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    update,
    isUpdating,
  };
};
