import { useState } from 'react';
import { CrudFilters, useDataProvider, useNotification } from '@pankod/refine-core';
import { PAGINATION_FIELDS } from 'hooks/constant';
import { RESOURCE_FIELDS, RESOURCE_NAME } from '../constant';
import { IChannel } from 'interfaces';
import moment from 'moment';
import { utils, writeFile } from 'xlsx';

interface IChannelReport {
  filters: CrudFilters;
  headers: string[];
}

export const useExportChannelReport = (params: IChannelReport) => {
  const [isLoading, setIsLoading] = useState(false);
  const dataProvider = useDataProvider();
  const { getList } = dataProvider();
  const { open } = useNotification();
  const pageSize = 1000;

  const filename = `channel-report-${new Date().toLocaleString()}`;

  const requestChannels = async () => {
    let rawData: IChannel[] = [];
    let current = 1;
    let preparingData = true;

    while (preparingData) {
      try {
        const { data, total } = await getList({
          resource: RESOURCE_NAME,
          pagination: {
            current,
            pageSize,
          },
          metaData: {
            items: [...RESOURCE_FIELDS],
            metadata: [...PAGINATION_FIELDS],
          },
          filters: [...params.filters],
        });

        current++;
        rawData.push(...(data as IChannel[]));
        if (total === rawData.length) {
          preparingData = false;
        }
      } catch (error) {
        preparingData = false;
        return;
      }
    }
    return rawData;
  };

  const triggerExport = async () => {
    setIsLoading(true);

    const channels = (await requestChannels()) || [];

    if (channels.length === 0) {
      setIsLoading(false);

      open({
        key: `error` + Date.now(),
        type: 'error',
        description: `Error on exporting!`,
        message: 'No record available to export',
      });
      return;
    }

    const workbook = utils.book_new();

    const rowData = channels.map((channel) => {
      let values = {
        'User Id': channel?.userId,
        Username: channel?.user?.username,
        Email: channel?.user?.email,
        'Channel URL': process.env.REACT_APP_H5_API_BASE_URL + '/c/' + channel?.name,
        'Email Verified': channel?.user?.isEmailVerified ? 'Yes' : 'No',
        'Joined Date': moment(channel?.setupCompletedAt).format('DD-MMM-YYYY'),
        'Full Name (Legacy)': channel?.user?.fullName,
        'Phone Number': (channel?.user?.mobileCountryCode || '') + channel?.user?.mobileNumber,
        'Address Line 1': channel?.user?.companyAddressLine1,
        'Address Line 2': channel?.user?.companyAddressLine2,
        State: channel?.user?.companyAddressState,
        Postcode: channel?.user?.companyAddressPostcode,
        City: channel?.user?.companyAddressCity,
        Country: channel?.user?.companyAddressCountry,
        'Agent NickName': channel.user?.contactPersonName,
        'Agent Phone Number': channel.user?.contactPersonMobileNumber,
        'Agent Email': channel.user?.email,
        'Bank Name': channel?.user?.bankName,
        'Swift Code': channel?.user?.bankSwiftCode,
        'Branch Code': channel?.user?.bankBranchCode,
        'Setup Up Channel Completely': channel?.setupCompletedAt ? 'Yes' : 'No',
        'Bank Account Holder Name': channel?.user?.bankAccountHolderName,
        'Bank Account Number': channel?.user?.bankAccountNumber,
        'Is Virtual GiftEnabled': channel?.isGiftEnabled ? 'Yes' : 'No',
        'Is Subscription Enabled': channel?.isSubscriptionEnabled ? 'Yes' : 'No',
        'Monetization Tools': channel?.isGiftEnabled || channel?.isSubscriptionEnabled ? 'Yes' : 'No',
        'Total Released Video': channel?.totalReleasedVideoCount,
        'Last Upload Date': channel?.lastVideoUploadedAt
          ? moment(channel?.lastVideoUploadedAt).format('DD-MMM-YYYY')
          : null,
        'Total Follower': channel?.totalFollowerCount,
        Genres: channel?.genres,
      };

      Object.keys(values).forEach((key) => {
        values[key] = values[key] || 'N/A';
      });

      return values;
    });

    const worksheet = utils.json_to_sheet([]);

    let rowNumber = 1;
    params.headers.map((header) => {
      utils.sheet_add_aoa(worksheet, [[header]], { origin: `A${rowNumber++}` });
    });
    utils.sheet_add_aoa(worksheet, [[]], { origin: `A${rowNumber++}` });
    utils.sheet_add_json(worksheet, rowData, { skipHeader: false, origin: `A${rowNumber++}` });
    utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

    writeFile(workbook, `${filename}.xlsx`, { compression: true });

    setIsLoading(false);
  };

  return {
    isLoading,
    triggerExport,
  };
};
