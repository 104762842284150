import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Edit,
  Icons,
  ImageField,
  ListButton,
  Modal,
  Popconfirm,
  Space,
  Table,
  useTable,
} from '@pankod/refine-antd';
import { HttpError, IResourceComponentsProps, useTranslate } from '@pankod/refine-core';
import { useDeletePlaylistVideo } from './hooks/use-delete-playlist-video';
import { PAGINATION_FIELDS, RESOURCE_FIELDS, RESOURCE_NAME } from './constant';
import { PlaylistVideoDetail } from './show';
import { IPlaylistVideo } from 'interfaces';

export const PlaylistVideoList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { id } = useParams();

  const { tableProps, tableQueryResult } = useTable<IPlaylistVideo, HttpError>({
    resource: RESOURCE_NAME,
    metaData: {
      items: [...RESOURCE_FIELDS],
      metadata: [...PAGINATION_FIELDS],
    },
    initialPageSize: 10,
    initialFilter: [
      {
        field: 'playlistId',
        operator: 'eq',
        value: id,
      },
    ],
  });

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [viewIndex, setViewIndex] = React.useState<string | undefined>();
  const closeModal = () => setViewIndex(undefined);

  const {
    deleteOne,
    deleteMany,
    isFetching: isDeleting,
  } = useDeletePlaylistVideo({
    onSuccess: () => {
      tableQueryResult.refetch();
    },
  });

  return (
    <>
      <Modal
        width={800}
        title={t('playlists.videoDetail.title')}
        visible={!!viewIndex}
        onCancel={closeModal}
        footer={[
          <Button key="close" onClick={closeModal}>
            Close
          </Button>,
        ]}
      >
        {viewIndex && <PlaylistVideoDetail id={viewIndex} />}
      </Modal>

      <Edit
        isLoading={isDeleting}
        actionButtons={<></>}
        pageHeaderProps={{
          title: t('playlists.editPlaylist'),
          extra: (
            <>
              <ListButton>{t('playlists.playlists')}</ListButton>
              <Button
                type="primary"
                onClick={() => {
                  deleteMany({
                    playlistId: id,
                    videoIds: selectedRowKeys,
                  });
                }}
                disabled={!(selectedRowKeys?.length > 0)}
              >
                {t('playlists.actions.removeVideo')}
              </Button>
            </>
          ),
        }}
      >
        <Table
          {...tableProps}
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys: React.Key[], record) => {
              setSelectedRowKeys(newSelectedRowKeys);
            },
          }}
          rowKey={(obj) => {
            return `${obj.videoId}`;
          }}
        >
          <Table.Column<IPlaylistVideo>
            dataIndex="title"
            title={t('videos.fields.title')}
            render={(_, record) => record?.video?.title}
          />
          <Table.Column<IPlaylistVideo>
            dataIndex="genre"
            title={t('videos.fields.genre')}
            render={(_, record) => record?.video?.genre}
          />
          <Table.Column<IPlaylistVideo>
            dataIndex="thumbnailUrl"
            title={t('videos.fields.thumbnailUrl')}
            render={(_, record) => (
              <ImageField value={record.video.thumbnailUrl} title={record.video.thumbnailUrl} width={150} />
            )}
          />
          <Table.Column<IPlaylistVideo>
            width="10%"
            key="actions"
            title={t('tables.actions')}
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <Button
                  size="small"
                  icon={<Icons.EyeOutlined />}
                  onClick={() => {
                    setViewIndex(record.videoId);
                  }}
                />

                <Popconfirm
                  title={t('confirmations.remove', { resource: t('videos.video') })}
                  onConfirm={() => {
                    deleteOne({
                      videoId: record?.video?.id,
                      playlistId: id,
                    });
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button size="small" icon={<Icons.DeleteOutlined />} />
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
      </Edit>
    </>
  );
};
