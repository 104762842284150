import React from 'react';
import { Button, Col, Modal, Row, Typography } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { VideoStatus } from 'api';
import { IVideo } from 'interfaces';
import { convertSecondsToDuration } from 'libs';

const { Text, Paragraph } = Typography;

export const VideoDetailDialog: React.FC<{ values: IVideo | null; close: () => void }> = ({ values, close }) => {
  const t = useTranslate();

  if (!values) {
    return <></>;
  }

  return (
    <Modal
      title={t('videos.videoDetails')}
      width={800}
      visible={!!values}
      onCancel={close}
      footer={[
        <Button key="back" onClick={close}>
          OK
        </Button>,
      ]}
    >
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Paragraph>
            <Text strong>{t('videos.fields.duration')}: </Text>
            <Text>{convertSecondsToDuration(values.duration || 0)}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>{t('videos.fields.status')}: </Text>
            <Text>{values.status}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>{t('videos.fields.language')}: </Text>
            <Text>{values.language}</Text>
          </Paragraph>
        </Col>
        <Col span={12}>
          <Paragraph>
            <Text strong>{t('videos.fields.genre')}: </Text>
            <Text>{values.genre}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>{t('videos.fields.rating')}: </Text>
            <Text>{values.rating}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>{t('videos.fields.subtitleLanguage')}: </Text>
            <Text>{values.subtitleLanguage}</Text>
          </Paragraph>
          <Paragraph>
            <Text strong>{t('videos.fields.availableInMyChannel')}: </Text>
            <Text>{values.status === VideoStatus.Released ? 'Yes' : 'No'}</Text>
          </Paragraph>
        </Col>

        <Col span={24}>
          <Paragraph>
            <Text strong>{t('videos.fields.videoType')}: </Text>
            <Text>
              {values.isVertical ? t('videos.fields.vertical') : t('videos.fields.horizontal')} /{' '}
              {values.isPayPerView ? t('videos.fields.payPerViewVideo') : t('videos.fields.nonPayPerViewVideo')}
            </Text>
          </Paragraph>
        </Col>

        <Col span={12}>
          <Paragraph>
            <Text strong>{t('videos.fields.productUrl')}:</Text>
            <Text>{values.productUrl || ' N/A'} </Text>
          </Paragraph>
          <Paragraph>
            <Text strong>{t('videos.fields.fullDescription')}: </Text>
            <Text>{values.description}</Text>
          </Paragraph>
        </Col>
      </Row>
    </Modal>
  );
};
