export const RESOURCE_NAME = 'nft-collections';

export const RESOURCE_FIELDS = [
  'id',
  'title',
  'titleCn',
  'titleZh',
  'description',
  'descriptionCn',
  'descriptionZh',
  'shortDescription',
  'shortDescriptionCn',
  'shortDescriptionZh',
  'banner',
  'image',
  'roadmap',
  'roadmapCn',
  'roadmapZh',
  'contractNetwork',
  'contractAddress',
  'contractAbi',
  'creatorWalletAddress',
  'freeMintStartDate',
  'freeMintEndDate',
  'preSalesStartDate',
  'preSalesEndDate',
  'publicSalesStartDate',
  'publicSalesEndDate',
  'freeMintPrice',
  'freeMintPriceCurrencyCode',
  'preSalesPrice',
  'preSalesPriceCurrencyCode',
  'publicSalesPrice',
  'publicSalesPriceCurrencyCode',
  'holderPerks',
  'holderPerksCn',
  'holderPerksZh',
  'faqs',
  'faqsCn',
  'faqsZh',
  'tnc',
  'tncCn',
  'tncZh',
  'fiatCheckoutEnabled',
  'fiatCheckoutUrl',
  'thirdwebPrebuiltContractVariant',
  'mintEndedAt',
  'createdAt',
  'updatedAt',
  'mintStage',
  'contractChainId',
] as const;
