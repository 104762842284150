// https://github.com/axlroseart/ryan-tool-kit/blob/d33222c8c6247300547586fc836b51ed06d53d35/packages/utils/src/aliUpload/index.ts

export interface UploadInfo {
  file: {
    name: string;
  };
  endpoint: string;
  bucket: string;
  videoId: string;
  object: any;
}

export interface AuthIn {
  uploadAuth: any;
  uploadAddress: string;
  videoId: string;
}

export interface AaliUploadOptions {
  paramData?: any;
  timeout?: number;
  partSize?: number;
  parallel?: number;
  retryCount?: number;
  retryDuration?: number;
  region: string;
  userId: string;
  getAuthByuploadInfo: (uploadInfo: UploadInfo) => Promise<AuthIn>;
  getNewTokenWhenExpire: (UploadInfo: UploadInfo) => Promise<string>;
  onSuccess?: (uploadInfo: UploadInfo) => void;
  onUpload?: (uploadInfo: UploadInfo) => void;
  onProgress?: (uploadInfo: UploadInfo, totalSize: number, progress: number) => void;
  onCancel?: (uploadInfo: UploadInfo, code: number, message: string) => void;
  onError?: (uploadInfo: UploadInfo, code: number, message: string) => void;
}

declare const AliyunUpload: any;

export const aliUploader = (() => {
  return {
    create: (file: File | Blob, options: AaliUploadOptions) => {
      let isStart = false;

      const aliUpload = new AliyunUpload.Vod({
        timeout: options.timeout || 60000,
        partSize: options.partSize || 1048576,
        parallel: options.parallel || 5,
        retryCount: options.retryCount || 3,
        retryDuration: options.retryDuration || 2,
        region: options.region,
        userId: options.userId,
        addFileSuccess: (uploadInfo: UploadInfo) => {
          console.log(`添加${uploadInfo.file.name}文件成功, 等待上传...`);
        },
        onUploadstarted: async (uploadInfo: UploadInfo) => {
          options?.onUpload && options?.onUpload(uploadInfo);
          // console.log("getAuthByuploadInfo", uploadInfo);
          // 如果是 UploadAuth 上传方式, 需要调用 uploader.setUploadAuthAndAddress 方法
          // 如果是 UploadAuth 上传方式, 需要根据 uploadInfo.videoId是否有值，调用点播的不同接口获取uploadauth和uploadAddress
          // 如果 uploadInfo.videoId 有值，调用刷新视频上传凭证接口，否则调用创建视频上传凭证接口
          // 注意: 这里是测试 demo 所以直接调用了获取 UploadAuth 的测试接口, 用户在使用时需要判断 uploadInfo.videoId 存在与否从而调用 openApi
          // 如果 uploadInfo.videoId 存在, 调用 刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)
          // 如果 uploadInfo.videoId 不存在,调用 获取视频上传地址和凭证接口(https://help.aliyun.com/document_detail/55407.html)
          try {
            const { uploadAuth, uploadAddress, videoId } = await options.getAuthByuploadInfo(uploadInfo);
            aliUpload.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
          } catch (error) {
            console.log('error', error);
          }
        },
        onUploadSucceed: (uploadInfo: UploadInfo) => {
          options?.onSuccess && options?.onSuccess(uploadInfo);
          // console.log({
          //   onUploadSucceed: uploadInfo.file.name,
          //   endpoint: uploadInfo.endpoint,
          //   bucket: uploadInfo.bucket,
          //   object: uploadInfo.object,
          // });
        },
        onUploadFailed: (uploadInfo: UploadInfo, code: number, message: string) => {
          options?.onError && options?.onError(uploadInfo, code, message);
          console.log(`onUploadFailed: file:${uploadInfo.file.name},code:${code}, message:${message}`);
        },
        onUploadCanceled: (uploadInfo: UploadInfo, code: number, message: string) => {
          options?.onCancel && options?.onCancel(uploadInfo, code, message);
          console.log(`Canceled file: ${uploadInfo.file.name}, code: ${code}, message:${message}`);
        },
        onUploadProgress: (uploadInfo: UploadInfo, totalSize: number, progress: number) => {
          options.onProgress && options.onProgress(uploadInfo, totalSize, Math.ceil(progress * 100));
        },
        onUploadTokenExpired: async (uploadInfo: UploadInfo) => {
          // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
          // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
          // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
          if (options.getNewTokenWhenExpire) {
            const uploadAuth = await options.getNewTokenWhenExpire(uploadInfo);
            aliUpload.resumeUploadWithAuth(uploadAuth);
          }
        },
      });

      return {
        start: async () => {
          isStart = true;
          aliUpload.addFile(file, null, null, null, options.paramData || '{"Vod":{}}');
          aliUpload.startUpload();
        },
        cancel: () => {
          isStart && aliUpload.cancelFile();
        },
        stop: () => {
          isStart && aliUpload.stopUpload();
        },
        continue: () => {
          isStart && aliUpload.startUpload();
        },
      };
    },
  };
})();
